import { RefetchFunction } from 'axios-hooks';
import { createContext } from 'react';
import { GuestPackageResponse } from '../../interfaces';

export interface ReservationPackageContextProps {
  reservationPackages: GuestPackageResponse[];
  getReservationPackage: RefetchFunction<unknown, unknown>;
  loading: boolean;
}

export const ReservationPackageContext = createContext<
  ReservationPackageContextProps | undefined
>(undefined);
