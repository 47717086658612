export const statusTextColorMapper: Record<string, string> = {
  CONFIRMED: '#0CA925',
  CANCELED: '#fa5c5c',
  STARTED: '#F19204',
  PROCESSED: '#2651C1',
  PUBLISHED: '#04921B',
  ARCHIVED: '#DD8400',
  DRAFT: '#797B89',
};

export const statusColorMapper: Record<string, string> = {
  CONFIRMED: '#EBF8ED',
  CANCELED: '#ffcccc',
  STARTED: '#FFECCF',
  PROCESSED: '#EAEFFB',
  PUBLISHED: '#EBF8ED',
  ARCHIVED: '#FFECCF',
  DRAFT: '#E7EAF0',
};
