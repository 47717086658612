import { FC, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import Header from '../../Header';
import AddOnNotificationIcon from '../../../assets/add-on-notification.svg';
import AddReportIcon from '../../../assets/add-report.svg';
import EditNoteIcon from '../../../assets/edit-note.svg';
import EditIcon from '../../../assets/pen.svg';
import LocationIcon from '../../../assets/locaton.svg';
import CalendarIcon from '../../../assets/calendar.svg';
import CallIcon from '../../../assets/call.svg';
import MessageIcon from '../../../assets/message.svg';
import DropDownIcon from '../../../assets/drop-down.svg';
import DropDownSatusIcon from '../../../assets/DropDownStatus';
import DropDownUpIcon from '../../../assets/drop-down-up.svg';
import ThreeDotMenuIcon from '../../../assets/three-dot-menu.svg';
import RoomIcon from '../../../assets/room.svg';
import EditblackIcon from '../../../assets/edit-icon.svg';
import DeleteIcon from '../../../assets/delete-icon.svg';
import DropDownUpStatus from '../../../assets/DropDownUpStatus';
import { endpoints, routes } from '../../../modules/mappers/urls';

import './styles/styles.css';
import StayDetail from '../../StayDetails';
import ReservationPreferance from '../../ReservationPreferance';
import EditGuestModal from '../../Modals/EditGuest/EditGuest';
import {
  GuestPackageResponse,
  PreviousReservations,
  MewsReservation,
  MewsReservationResponse,
} from '../../../modules/interfaces';
import EditPreferences from '../../Modals/EditPreferences/EditPreferences';
import DamageReportmodal from '../../Modals/DamageReportmodal/DamageReportmodal';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { useAuth, useLoading, useRequest } from '../../../modules/hooks';

interface DetailsLocationState {
  reservationId: string;
  phoneNumber: string;
  email: string;
  status: string;
  amount: number;
  guestName: string;
  roomTypeId: number;
  checkIn: string;
  checkOut: string;
  createdAt: string;
  room: string;
  amountOfNights: number;
}

type Pref = {
  title: string;
  isSelect: boolean;
};

const getColorFromStatusBackground = (statusColor: string) => {
  if (statusColor === 'Pending') {
    return '#F1920440';
  }
  if (statusColor === 'Active') {
    return '#0CA92540';
  }
  return '#2651C140';
};

const getColorFromStatus = (statusColor: string) => {
  if (statusColor === 'Pending') {
    return '#F19204';
  }
  if (statusColor === 'Active') {
    return '#0CA925';
  }
  return '#2651C1';
};

let openStatusIndexStatic = -1;
export const ReservationDetails: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isGuestModal, setGuestModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reservationData, setReservationData] = useState<MewsReservation>();
  const [isEditDamageReport, setIsEditDamageReport] = useState(false);
  const [damageReport, setdamageReport] = useState(false);
  const [editPreference, seteditPreference] = useState(false);
  const [openStatusIndex, setOpenStatusIndex] = useState(-1);
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [previousReservations, setPreviousReservations] = useState<
    PreviousReservations[]
  >([]);

  const addOnPackage = useRef<HTMLDivElement>(null);
  const statusList = ['Pending', 'Active', 'Resolved'];
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;
  const [fullName, setFullname] = useState('');

  const [dietaryPref, setDietaryPref] = useState([
    {
      title: 'Vegatarian',
      isSelect: false,
    },
    {
      title: 'Inclusive Dining Experience',
      isSelect: false,
    },
    {
      title: 'Allergy-Friendly Dining',
      isSelect: true,
    },
    {
      title: 'Culinary Diversity',
      isSelect: false,
    },
    {
      title: 'Ethical and Sustainable Dining',
      isSelect: false,
    },
  ]);
  const [roomPref, setRoomPref] = useState([
    {
      title: 'Spacious Suites',
      isSelect: true,
    },
    {
      title: 'Scenic Views',
      isSelect: false,
    },
    {
      title: 'Luxurious Accommodations',
      isSelect: false,
    },
    {
      title: 'High-Floor Rooms',
      isSelect: false,
    },
    {
      title: 'Smart Room Technology',
      isSelect: false,
    },
    {
      title: 'Accessible Rooms',
      isSelect: true,
    },
  ]);

  const { reservationId, checkIn, checkOut } =
    location.state || ({} as DetailsLocationState);

  const getReservationUrl = `${endpoints.RESERVATION}/${reservationId}`;
  const [{ data: reservationResult, loading: loadingReservation }, getMewsReservation] =
    useRequest<MewsReservationResponse>(getReservationUrl, 'get', {
      authToken: credentialsInfo?.token,
    });

  const getGuestReservationUrl = `${endpoints.PREVIOUS_RESERVATIONS}`;
  const [
    { data: guestReservationResult, loading: loadingGuestReservation },
    getGuestReservations,
  ] = useRequest<PreviousReservations[]>(
    getGuestReservationUrl,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const URL = `${endpoints.RESERVATION_PACKAGES}`;
  const [
    { data: reservationGuest, loading: loadingPreregisterd },
    getReservationPackage,
  ] = useRequest<GuestPackageResponse[]>(
    URL,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    if (location.state === null) {
      navigate(routes.RESERVATIONS);
    }
  }, [location.state, navigate]);

  useEffect(() => {
    setLoading(loadingReservation || loadingPreregisterd || loadingGuestReservation);
  }, [loadingReservation, loadingPreregisterd, loadingGuestReservation]);

  useEffect(() => {
    if (!reservationResult || !reservationResult?.result.Reservations) return;
    setReservationData(reservationResult.result.Reservations[0]);
    const [reservation] = reservationResult.result.Reservations;
    setFullname(`${reservation?.Customer?.FirstName} ${reservation?.Customer?.LastName}`);

    const guestEmail = reservation?.Customer?.Email;

    getGuestReservations({ params: { guestEmail } });
  }, [reservationResult]);

  useEffect(() => {
    if (!guestReservationResult || !guestReservationResult.result) return;

    setVehicleNumber(guestReservationResult?.result[0]?.guest?.licensePlate || '');

    const previousReservationsArray =
      guestReservationResult?.result.map(item => {
        return { ...item, isOpen: false };
      }) || [];

    setPreviousReservations(previousReservationsArray);
  }, [guestReservationResult]);

  useEffect(() => {
    if (!reservationResult) return;
    getReservationPackage({ params: { reservationId } });
  }, [reservationResult]);

  const [damageReports, setDamageReport] = useState([
    {
      name: 'Cause Damage',
      description: 'Guest accidentally damaged a lamp during their stay',
      flaggedOn: '25 Jan 2022, 10:20 PM',
      status: 'Resolved',
    },
    {
      name: 'Chargeback Dispute',
      description: 'Guest initiated a chargeback dispute for a transaction.',
      flaggedOn: '10 Mar 2023, 10:45 PM',
      status: 'Active',
    },
    {
      name: 'No-Show',
      description: 'Guest did not arrive for the reserved stay.',
      flaggedOn: '10 Mar 2023, 10:45 PM',
      status: 'Active',
    },
    {
      name: 'Cause Damage',
      description: 'Guest initiated a chargeback dispute for a transaction.',
      flaggedOn: '25 Jan 2022, 10:20 PM',
      status: 'Resolved',
    },
    {
      name: 'Chargeback Dispute',
      description: 'Guest accidentally damaged a lamp during their stay',
      flaggedOn: '10 Mar 2023, 10:45 PM',
      status: 'Pending',
    },
  ]);

  const onDropDownClick = (index: number) => {
    const newRoomList = previousReservations.map((item, i) => {
      const newItem = JSON.parse(JSON.stringify(item));
      if (index === i) {
        newItem.isOpen = !newItem.isOpen;
      }
      return newItem;
    });
    setPreviousReservations(newRoomList);
  };

  const ondamageReportHide = () => {
    setdamageReport(false);
  };

  const onDamagereport = (isEdit: boolean) => {
    setIsEditDamageReport(isEdit);
    setdamageReport(true);
  };

  const formatDate = (date: string) => {
    return DateTime.fromISO(date).toFormat('LLL, dd');
  };

  function getAdultsAndChildren(guestReservation: MewsReservation): string {
    let adults = 0;
    let children = 0;
    guestReservation.PersonCounts?.forEach(personCount => {
      const ageCategory = guestReservation?.AgeCategory?.AgeCategories.find(
        ageCategoryItem => ageCategoryItem.Id === personCount.AgeCategoryId,
      );

      if (ageCategory?.MaximalAge === 18) {
        children += 1;
      } else {
        adults += 1;
      }
    });

    let adultString = '';

    if (adults > 1) {
      adultString = 'Adults';
    } else if (adults === 1) {
      adultString = 'Adult';
    }

    return children > 0
      ? `${adults} ${adultString}, ${children} children`
      : `${adults} ${adultString}`;
  }

  const stayItem = (
    index: number,
    guestReservation?: PreviousReservations,
    guestVehicleNumber?: string,
  ) => {
    return (
      <div
        className="previous-stay-item-container-main"
        style={{ backgroundColor: guestReservation?.isOpen ? '#ffffff' : '#fbfbfb' }}
      >
        <div
          className="previous-stay-item-container"
          onClick={() => onDropDownClick(index)}
        >
          <div className="hotel-detail-container">
            <img
              style={{ borderRadius: '8px' }}
              className="hotel-image"
              alt="room"
              src={RoomIcon}
            />
            <div className="hotel-name-container">
              <div className="hotel-name">{guestReservation?.reservation.roomName}</div>
              <div className="hotel-details">Thornridge Cir. Shiloh, Hawaii</div>
            </div>
          </div>
          <div className="hotel-detail-container">
            {!guestReservation?.isOpen && (
              <div className="hotel-name-container">
                <div className="stay-list-info-text">
                  {formatDate(checkIn)} - {formatDate(checkOut)}
                </div>

                <div className="stay-list-info-text" style={{ marginTop: '10px' }}>
                  {guestReservation?.mewsReservation &&
                    getAdultsAndChildren(guestReservation.mewsReservation)}
                </div>
              </div>
            )}
            <img
              style={{ marginLeft: '20px' }}
              alt="drop-down"
              src={guestReservation?.isOpen ? DropDownUpIcon : DropDownIcon}
            />
          </div>
        </div>

        {guestReservation?.isOpen && reservationData && (
          <div>
            <StayDetail
              checkIn={guestReservation?.mewsReservation.ScheduledStartUtc}
              checkOut={guestReservation?.mewsReservation.EndUtc}
              reservation={guestReservation.mewsReservation}
              guestPackages={guestReservation.packages}
              pricesReservation={guestReservation.prices}
              vehicleNumber={guestVehicleNumber}
              isPrevious
            />
          </div>
        )}
      </div>
    );
  };

  const renderPackageTable = () => {
    return (
      <div className="bdr package-table-container">
        {reservationGuest?.result && reservationGuest.result.length !== 0 ? (
          <table className="table">
            <thead className="bg-red">
              <tr>
                <th scope="col">
                  <div className="table-header-title table-padding-left">
                    package name
                  </div>
                </th>
                <th scope="col">
                  <div className="table-header-title">Description</div>
                </th>
                <th scope="col">
                  <div className="table-header-title">Quantity</div>
                </th>
                <th scope="col">
                  <div className="table-header-title">Unit Price</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {reservationGuest.result.map((item, index) => {
                const lastIndex = index === reservationGuest.result.length - 1;
                return (
                  <tr className={lastIndex ? 'last-row-border-hide' : undefined}>
                    <td>
                      <div className="table-cell-text table-padding-left">
                        {item.packages.name}
                      </div>
                    </td>
                    <td>
                      <div className="table-cell-text">{item.description}</div>
                    </td>
                    <td>
                      <div className="table-cell-text">{item.quantity}</div>
                    </td>
                    <td>
                      <div className="table-cell-price-text">{item.price}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="no-reservation-note-text">No package was selected</div>
        )}
      </div>
    );
  };

  const onToggleStatus = (nextShow: boolean, index: number) => {
    if (nextShow && index !== openStatusIndexStatic) {
      openStatusIndexStatic = index;
      setOpenStatusIndex(index);
    }
    if (!nextShow && index === openStatusIndexStatic) {
      openStatusIndexStatic = -1;
      setOpenStatusIndex(-1);
    }
  };

  const onDeleteDamageReportHide = () => {
    setDeleteModal(false);
  };

  const onDeleteDamageReport = () => {
    setDeleteModal(true);
  };

  const renderDamageReportTable = () => {
    return (
      <div className="bdr package-table-container">
        <table className="table">
          <thead className="bg-red">
            <tr>
              <th scope="col">
                <div className="table-header-title table-padding-left">name</div>
              </th>
              <th scope="col">
                <div className="table-header-title">Description</div>
              </th>
              <th scope="col">
                <div className="table-header-title">Flagged On</div>
              </th>
              <th scope="col">
                <div className="table-header-title status-header-text">Status</div>
              </th>
              <th scope="col">
                <div className="table-header-title" />
              </th>
            </tr>
          </thead>
          <tbody>
            {damageReports.map((item, index) => {
              const lastIndex = index === damageReports.length - 1;
              const isTopPlacement = index < damageReports.length - 2;
              return (
                <tr className={lastIndex ? 'last-row-border-hide' : undefined}>
                  <td>
                    <div className="table-cell-text-report-name table-padding-left">
                      {item.name}
                    </div>
                  </td>
                  <td>
                    <div className="table-cell-text wrap-description-table-text">
                      {item.description}
                    </div>
                  </td>
                  <td>
                    <div className="table-cell-text">{item.flaggedOn}</div>
                  </td>
                  <td>
                    <OverlayTrigger
                      trigger="click"
                      placement={isTopPlacement ? 'bottom' : 'top'}
                      rootClose
                      onToggle={nextShow => onToggleStatus(nextShow, index)}
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body className="status-drop-down-padding">
                            {statusList.map(statusItem => {
                              return (
                                <div
                                  onClick={e => {
                                    document.body.click();
                                  }}
                                  className="status-drop-down-small-container"
                                >
                                  <div
                                    className="status-indicator-small"
                                    style={{
                                      backgroundColor: getColorFromStatus(statusItem),
                                    }}
                                  />
                                  <div className="edit-text-style">{statusItem}</div>
                                </div>
                              );
                            })}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        className="status-container"
                        style={{
                          backgroundColor: getColorFromStatusBackground(item.status),
                        }}
                      >
                        <div
                          className="report-status-text"
                          style={{
                            color: getColorFromStatus(item.status),
                          }}
                        >
                          {item.status}
                        </div>
                        {index === openStatusIndex ? (
                          <DropDownUpStatus color={getColorFromStatus(item.status)} />
                        ) : (
                          <DropDownSatusIcon color={getColorFromStatus(item.status)} />
                        )}
                      </div>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      trigger="click"
                      placement={isTopPlacement ? 'bottom' : 'top'}
                      rootClose
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <div
                              onClick={e => {
                                document.body.click();
                                onDamagereport(true);
                              }}
                              className="edit-damage-report-container"
                            >
                              <img
                                className="edit-icon-image-style"
                                src={EditblackIcon}
                                alt="edit-black-icon"
                              />
                              <div className="edit-text-style">Edit</div>
                            </div>
                            <div
                              style={{ marginTop: '10px' }}
                              className="edit-damage-report-container"
                              onClick={e => {
                                document.body.click();
                                onDeleteDamageReport();
                              }}
                            >
                              <img
                                className="edit-icon-image-style"
                                src={DeleteIcon}
                                alt="edit-black-icon"
                              />
                              <div className="delete-text-style">Delete</div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div className="report-table-menu-conatiner">
                        <img src={ThreeDotMenuIcon} alt="three-dot" />
                      </div>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  const onPreferanceHide = () => {
    seteditPreference(false);
  };

  const onEditPreferance = () => {
    seteditPreference(true);
  };

  const onSavePref = (dietaryPreferance: Array<Pref>, roomPreferance: Array<Pref>) => {
    setDietaryPref(dietaryPreferance);
    setRoomPref(roomPreferance);
    seteditPreference(false);
  };

  const onGuestHide = () => {
    setGuestModal(false);
  };

  const onEditGuest = () => {
    setGuestModal(true);
  };

  const onLearnMoreClick = () => {
    addOnPackage.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const onMessageGuest = () => {
    navigate(routes.INBOX, {
      state: {
        guestReservationUser: reservationData?.Customer,
        reservationGuestId: reservationId,
        reservationGuest: reservationData,
        reservationPackages: reservationGuest?.result,
        licensePlate: vehicleNumber,
      },
    });
  };

  return (
    <Container fluid className="p-0">
      <Header title="Reservation details" isBack />
      <Row className="main-reservation-detail-container">
        <div className="new-add-on-package-view">
          <div className="add-on-package-row">
            <img src={AddOnNotificationIcon} alt="add-on-notification" />
            <div className="add-on-package-text-container">
              <div className="add-on-package-text">New add-on packages</div>
              <div className="add-on-package-inof-text">
                Following packages added: Bath robe, Buffet dinner, Champagne, Strawberry
                cake and more...
              </div>
            </div>
          </div>
          <div onClick={onLearnMoreClick} className="add-package-learn-more">
            Learn more
          </div>
        </div>
        <Col xs={12} md={8} lg={8}>
          <div className="current-stay-text">Current stay information</div>
          <div className="stay-reservation-container">
            {reservationData && (
              <StayDetail
                checkIn={checkIn}
                checkOut={checkOut}
                reservation={reservationData}
                addOnPackage={addOnPackage}
                guestPackages={reservationGuest?.result}
                pricesReservation={reservationData?.Prices}
                getMewsReservation={getMewsReservation}
                vehicleNumber={vehicleNumber}
              />
            )}
          </div>
          {previousReservations.length > 0 && (
            <>
              <div className="current-stay-text">Previous stay information</div>
              <div className="stay-reservation-container">
                <div className="room-list-container">
                  {previousReservations.map((item, i) =>
                    stayItem(i, item, item.guest.licensePlate),
                  )}
                </div>
              </div>
            </>
          )}

          <div className="current-stay-text">
            Preferences
            <div onClick={onEditPreferance} className="preference-title-container">
              <img src={EditNoteIcon} alt="edit-icon" />
            </div>
          </div>
          <div className="stay-reservation-container">
            <ReservationPreferance dietaryPref={dietaryPref} roomPref={roomPref} />
          </div>
          <div className="current-stay-text">PURCHASED Add-On packages</div>
          <div className="purchase-package-table-container">{renderPackageTable()}</div>
          <div className="current-stay-text">
            damage Report
            <div
              className="add-package-report-icon"
              onClick={() => onDamagereport(false)}
            >
              <img src={AddReportIcon} alt="add-report" />
            </div>
          </div>
          <div className="purchase-package-table-container">
            {renderDamageReportTable()}
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} style={{ position: 'relative' }}>
          <div className="profile-reservation-container">
            <div onClick={onEditGuest} className="edit-container">
              <img alt="edit" src={EditIcon} />
            </div>
            <div className="name-initial-container">
              {fullName
                ?.match(/(\b\S)?/g)
                ?.join('')
                .match(/(^\S|\S$)?/g)
                ?.join('')
                .toUpperCase()}
            </div>
            <div className="profile-name-text">{fullName}</div>
            <div className="gmail-text">{reservationData?.Customer?.Email}</div>
            <div className="user-details-item">
              <div className="user-detail-item-image">
                <img alt="location" src={LocationIcon} />
              </div>
              <div className="user-detail-info-container">
                <div className="user-detail-info-title">Address</div>
                <div className="user-detail-info-value">Allentown, New Mexico</div>
              </div>
            </div>
            <div className="user-details-item">
              <div className="user-detail-item-image">
                <img alt="calendar" src={CalendarIcon} />
              </div>
              <div className="user-detail-info-container">
                <div className="user-detail-info-title">Birthday</div>
                <div className="user-detail-info-value">
                  {formatDate(reservationData?.Customer?.BirthDate || '')}
                </div>
              </div>
            </div>
            <div className="user-details-item">
              <div className="user-detail-item-image">
                <img alt="call" src={CallIcon} />
              </div>
              <div className="user-detail-info-container">
                <div className="user-detail-info-title">Phone number</div>
                <div className="user-detail-info-value">
                  {reservationData?.Customer?.Phone}
                </div>
              </div>
            </div>
            <div onClick={onMessageGuest} className="btn-container">
              <img alt="message" src={MessageIcon} />
              <div className="guest-message-btn-text">Message Guest</div>
            </div>
          </div>
          {reservationData?.Prices && (
            <div className="price-reservation-container">
              <div className="price-text-style">PRICE</div>
              <div className="price-info-container">
                <div className="price-info-title">Total Room Charges</div>
                <div className="price-info-value">{`$${reservationData?.Prices?.subtotal.toFixed(
                  2,
                )}`}</div>
              </div>

              {reservationData?.Prices?.taxes &&
                reservationData?.Prices?.taxes.map(item => (
                  <div className="price-info-container">
                    <div className="price-info-title">{item.name}</div>
                    <div className="price-info-value">{`$${item.value.toFixed(2)}`}</div>
                  </div>
                ))}

              <div className="price-info-container">
                <div className="price-info-title">Add-On Purchases</div>
                <div className="price-info-value">
                  {`$${(
                    (reservationData?.Prices?.reservationTotal || 0) -
                    (reservationData?.Prices?.total || 0)
                  ).toFixed(2)}`}
                </div>
              </div>

              <div className="price-info-divider" />
              <div
                className="price-info-divider"
                style={{ marginTop: '5px', marginBottom: '10px' }}
              />
              <div className="price-info-container">
                <div className="price-info-total">Total</div>
                <div className="price-info-total-value">
                  {reservationData?.Prices?.reservationTotal.toFixed(2)}
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <EditGuestModal show={isGuestModal} onHide={onGuestHide} />
      <DeleteModal
        title="Are you sure you want to delete this report?"
        description="Deleting this report will permanently remove it from the system. Please ensure
        that you no longer need this information before proceeding."
        show={deleteModal}
        onHide={onDeleteDamageReportHide}
        onDelete={onDeleteDamageReportHide}
      />
      <DamageReportmodal
        isEdit={isEditDamageReport}
        show={damageReport}
        onHide={ondamageReportHide}
      />
      <EditPreferences
        show={editPreference}
        onHide={onPreferanceHide}
        dietaryPref={dietaryPref}
        roomPref={roomPref}
        onSave={onSavePref}
      />
    </Container>
  );
};
