import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { Users } from '../../../modules/interfaces';

import { useAuth, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { PropertiesContext } from '../../../modules/context/propertiesContext';

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userPropertiesData, setUserPropertiesData] = useState<Users>();
  const { credentialsInfo } = useAuth()!;

  const userPropertiesUrl = `${endpoints.USERS}/info`;
  const [{ data, loading: userPropertiesLoading }, getInfo] = useRequest<Users>(
    userPropertiesUrl,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    if (!credentialsInfo?.token) return;

    getInfo();
  }, [credentialsInfo?.token]);

  useEffect(() => {
    if (!data) return;

    setUserPropertiesData(data.result);
  }, [data]);

  const propertiesContext = useMemo(() => {
    return { userPropertiesData, userPropertiesLoading };
  }, [userPropertiesData, userPropertiesLoading]);

  return (
    <PropertiesContext.Provider value={propertiesContext}>
      {children}
    </PropertiesContext.Provider>
  );
};
