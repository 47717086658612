import { GuestPackage } from '../interfaces';

export const updateOrCreatePackageList = (
  packages: GuestPackage[],
  updatedPackage: GuestPackage,
): GuestPackage[] => {
  const packageIndex = packages.findIndex(
    ({ packageId }) => packageId === updatedPackage.packageId,
  );

  if (packageIndex > -1) {
    return packages.map((packageItem, index) =>
      index === packageIndex ? updatedPackage : packageItem,
    );
  }

  return [...packages, updatedPackage];
};
