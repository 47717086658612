import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import LoadingOverlay from 'react-loading-overlay-ts';
import { ReservationPackageContext } from '../../../modules/context/guestPackageContext';
import { GuestPackageResponse } from '../../../modules/interfaces';
import { useGetReservationPackage } from '../../Dashboards/Packages/packageEnpoints';

export const ReservationPackageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [reservationPackages, setReservationPackages] = useState<GuestPackageResponse[]>(
    [],
  );
  const [{ data, loading }, getReservationPackage] = useGetReservationPackage();

  useEffect(() => {
    if (!data) return;

    setReservationPackages(data.result);
  }, [data]);

  const reservationPackageContext = useMemo(() => {
    return { reservationPackages, getReservationPackage, loading };
  }, [reservationPackages, getReservationPackage, loading]);

  return (
    <LoadingOverlay
      active={loading}
      text="Wait while we get things ready."
      className="loading-page-v"
    >
      <ReservationPackageContext.Provider value={reservationPackageContext}>
        {children}
      </ReservationPackageContext.Provider>
    </LoadingOverlay>
  );
};
