import { createContext } from 'react';
import { PushNotification } from '../../interfaces';

export interface PushNotificationProps {
  notificationList: PushNotification[];
  setNotificationList: React.Dispatch<React.SetStateAction<PushNotification[]>>;
}

export const PushNotificationContext = createContext<PushNotificationProps | undefined>(
  undefined,
);
