import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Image, Row } from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import 'suneditor/dist/css/suneditor.min.css';
import { MultiSelect, Option } from 'react-multi-select-component';
import Select from 'react-dropdown-select';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FileUploader } from 'react-drag-drop-files';
import { RefetchFunction } from 'axios-hooks';

import './styles/styles.css';
import {
  BedType,
  Property,
  Room,
  RoomResponse,
  SelectType,
  TPRoom,
} from '../../../modules/interfaces';
import { BedItem } from './BedItem';
import { useAuth, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { FileStore } from '../PostEditor';

import { PreviewItem } from '../../Customize/PreviewItem';

interface RoomEditorModalProps {
  data?: RoomResponse;
  show: boolean;
  handleClose: () => void;
  refetchRooms: RefetchFunction<unknown, unknown>;
}

const amenitiesOption: Option[] = [
  { label: 'Amenity 1', value: 'Amenity 1' },
  { label: 'Amenity 2', value: 'Amenity 2' },
  { label: 'Amenity 3', value: 'Amenity 3' },
];

const bedType = ['Twin', 'Twin 1', 'Twin 2'];

export const RoomEditorModal: FC<RoomEditorModalProps> = ({
  data,
  show,
  handleClose,
  refetchRooms,
}) => {
  const [amenities, setAmenities] = useState<Option[]>([]);
  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);
  const [selectedRoomId, setSelectedRoomId] = useState<Option[]>([]);
  const [propertiesList, setPropertiesList] = useState<Property[]>([]);
  const [TpRooms, setTpRooms] = useState<TPRoom[]>([]);
  const [files, setFiles] = useState<FileStore[]>([]);
  const [fileLs, setFileList] = useState<FileList | null>(null);
  const [mediaDeleted, setMediaDeleted] = useState<string[]>([]);
  const [bedList, setBedList] = useState<BedType[]>([]);
  const editor = useRef<SunEditorCore>();
  const fileTypes = useMemo(() => ['JPEG', 'JPG', 'PNG', 'GIF'], []);
  const { credentialsInfo } = useAuth()!;

  const userPropertiesUrl = `${endpoints.PROPERTIES}`;
  const [{ data: propertiesData, loading: userPropertiesLoading }, getInfo] = useRequest<
    Property[]
  >(userPropertiesUrl, 'get', {
    authToken: credentialsInfo?.token,
  });

  const roomsURL = `${endpoints.ROOMS}/third-party/mews`;
  const [{ data: roomMewsData, loading: roomMewsLoading }, refetch] = useRequest<
    TPRoom[]
  >(
    roomsURL,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    if (data) {
      refetch({ params: { updateId: data?.externalId || '' } });
    } else {
      refetch();
    }
  }, [show]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [{ data: insertResult }, insertRoom] = useRequest<string>(
    endpoints.ROOMS,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const updateRoomUrl = `${endpoints.ROOMS}/${data?.externalId}`;
  const [{ data: updateResult }, updateRoom] = useRequest<string>(
    updateRoomUrl,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const createRoom = (roomData: Room) => {
    const formData = new FormData();

    formData.append('name', roomData.name || '');
    formData.append('abbreviation', roomData.abbreviation || '');
    formData.append('maxOccupants', roomData.maxOccupants || '');
    formData.append('roomSize', roomData.roomSize);
    formData.append('propertyId', roomData?.properties || '');
    formData.append('checkInSteps', roomData?.checkInSteps || '');
    formData.append('wifiName', roomData?.wifiName || '');
    formData.append('wifiPassword', roomData?.wifiPassword || '');
    formData.append('description', roomData?.description || '');
    formData.append('bedType', roomData?.bedType || '');
    formData.append('roomId', roomData?.roomId || '');

    if (amenities) {
      const values = amenities.map(item => item.value);
      const commaSeparatedValues = values.join(',');

      formData.append('amenities', commaSeparatedValues);
    }

    if (files) {
      const media = files.filter(file => file.file.name !== '').map(file => file.file);
      media.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });
    }

    if (data) {
      updateRoom({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    } else {
      insertRoom({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    }
  };

  const onSubmit = async (roomData: any) => {
    const validated = await trigger([
      'name',
      'abbreviation',
      'maxOccupants',
      'roomSize',
      'propertyId',
    ]);

    if (validated) {
      createRoom(roomData);
      handleClose();
    }
  };

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const onClickRemoveBed = (key: number) => {
    const tempBeds = bedList.filter(item => item.key !== key);
    setBedList(tempBeds);
  };

  const resetForm = () => {
    setSelectedProperties([]);
    setSelectedRoomId([]);
    setAmenities([]);
    setFiles([]);

    reset({
      name: '',
      abbreviation: '',
      maxOccupants: '',
      roomSize: '',
      checkInSteps: '',
      wifiName: '',
      wifiPassword: '',
      description: '',
      bedType: '',
      roomId: '',
      properties: '',
    });
  };

  const getRoomDropDownData = () => {
    const dropDownList: SelectType[] = [];

    TpRooms.forEach(item => {
      dropDownList.push({
        value: item.Id,
        label: `Room Number ${item.Name}`,
      });
    });

    return dropDownList;
  };

  const handleFileChange = useCallback(
    (fileList: FileList) => {
      Array.from(fileList).forEach(file => {
        const fileAdded = files.some(item => item.file.name === file.name);

        if (!fileAdded) {
          const fileUrl = URL.createObjectURL(file);
          setFiles([...files, { id: undefined, url: fileUrl, file }]);
        }
      });
    },
    [files],
  );

  const handleDeleteFile = useCallback(
    (fileUrl: string, uuid: string | undefined) => {
      const filteredFiles = files.filter(item => item.url !== fileUrl);
      const list = new DataTransfer();

      URL.revokeObjectURL(fileUrl);

      filteredFiles.forEach(fileStore => list.items.add(fileStore.file));

      const { files: dtFiles } = list;

      if (uuid) {
        setMediaDeleted([...mediaDeleted, uuid]);
      }

      setFileList(dtFiles.length ? dtFiles : null);
      setFiles(filteredFiles);
    },
    [files, mediaDeleted],
  );

  const onClose = () => {
    handleClose();
    resetForm();
  };

  useEffect(() => {
    if (!data) return;

    reset({
      name: data?.name || '',
      abbreviation: data?.abbreviation || '',
      maxOccupants: data?.maxOccupants || '',
      roomSize: data?.roomSize || '',
      checkInSteps: data?.checkInSteps || '',
      wifiName: data?.wifiName || '',
      wifiPassword: data?.wifiPassword || '',
      description: data?.description || '',
      bedType: data?.bedType || '',
      roomId: data?.roomId || '',
    });

    if (data?.media) {
      const mediaData = data?.media.map((item): FileStore => {
        return { id: item.uuid, url: item.uri, file: new File([], '') };
      });
      setFiles(mediaData);
    }

    setSelectedProperties([
      { label: data.property?.name || '', value: data.property?.uuid || '' },
    ]);

    const amenitiesList: Option[] | undefined = data.amenities
      ?.split(',')
      .map(item => ({ label: item, value: item }));

    if (amenitiesList) {
      setAmenities(amenitiesList);
    }
  }, [data]);

  useEffect(() => {
    if (!TpRooms || !data) return;

    const value: TPRoom | undefined = TpRooms?.find(
      item => item?.Id === data?.externalId,
    );

    setSelectedRoomId([{ value: value?.Id, label: `Room Number ${value?.Name}` }]);
  }, [TpRooms, refetch]);

  useEffect(() => {
    if (!propertiesData) return;

    setPropertiesList(propertiesData.result);
  }, [propertiesData]);

  useEffect(() => {
    if (!roomMewsData) return;

    setTpRooms(roomMewsData?.result);
  }, [roomMewsData]);

  useEffect(() => {
    if (insertResult || updateResult) {
      refetchRooms();
      resetForm();
    }
  }, [insertResult, updateResult]);

  const getPropertyDropDownData = () => {
    const dropDownList: SelectType[] = [];

    propertiesList.forEach(item => {
      dropDownList.push({
        value: item.uuid,
        label: item.name,
      });
    });
    return dropDownList;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      dialogClassName="post-editor-modal"
      backdrop="static"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="editor-title">New Room</Modal.Title>
          <span className="editor-sub-title">Add new room to Forest Hills property</span>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {propertiesList.length !== 0 && (
              <Row>
                <Form.Group className="mt-24px pb-24px border-bottom">
                  <Form.Label className="editor-title-label">Property</Form.Label>
                  <Controller
                    name="properties"
                    control={control}
                    rules={{ required: 'This is required.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={getPropertyDropDownData()}
                        values={selectedProperties}
                        onChange={values => {
                          field.onChange(values[0]?.value || '');
                          setSelectedProperties(values);
                        }}
                        loading={userPropertiesLoading}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="properties"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Form.Group>
              </Row>
            )}
            {TpRooms.length !== 0 && (
              <Row>
                <Form.Group className="mt-24px pb-24px border-bottom">
                  <Form.Label className="editor-title-label">Select Room</Form.Label>
                  <Controller
                    name="roomId"
                    control={control}
                    rules={{ required: 'This is required.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={getRoomDropDownData()}
                        values={selectedRoomId}
                        onChange={values => {
                          field.onChange(values[0]?.value || '');
                          setSelectedRoomId(values);
                        }}
                        loading={roomMewsLoading}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="roomId"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mt-8px">
              <Col md={6}>
                <Form.Label className="editor-title-label">Room Name</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter room name..."
                  {...register('name', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
              <Col md={6}>
                <Form.Label className="editor-title-label">Room Abbreviation</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter room abbreviation"
                  {...register('abbreviation', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="abbreviation"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>
            <Row className="mt-24px">
              <Col md={6}>
                <Form.Label className="editor-title-label">Max Occupants</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter room max occupants..."
                  {...register('maxOccupants', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="maxOccupants"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>

              <Col md={6}>
                <Form.Label className="editor-title-label">Room Size</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter room size..."
                  {...register('roomSize', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="roomSize"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>

              <Row className="mt-24px">
                <Col md={6}>
                  <Form.Label className="editor-title-label">Wifi Name</Form.Label>
                  <Form.Control
                    className="editor-control"
                    placeholder="Enter wifi name..."
                    {...register('wifiName')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="wifiName"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Form.Label className="editor-title-label">Wifi Password</Form.Label>
                  <Form.Control
                    className="editor-control"
                    placeholder="Enter wifi password..."
                    {...register('wifiPassword')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="wifiPassword"
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>
              </Row>
            </Row>
            <Row className="mt-24px">
              <Col md={6}>
                <Form.Label className="editor-title-label">Room Description</Form.Label>
                <Form.Control as="textarea" rows={5} {...register('description')} />
              </Col>
              <Col md={6}>
                <Form.Label className="editor-title-label">Check in steps</Form.Label>
                <Form.Control as="textarea" rows={5} {...register('checkInSteps')} />
              </Col>
            </Row>
            {bedList.map(item => (
              <BedItem
                key={item.key}
                bedTypes={bedType}
                data={item}
                onClickRemoveBed={onClickRemoveBed}
              />
            ))}
            <Row className="mt-24px check-box-group">
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="editor-title-label">Bed Type</Form.Label>
                  <Form.Select
                    className="editor-control"
                    {...register('bedType', { required: true })}
                  >
                    {bedType?.map(item => {
                      return (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mt-24px pb-24px border-bottom">
                <Form.Label className="editor-title-label">Amenities</Form.Label>

                <MultiSelect
                  options={amenitiesOption}
                  value={amenities}
                  labelledBy="Default"
                  onChange={setAmenities}
                />
              </Form.Group>
            </Row>

            <Row className="mt-24px">
              <Form.Group className="mt-24">
                <Form.Label className="editor-title-label">Media Files</Form.Label>
                <Row className="m-0 upload-section">
                  <Col sm={12} className="preview-section">
                    <Row>
                      {files.map(item => {
                        return (
                          <PreviewItem
                            url={item.url}
                            key={item.url}
                            fileId={item.url}
                            uuid={item.id}
                            onDelete={handleDeleteFile}
                          />
                        );
                      })}
                    </Row>
                  </Col>
                  <Col sm={12} className="file-upload text-center">
                    <Image className="w-auto" alt="" src="/file-upload.svg" />
                    <div className="text-center react-file-uploader">
                      <span className="d-block">
                        <span className="upload-letter">
                          Upload a file or drag and drop
                        </span>
                      </span>
                      <span className="d-block">
                        <span className="upload-formats">
                          {fileTypes.join(',')} to 50MB
                        </span>
                      </span>
                      <FileUploader
                        handleChange={handleFileChange}
                        name="file"
                        multiple
                        types={fileTypes}
                        className="file-uploader"
                        fileOrFiles={fileLs}
                      />
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer className="mt-48px">
          <Button className="cancel-button" onClick={onClose}>
            Cancel
          </Button>
          <Button className="save-button" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
