export const SIZE_OPTIONS = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];
export const RANGE_OPTIONS = [
  { label: 'Good', value: 'good' },
  { label: 'Excellent', value: 'excellent' },
  { label: 'Exceptional', value: 'exceptional' },
];

export const CATEGORIES_OPTIONS = [
  { label: 'Services', value: 'SERVICE' },
  { label: 'Product', value: 'PRODUCT' },
  { label: 'Amenities', value: 'AMENITY' },
];
