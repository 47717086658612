import { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

type EditGuestProp = {
  show: boolean;
  onHide: () => void;
};

const EditGuestModal: FC<EditGuestProp> = ({ show, onHide }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">
          Edit guest information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit-guest-input-title">Name</div>
        <input
          type="text"
          className="edit-guest-input-style"
          aria-describedby="nameHelp"
          placeholder="Enter name"
          value="Anna Bronte"
          disabled
        />
        <div className="edit-guest-input-title">E-mail</div>
        <input
          type="email"
          className="edit-guest-input-style"
          aria-describedby="emailHelp"
          placeholder="Enter email"
          value="azizaisrailova7799@gmail.com"
        />
        <div className="edit-guest-input-title">Adress</div>
        <input
          type="text"
          className="edit-guest-input-style"
          aria-describedby="adressHelp"
          placeholder="Enter adress"
          value="Allentown, New Mexico"
        />
        <div className="edit-guest-input-title">Birthday</div>
        <DatePicker
          className="edit-guest-input-style calendar-picker-style"
          selected={startDate}
          wrapperClassName="edit-guest-date-picker-style"
          onChange={(date: Date) => setStartDate(date)}
          dateFormat="dd/MM/YYYY"
        />
        <div className="edit-guest-input-title">Phone number</div>
        <input
          type="tel"
          className="edit-guest-input-style"
          aria-describedby="phoneNumberHelp"
          placeholder="Enter phone number"
          style={{ marginBottom: '0px' }}
          value="(+33) 655 55 2618"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button className="edit-guest-modal-button-save" onClick={onHide}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGuestModal;
