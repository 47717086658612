import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';

import {
  PencilSquare,
  ArrowRepeat,
  PersonCheck,
  PersonX,
  PersonSlash,
} from 'react-bootstrap-icons';
import { InviteModal } from '../../Modals/InviteModal';
import { endpoints } from '../../../modules/mappers/urls';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { AdminTableProps } from '../AdminCategory';
import { DataTable } from '../DataTable';
import { DotDropDown } from '../../Customize/DropDown';
import { config } from '../../../modules/config';
import {
  UserManagmentOption,
  UserManagmentModalData,
} from '../../Modals/UserManagementModal';
import { RoleResponse, Roles, UserResponse, Users } from '../../../modules/interfaces';
import { ModalType } from '../../../modules/enums/types';
import { UserStatus } from '../../../modules/enums/status';
import { UserAction } from '../../../modules/enums/actions';

interface UserDotCellRendererProps {
  data?: Users;
  onClickEdit: (data?: Users) => void;
  onClickDelete: (data?: Users) => void;
  onClickStatus: (data?: Users) => void;
  onClickResetPassword: (data?: Users) => void;
}

const UsersDotMenu: FC<UserDotCellRendererProps> = ({
  data,
  onClickEdit,
  onClickDelete,
  onClickStatus,
  onClickResetPassword,
}) => {
  return (
    <DotDropDown title="" drop="end">
      <Dropdown.Item
        onClick={() => {
          onClickEdit(data);
        }}
      >
        <PencilSquare className="dropdown-icon" />
        Edit User
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickStatus(data);
        }}
      >
        {data?.status === UserStatus.ACTIVE ? (
          <>
            <PersonSlash className="dropdown-icon" />
            Suspend User
          </>
        ) : (
          <>
            <PersonCheck className="dropdown-icon" />
            Activate User
          </>
        )}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickResetPassword(data);
        }}
      >
        <ArrowRepeat className="dropdown-icon" />
        Reset Password
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickDelete(data);
        }}
      >
        <PersonX className="dropdown-icon" />
        Delete User
      </Dropdown.Item>
    </DotDropDown>
  );
};

const getDotMenu = ({
  onClickEdit,
  onClickDelete,
  onClickStatus,
  onClickResetPassword,
}: UserDotCellRendererProps) => {
  const DotMenuCellRenderer: FC<ICellRendererParams<Users>> = (
    params: ICellRendererParams<Users>,
  ) => {
    const { data } = params;
    return (
      <UsersDotMenu
        data={data}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickStatus={onClickStatus}
        onClickResetPassword={onClickResetPassword}
      />
    );
  };

  return DotMenuCellRenderer;
};

export const UserManagment: FC<AdminTableProps> = ({ changeState }) => {
  const gridRef = useRef<AgGridReact>(null);
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;
  const [tableData, setTableData] = useState<Users[]>([]);
  const [roleList, setRoleList] = useState<Roles[]>([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<Users>();
  const [editModal, setEditModal] = useState<UserManagmentModalData>({
    modalName: UserAction.EDIT_USER,
    cancelButton: 'Cancel',
    saveButton: 'Save',
    modalType: ModalType.EDIT,
    handleClose: () => {
      // TODO: need to implement
    },
    handleRefresh: () => {
      // TODO: need to implement
    },
  });

  const [showInviteModal, setShowInviteModal] = useState(false);
  const { setSimpleToasts } = useNotifications()!;

  const [{ data: roleData, loading: roleLoading, error: roleError }] =
    useRequest<RoleResponse>(endpoints.ROLES, 'get', {
      authToken: credentialsInfo?.token,
      params: {
        currentPage: 0,
        pageCount: config.tables.balances.paginationSize,
      },
    });

  const [{ data, loading, error }, refetch] = useRequest<UserResponse>(
    endpoints.USERS,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  const [{ loading: inviteLoading, data: inviteResult, error: inviteError }, inviteUser] =
    useRequest<string>(
      endpoints.USERS,
      'post',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  useEffect(() => {
    setLoading(inviteLoading || loading || roleLoading);
  }, [inviteLoading, loading, roleLoading, setLoading]);

  useEffect(() => {
    if (inviteError) {
      const message = getApiErrorMessage(inviteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (inviteResult) {
      setSimpleToasts({ type: 'info', message: 'User Invite was success!', show: true });
    }
  }, [inviteResult, inviteError, setSimpleToasts]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (roleError) {
      const message = getApiErrorMessage(roleError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (data) {
      const { result } = data;
      setTableData(result.data);
    }
    if (roleData) {
      const { result } = roleData;
      setRoleList(result.data);
    }
  }, [data, roleData, error, roleError, setSimpleToasts, setLoading]);

  const refreshHandler = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleOnclickInviteUser = () => {
    setShowInviteModal(!showInviteModal);
  };

  const handleInviteUser = (email: string, role: string) => {
    handleOnclickInviteUser();
    const currentHost = `${window.location.protocol}//${window.location.host}`;
    inviteUser({ data: { email, role, host: currentHost } });
  };

  const onClickEdit = useCallback(
    (user?: Users) => {
      if (user) {
        setCurrentUser(user);
        setShowEditModal(true);
        setEditModal({
          modalName: UserAction.EDIT_USER,
          cancelButton: 'Cancel',
          saveButton: 'Save',
          handleClose: () => {
            setShowEditModal(false);
          },
          modalType: ModalType.EDIT,
          handleRefresh: refreshHandler,
        });
      }
    },
    [setCurrentUser, setShowEditModal, setEditModal, refreshHandler],
  );

  const onClickStatus = useCallback(
    (user?: Users) => {
      if (user) {
        setCurrentUser(user);
        setShowEditModal(true);
        setEditModal({
          modalName:
            user.status === UserStatus.ACTIVE
              ? UserAction.SUSPEND_USER
              : UserAction.ACTIVATE_USER,
          cancelButton: 'Cancel',
          saveButton: 'Save',
          handleClose: () => {
            setShowEditModal(false);
          },
          modalType:
            user.status === UserStatus.ACTIVE
              ? ModalType.SUSPEND_USER
              : ModalType.ACTIVATE_USER,
          handleRefresh: refreshHandler,
        });
      }
    },
    [setCurrentUser, setShowEditModal, setEditModal, refreshHandler],
  );

  const onClickResetPassword = useCallback(
    (user?: Users) => {
      if (user) {
        setCurrentUser(user);
        setShowEditModal(true);
        setEditModal({
          modalName: UserAction.RESET_PASSWORD,
          cancelButton: 'Cancel',
          saveButton: 'Save',
          handleClose: () => {
            setShowEditModal(false);
          },
          modalType: ModalType.RESET_PASSWORD,
          handleRefresh: refreshHandler,
        });
      }
    },
    [setCurrentUser, setShowEditModal, setEditModal, refreshHandler],
  );

  const onClickDelete = useCallback(
    (user?: Users) => {
      if (user) {
        setCurrentUser(user);
        setShowEditModal(true);
        setEditModal({
          modalName: UserAction.DELETE_USER,
          cancelButton: 'Cancel',
          saveButton: 'Save',
          handleClose: () => {
            setShowEditModal(false);
          },
          modalType: ModalType.DELETE,
          handleRefresh: refreshHandler,
        });
      }
    },
    [setCurrentUser, setShowEditModal, setEditModal, refreshHandler],
  );

  const [columns] = useState<ColDef<Users>[]>([
    { field: 'firstName', headerName: 'FIRST NAME', width: 200 },
    { field: 'lastName', headerName: 'LAST NAME', width: 200 },
    {
      valueGetter: params => {
        const { data: user } = params;
        const role = user ? user.roles[0].name : '-';

        return role;
      },
      headerName: 'ROLE',
      width: 200,
    },
    { field: 'email', headerName: 'EMAIL', width: 200 },
    { field: 'status', headerName: 'STATUS', width: 200 },
    {
      headerName: 'ACTION',
      colId: 'action',
      cellClass: 'actionCol justify-content-end',
      width: 200,
      cellRenderer: getDotMenu({
        onClickEdit,
        onClickDelete,
        onClickResetPassword,
        onClickStatus,
      }),
    },
  ]);

  return (
    <>
      <Row className="admin-article-table">
        <Col sm={12}>
          <Row className="d-flex align-items-center justify-content-between table-title-section">
            <Col sm={3}>
              <h5 className="table-title">User Management</h5>
              <span className="table-sub-title">
                Total Users: <span className="bold">{tableData.length}</span>
              </span>
            </Col>
            <Col sm={3} className="d-flex align-items-center justify-content-end">
              <button
                type="button"
                onClick={handleOnclickInviteUser}
                className="invite-user position-relative"
              >
                Invite User
              </button>
            </Col>
          </Row>
          <DataTable
            refreshHandler={refreshHandler}
            gridRef={gridRef}
            rowData={tableData}
            columnDefs={columns}
            pagination
            changeState={changeState}
            paginationPageSize={config.tables.balances.paginationSize}
            onRowClicked={(e: RowClickedEvent<Users>) => {
              if (e.api.getFocusedCell()?.column.getColId() === 'action') {
                return;
              }
              const { data: user } = e;
              if (user) {
                // TODO: need to implement
              }
            }}
          />
        </Col>
      </Row>
      {showInviteModal && (
        <InviteModal
          showInviteModal={showInviteModal}
          handleClose={handleOnclickInviteUser}
          handleSave={handleInviteUser}
          roleList={roleList}
        />
      )}
      {showEditModal && (
        <UserManagmentOption
          data={currentUser}
          roles={roleList}
          cancelButton={editModal?.cancelButton}
          modalName={editModal?.modalName}
          saveButton={editModal?.saveButton}
          handleClose={editModal?.handleClose}
          handleRefresh={editModal.handleRefresh}
          modalType={editModal.modalType}
        />
      )}
    </>
  );
};
