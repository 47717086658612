import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './styles/styles.css';
import MarkReadIcon from '../../assets/mark-read.svg';
import BackIcon from '../../assets/back.svg';
import NotificationIcon from '../../assets/notification.svg';
import NotificationListIcon from '../../assets/notiication-list.svg';
import { SearchInboxInput } from '../Inputs/Search';
import { usePushNotifications } from '../../modules/hooks';

type HeaderProps = {
  title: string;
  isBack?: boolean;
};

const Header: FunctionComponent<HeaderProps> = ({ title, isBack }: HeaderProps) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const target = useRef(null);

  const notificationStore = usePushNotifications();

  const readNotificationsHandler = () => {
    notificationStore?.setNotificationList([]);
  };

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <div id="header" className="header-view">
        <div className="header-back-container">
          {isBack && (
            <div style={{ cursor: 'pointer' }} onClick={onBackClick}>
              <img alt="back" src={BackIcon} />
            </div>
          )}
          <div className="header-title-text">{title}</div>
        </div>
        <div className="header-search-view">
          <SearchInboxInput
            style={{ width: '350px', marginRight: '15px' }}
            placeholder="Search here"
          />
          <div
            style={{ position: 'relative', cursor: 'pointer' }}
            ref={target}
            onClick={() => setShow(true)}
          >
            <img src={NotificationIcon} alt="notificationIcon" />
            {notificationStore?.notificationList &&
              notificationStore?.notificationList.length > 0 && (
                <div className="notification-badge">
                  {notificationStore?.notificationList.length}
                </div>
              )}
          </div>
        </div>
      </div>
      <Overlay
        onHide={() => setShow(false)}
        target={target.current}
        rootClose
        show={show}
        placement="bottom-start"
        onExit={() => readNotificationsHandler()}
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="notification_overlay">
            <div className="search-overlay-header">
              <div className="search-overlay-title">Notifications</div>
              <div className="row-view">
                <img src={MarkReadIcon} alt="markReadIcon" />
                <div className="search-overlay-read-text">Mark all as read</div>
              </div>
            </div>
            <div className="notification-list-container">
              {notificationStore?.notificationList.map(item => {
                return (
                  <div>
                    <div className="notification-item">
                      <div className="notification-icon">
                        <div
                          style={{ backgroundColor: item.isRead ? '#F1F1F1' : '#DB453C' }}
                          className="notification-item-badge"
                        />
                        <img src={NotificationListIcon} alt="notificationListIcon" />
                      </div>
                      <div className="notification-title-container">
                        <div className="text-notification-list">{item.title}</div>
                        <div className="text-notification-date">23 Aug, 10:25 AM</div>
                      </div>
                    </div>
                    <div className="notification_divider" />
                  </div>
                );
              })}
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default Header;
