import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Image, Row } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { RefetchFunction } from 'axios-hooks';

import { Property } from '../../../modules/interfaces';
import './styles/styles.css';
import { useAuth, useRequest } from '../../../modules/hooks';

import { endpoints } from '../../../modules/mappers/urls';

interface PropertyEditorModalProps {
  data?: Property;
  show: boolean;
  handleClose: () => void;
  refetchProperties: RefetchFunction<unknown, unknown>;
}

export const PropertyEditorModal: FC<PropertyEditorModalProps> = ({
  data: propertieDate,
  show,
  handleClose,
  refetchProperties,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const { credentialsInfo } = useAuth()!;

  const [logoFile, setLogoFile] = useState<File | undefined>(undefined);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const logoInputField = useRef<HTMLInputElement>(null);
  const imageUrlInputField = useRef<HTMLInputElement>(null);

  const [{ data: insertResult }, insertProperty] = useRequest<string>(
    endpoints.PROPERTIES,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    if (!getValues('files') || !(imageFile && logoFile)) return;
    const propertyFiles = getValues('files');

    setLogoFile(propertyFiles[0]);
    setImageFile(propertyFiles[0]);
  }, [getValues]);

  const handleCloseModal = () => {
    reset({
      name: '',
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      zipCode: '',
    });
    handleClose();
  };

  const createProperty = (data: Property) => {
    const formData = new FormData();

    formData.append('name', data.name || '');
    formData.append('streetName', data.streetName || '');
    formData.append('streetNumber', data.streetNumber || '');
    formData.append('city', data.city);
    formData.append('zipCode', data?.zipCode || '0');
    formData.append('state', data.state);

    let propertyFiles: File[] = [];
    if (logoFile && imageFile) {
      propertyFiles = [logoFile, imageFile];
      propertyFiles.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });
    }

    insertProperty({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: credentialsInfo?.token,
      },
    });
  };

  const onSubmit = async (data: any) => {
    const validated = await trigger([
      'name',
      'streetNumber',
      'streetName',
      'city',
      'state',
      'zipCode',
    ]);

    if (validated) {
      createProperty(data);
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (!insertResult) return;

    refetchProperties();
  }, [insertResult]);

  const handleFrontIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const propertyFiles = e.target.files?.[0] || undefined;
    if (propertyFiles) {
      setLogoFile(propertyFiles);
    }
  };

  const handleBackIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const propertyFiles = e.target.files?.[0] || undefined;
    if (propertyFiles) {
      setImageFile(propertyFiles);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      dialogClassName="post-editor-modal"
      backdrop="static"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="editor-title">New Property</Modal.Title>
          <span className="editor-sub-title">Add new property</span>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="editor-title-label">Property Name</Form.Label>
              <Form.Control
                className="editor-control"
                placeholder="Enter property name..."
                defaultValue={propertieDate?.name}
                {...register('name', { required: 'This is required.' })}
              />
            </Form.Group>
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <span className="error-text">{message}</span>}
            />
            <Row className="mt-48px">
              <Col md={6}>
                <Form.Label className="editor-title-label">Street Number</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property street number..."
                  defaultValue={propertieDate?.name}
                  {...register('streetNumber', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="streetNumber"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
              <Col md={6}>
                <Form.Label className="editor-title-label">Street Name</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property street name..."
                  defaultValue={propertieDate?.name}
                  {...register('streetName', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="streetName"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>
            <Row className="mt-24px">
              <Col md={6}>
                <Form.Label className="editor-title-label">City</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property city..."
                  defaultValue={propertieDate?.name}
                  {...register('city', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="city"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
              <Col md={6}>
                <Form.Label className="editor-title-label">State</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property state..."
                  defaultValue={propertieDate?.name}
                  {...register('state', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="state"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>
            <Row className="mt-24px">
              <Col md={6}>
                <Form.Label className="editor-title-label">ZIP Code</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property zip code..."
                  defaultValue={propertieDate?.name}
                  {...register('zipCode', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="zipCode"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>

            <Form.Group className="mt-24px">
              <Form.Label className="editor-title-label">Property Logo</Form.Label>

              <Col sm={12}>
                <input
                  className="mt-16px"
                  onChange={handleFrontIdChange}
                  multiple={false}
                  ref={logoInputField}
                  type="file"
                  hidden
                />

                <Image
                  alt=""
                  src="/Icon-image.svg"
                  width="42"
                  height="42"
                  className="ag-cell-img"
                />

                <Button
                  className="file-change-btn"
                  onClick={() => logoInputField.current?.click()}
                >
                  Add Logo{' '}
                </Button>
              </Col>
            </Form.Group>
            <Form.Group className="mt-24px">
              <Form.Label className="editor-title-label">Property Image</Form.Label>

              <Col sm={12}>
                <input
                  className="mt-16px"
                  onChange={handleBackIdChange}
                  multiple={false}
                  ref={imageUrlInputField}
                  type="file"
                  hidden
                />

                <Image
                  alt=""
                  src="/Icon-image.svg"
                  width="42"
                  height="42"
                  className="ag-cell-img"
                />

                <Button
                  className="file-change-btn"
                  onClick={() => imageUrlInputField.current?.click()}
                >
                  Add Image
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-48px">
          <Button className="cancel-button" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button type="submit" className="save-button">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
