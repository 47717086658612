import { FC } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface ConfirmModalProps {
  title: string;
  show: boolean;
  body: string;
  handleCancel: () => void;
  handleConfirm: () => void;
}

export interface ModalState {
  title: string;
  show: boolean;
  body: string;
  handler?: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  show,
  body,
  handleCancel,
  handleConfirm,
}) => {
  return (
    <Modal show={show} onHide={handleCancel} dialogClassName="post-editor-modal">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-content">{body}</Modal.Body>
      <Modal.Footer>
        <Button className="cancel-button" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="save-button"
          onClick={() => {
            handleConfirm();
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
