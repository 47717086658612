import { RefetchFunction } from 'axios-hooks';
import { createContext } from 'react';
import { PackagesResponse } from '../../interfaces';

export interface PackageContextProps {
  packages: PackagesResponse[];
  refetch: RefetchFunction<unknown, unknown>;
  loading: boolean;
}

export const PackageContext = createContext<PackageContextProps | undefined>(undefined);
