import { FC, useState, useEffect, useCallback, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Overlay, Popover } from 'react-bootstrap';
import { endpoints } from '../../../modules/mappers/urls';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import {
  Article,
  ArticleResponse,
  Categories,
  FilterRequest,
} from '../../../modules/interfaces';
import './styles/styles.css';
import { ArticleStatus } from '../../../modules/enums/status';
import Header from '../../Header';
import { ArticleTable } from '../../Tables/Article';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import UnCheckIcon from '../../../assets/uncheck-checkbox.svg';
import CheckIcon from '../../../assets/check-checkbox.svg';
import PlusIcon from '../../../assets/plus-icon.svg';
import AddArticles from '../../Modals/AddArticles/AddArticles';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';

interface RowArticleData {
  rowData: Article[];
  key: string;
}

export const Articles: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [modal, setModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<Article>();
  const [categories, setCategories] = useState<Categories[]>([]);
  const [recentData, setRecentData] = useState<Article[]>([]);
  const [pageTableData, setPageTableData] = useState<RowArticleData[]>([]);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const [totalNumber, setTotalNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [status, setStatus] = useState<string[]>(['All status']);
  const [category, setCategory] = useState<string[]>(['All category']);
  const [categoryFinal, setCategoryFinal] = useState<string[]>(['All category']);
  const [statusFinal, setStatusFinal] = useState<string[]>(['All status']);
  const [filters, setFitlers] = useState<FilterRequest>({
    name: '',
    categories: [],
    status: [{ id: ArticleStatus.PUBLISHED, name: ArticleStatus.PUBLISHED }],
  });
  const statusArray = ['All status', 'Published', 'Archived', 'Draft'];
  const [categoryShow, setCategoryShow] = useState(false);
  const categoryTarget = useRef(null);
  const [statusShow, setStatusShow] = useState(false);
  const [addArticle, setAddArticle] = useState(false);
  const [actionArticle, setActionArticle] = useState<Article | undefined>();
  const statusTarget = useRef(null);

  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ data: categoriesData, loading: categoriesLoading, error: categoriesError }] =
    useRequest<Categories[]>(endpoints.CATEGORIES, 'get', {
      authToken: credentialsInfo?.token,
    });

  const [{ data, loading, error }, refetch] = useRequest<ArticleResponse>(
    endpoints.ARTICLES,
    'get',
    {
      authToken: credentialsInfo?.token,
      params: {
        name: filters.name,
        categories: filters.categories.map(item => item.id).join(','),
        status: filters.status.map(item => item.name).join(','),
        fetchRecent: true,
        currentPage: 0,
        pageCount: 0,
      },
    },
  );
  const refreshHandler = useCallback(async () => {
    await refetch({ data: { filter: filters } });
  }, [refetch, filters]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (data) {
      const { recent, totalCount, totalPages, data: articles = [] } = data.result;
      if (recent !== undefined) setRecentData(recent);
      setTotalNumber(totalCount);
      setTotalPage(totalPages);
      const articleMatrix = articles.reduce(
        (acc: RowArticleData[], article: Article): RowArticleData[] => {
          const rows = [...acc];
          const key = `row-${rows.length + 1}`;
          const rowArticle = rows.pop() || { rowData: [], key };

          if (rowArticle.rowData.length < 2) {
            rowArticle.rowData.push(article);

            rows.push(rowArticle);
          } else {
            rows.push(rowArticle, { rowData: [article], key });
          }

          return rows;
        },
        [],
      );
      setPageTableData(articleMatrix);
    }
  }, [loading, data, error, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categoriesData) {
      const { result } = categoriesData;
      setCategories(result);
    }
  }, [categoriesLoading, categoriesData, categoriesError, setSimpleToasts, setLoading]);

  const onStatusItemClick = (statusItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = status.filter(item => item !== statusItem);
      setStatus(newList);
    } else {
      const newList = [...status];
      newList.push(statusItem);
      setStatus(newList);
    }
  };
  const onCategoryItemClick = (categoryItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = category.filter(item => item !== categoryItem);
      setCategory(newList);
    } else {
      const newList = [...category];
      newList.push(categoryItem);
      setCategory(newList);
    }
  };
  const onAddArticle = () => {
    setAddArticle(true);
  };

  const renderCategryItem = (categoryItem: Categories) => {
    const isSelected = category.includes(categoryItem.name);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onCategoryItemClick(categoryItem.name, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{categoryItem.name}</div>
      </div>
    );
  };

  const renderStatusItem = (statusItem: string) => {
    const isSelected = status.includes(statusItem);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onStatusItemClick(statusItem, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{statusItem}</div>
      </div>
    );
  };

  const onCancleStatus = () => {
    setStatus(statusFinal);
    setStatusShow(false);
  };

  const onApplyStatus = () => {
    if (status.length > 0) {
      setStatusFinal(status);
      setStatusShow(false);
    }
  };

  const onCancleCategory = () => {
    setCategory(categoryFinal);
    setCategoryShow(false);
  };

  const onApplyCategory = () => {
    if (category.length > 0) {
      setCategoryFinal(category);
      setCategoryShow(false);
    }
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title="Articles — 1235" />
      <div className="artical-text-main-container">
        <div className="artical-dropdown-container ">
          <div
            ref={categoryTarget}
            onClick={() => setCategoryShow(!categoryShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              {categoryFinal[0]} {categoryFinal.length > 1 && ' and '}
              {categoryFinal.length > 1 && (
                <div className="more-text-in-dropdown">
                  +{categoryFinal.length - 1} More
                </div>
              )}
            </div>
            <img src={categoryShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div
            ref={statusTarget}
            onClick={() => setStatusShow(!statusShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              {statusFinal[0]}
              {statusFinal.length > 1 && ' and '}
              {statusFinal.length > 1 && (
                <div className="more-text-in-dropdown">
                  +{statusFinal.length - 1} More
                </div>
              )}
            </div>
            <img src={statusShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
        </div>
        <div onClick={onAddArticle} className="add-artical-container">
          <img src={PlusIcon} alt="plus-icon" />
          <div className="add-artical-text-style">Add article</div>
        </div>
      </div>
      <Overlay
        onHide={() => setCategoryShow(false)}
        target={categoryTarget.current}
        rootClose
        show={categoryShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {categories.map(renderCategryItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleCategory} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyCategory} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setStatusShow(false)}
        target={statusTarget.current}
        rootClose
        show={statusShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {statusArray.map(renderStatusItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleStatus} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyStatus} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <ArticleTable
        searchString=""
        tableData={recentData}
        refreshHandler={refreshHandler}
        onEditArticle={article => {
          setActionArticle(article);
          setAddArticle(true);
        }}
        onDeleteArticle={article => {
          setActionArticle(article);
          setModal(true);
        }}
      />
      <AddArticles
        article={actionArticle}
        show={addArticle}
        key={JSON.stringify(actionArticle)}
        categories={categories}
        onSave={() => setAddArticle(false)}
        onHide={() => setAddArticle(false)}
      />
      <DeleteModal
        show={modal}
        onDelete={() => setModal(false)}
        onHide={() => setModal(false)}
        title="Delete Article"
        description="Are you sure you want to delete this article?"
      />
    </Container>
  );
};
