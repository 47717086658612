export const coachHouseCellphone = '12053778385';
export const coachHouseEmail = 'reservations@coachhousesalem.com';
export const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const currentYear = new Date().getFullYear();
const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
export const yearList = range(currentYear, currentYear - 20, -1);
