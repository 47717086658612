import { FC, useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  Coin,
  FileText,
  PersonCircle,
  PersonVcard,
  InfoCircle,
} from 'react-bootstrap-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useForm, FormProvider } from 'react-hook-form';

import LoadingOverlay from 'react-loading-overlay-ts';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { ReservationCard } from '../Cards/ReservationVerifyCard';
import './styles/styles.css';
import {
  UserDetailsModal,
  IdCheckModal,
  AgreementModal,
  SafetyDepositModal,
  TermsAndPrivacy,
} from '../Modals/GuestPagesModals';
import { endpoints } from '../../modules/mappers/urls';
import { Guests, GuestsRequest, Reservation } from '../../modules/interfaces';
import {
  useNotifications,
  useRequest,
  useGuestAuth,
  ErrorEnvelop,
} from '../../modules/hooks';
import { GuestPortalContext } from '../../modules/context/guestPortalContext';
import { useGetAccessCode } from './endpoints/GuestPageEnpoints';
import { getApiErrorMessage } from '../../modules/utils/transform';
import { GuestPortalErrors } from '../Modals/GuestPagesModals/constants/GuestPortalConstants';
import { SafetyDepositInformation } from '../Modals/GuestPagesModals/SafetyDepositInformation';

interface GuestRegistrationProps {
  reservationId?: string;
}

export const GuestRegistration: FC<GuestRegistrationProps> = () => {
  const ICON_SIZE = 28;
  const navigate = useNavigate();

  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [idCheckModal, setIdCheckModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [safetyDepositModal, setSafetyDepositModal] = useState(false);
  const [termsAndPrivacy, setTermsAndPrivacy] = useState(false);
  const [safetyDepositInformation, setSafetyDepositInformation] = useState(false);
  const { setSimpleToasts } = useNotifications()!;

  const [checkUserDetails, setCheckUserDetails] = useState(false);
  const [checkIdCheck, setCheckIdCheck] = useState(false);
  const [checkAgreetment, setCheckAgreetment] = useState(false);
  const [checkSafetyDeposit, setCheckSafetyDeposit] = useState(false);

  const { token } = useGuestAuth()!;
  const { reservationId } = useParams();
  const {
    reservation,
    error: reservationError,
    setGuestName,
  } = useContext(GuestPortalContext)!;

  const location = useLocation();
  const { state } = location;

  const [{ loading: loadingGuest, error: guestError }, insertGuest] =
    useRequest<GuestsRequest>(
      endpoints.GUESTS,
      'post',
      {
        authGuestToken: token,
      },
      { manual: true },
    );

  const [
    { data: accessCodeResult, loading: loadingAccessCode },
    getReservationAccessCode,
  ] = useGetAccessCode(reservationId || '');

  const reservationURL = `${endpoints.MEWS_RESERVATIONS}/${reservationId}/details`;

  const [{ data: reservationGuest, loading: loadingPreregisterd }, getReservation] =
    useRequest<Reservation>(reservationURL, 'get', {
      authGuestToken: token,
    });

  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    mode: 'onChange',
  });
  const onSubmit = (data: Guests) => {
    const formData = new FormData();

    const date = DateTime.fromJSDate(new Date(data.birthDate || ''));
    const formattedDate = date.toFormat('yyyy-MM-dd');

    formData.append('firstName', data.firstName || '');
    formData.append('lastName', data.lastName || '');
    formData.append('email', data.email || '');
    formData.append('phoneNumber', data.phoneNumber || '');
    formData.append('licensePlate', data.licensePlate || '');
    formData.append('signature', data.signature || '');
    formData.append('birthDate', formattedDate || '');
    formData.append('reservationId', reservationId || '');
    formData.append('packages', JSON.stringify(state || []));

    if (data.files) {
      data.files.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });
    }

    insertGuest({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'reservation-token': token,
      },
    });

    getReservationAccessCode();
  };

  useEffect(() => {
    if (token) return;

    navigate(`/guest-portal/${reservationId}`);
  }, [navigate, reservationId, token]);

  useEffect(() => {
    if (!safetyDepositModal) return;

    if (!checkUserDetails) {
      setSimpleToasts({
        message: 'First write your information by clicking on Your details card',
        type: 'warning',
        show: true,
      });
      setSafetyDepositModal(false);
    }
  }, [checkUserDetails, safetyDepositModal, setSimpleToasts]);

  useEffect(() => {
    if (!accessCodeResult || loadingGuest || guestError) return;

    navigate(`/guest-portal/${reservationId}`, {
      state: {
        roomCode: accessCodeResult.result,
      },
    });
  }, [
    accessCodeResult,
    getReservationAccessCode,
    guestError,
    loadingGuest,
    navigate,
    reservationId,
    setSimpleToasts,
  ]);

  useEffect(() => {
    if (!checkUserDetails) return;

    const guestName = `${methods.getValues('firstName')} ${methods.getValues(
      'lastName',
    )}`;

    setGuestName(guestName);
  }, [checkUserDetails]);

  useEffect(() => {
    if (!guestError || !reservationError) return;
    if (
      getApiErrorMessage(guestError as AxiosError<ErrorEnvelop>) ===
        GuestPortalErrors.JWT_EXPIRED ||
      getApiErrorMessage(reservationError as AxiosError<ErrorEnvelop>) ===
        GuestPortalErrors.JWT_EXPIRED
    ) {
      setSimpleToasts({
        message: 'Token expired, please try entering your details again',
        type: 'danger',
        show: true,
      });

      navigate(`/guest-portal/${reservationId}`);
    }
  }, [guestError, navigate, reservationId, setSimpleToasts, reservationError]);

  useEffect(() => {
    if (!reservationGuest) return;
    if (reservationGuest?.result.safetyDepositPaymentStatus === 'PAID') {
      setCheckSafetyDeposit(true);
    }
  }, [reservationGuest]);

  useEffect(() => {
    if (!guestError) return;

    const errorMessage = guestError
      ? getApiErrorMessage(guestError as AxiosError<ErrorEnvelop>)
      : '';
    setSimpleToasts({
      message: errorMessage,
      type: 'danger',
      show: true,
    });
  }, [guestError, setSimpleToasts]);

  return (
    <LoadingOverlay
      active={loadingAccessCode || loadingGuest || loadingPreregisterd}
      text="Wait while we get things ready."
      className="loading-page-v"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container
            className="container-paddings"
            style={{ marginTop: '35px' }}
            id="guest-portal"
          >
            <ReservationCard
              title="Your Details"
              icon={<PersonCircle size={ICON_SIZE} className="card-icon" />}
              showModal={setUserDetailsModal}
              checked={checkUserDetails}
            />
            <ReservationCard
              title="ID Check"
              icon={<PersonVcard size={ICON_SIZE} className="card-icon" />}
              showModal={setIdCheckModal}
              checked={checkIdCheck}
            />
            <ReservationCard
              title="Agreement"
              icon={<FileText size={ICON_SIZE} className="card-icon" />}
              showModal={setAgreementModal}
              checked={checkAgreetment}
            />
            <ReservationCard
              title={
                <OverlayTrigger
                  overlay={<Tooltip>Remove this package from the list</Tooltip>}
                >
                  <>
                    Safety Deposit - 100 USD{' '}
                    <InfoCircle onClick={() => setSafetyDepositInformation(true)} />
                  </>
                </OverlayTrigger>
              }
              icon={<Coin size={ICON_SIZE} className="card-icon" />}
              showModal={setSafetyDepositModal}
              checked={checkSafetyDeposit}
              disabled={reservationGuest?.result.safetyDepositPaymentStatus === 'PAID'}
            />
            <p
              style={{
                color: 'grey',
                fontSize: '14px',
              }}
            >
              By verifying, you agree to the{' '}
              <b
                className="terms-privacy-text"
                onClick={() => setTermsAndPrivacy(true)}
                role="button"
                onKeyDown={() => setTermsAndPrivacy(true)}
                tabIndex={0}
              >
                Terms & Privacy Policy{' '}
              </b>
            </p>
            <Container
              style={{
                width: '100%',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              {checkUserDetails &&
              checkIdCheck &&
              checkAgreetment &&
              checkSafetyDeposit ? (
                <Button type="submit" className="save-button verify-me-button">
                  Verify Me
                </Button>
              ) : null}
            </Container>
            <UserDetailsModal
              show={userDetailsModal}
              isEnd={setCheckUserDetails}
              handleClose={() => {
                setUserDetailsModal(false);
              }}
            />
            <IdCheckModal
              show={idCheckModal}
              isEnd={setCheckIdCheck}
              handleClose={() => {
                setIdCheckModal(false);
              }}
            />
            <AgreementModal
              show={agreementModal}
              isEnd={setCheckAgreetment}
              handleClose={() => {
                setAgreementModal(false);
              }}
            />
            {reservation &&
            reservation.AssignedResourceId &&
            checkUserDetails &&
            reservationId ? (
              <SafetyDepositModal
                show={safetyDepositModal}
                isEnd={setCheckSafetyDeposit}
                handleClose={() => {
                  setSafetyDepositModal(false);
                }}
                roomTypeId={reservation.AssignedResourceId}
                reservationId={reservationId}
              />
            ) : null}
            <SafetyDepositInformation
              show={safetyDepositInformation}
              handleClose={() => {
                setSafetyDepositInformation(false);
              }}
            />

            <TermsAndPrivacy
              show={termsAndPrivacy}
              handleClose={() => {
                setTermsAndPrivacy(false);
              }}
            />
          </Container>
        </form>
      </FormProvider>
    </LoadingOverlay>
  );
};
