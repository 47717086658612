import React, { FC, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';

import { RoleManagment } from '../../Tables/RoleManagement';
import { UserManagment } from '../../Tables/UserManagement';
import './styles/Users.css';

export const Users: FC = () => {
  const [changeState, setChangeState] = useState<number>(0);
  return (
    <Card className="table-container pb-0">
      <Tabs
        defaultActiveKey="users"
        className="admin-tabs"
        onClick={() => {
          setChangeState(changeState + 1);
        }}
      >
        <Tab eventKey="users" title="User Managment" className="admin-tab">
          <UserManagment changeState={changeState} />
        </Tab>
        <Tab eventKey="roles" title="Role Managment" className="admin-tab">
          <RoleManagment changeState={changeState} />
        </Tab>
      </Tabs>
    </Card>
  );
};
