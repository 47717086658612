import { FC, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

import './styles/styles.css';

interface CategoryEditorModalProps {
  category?: string;
  show: boolean;
  handleClose: () => void;
  handleSubmit: (isUpdate: boolean, name?: string) => void;
}

export const CategoryEditorModal: FC<CategoryEditorModalProps> = ({
  category,
  show,
  handleClose,
  handleSubmit,
}) => {
  const categoryNameRef = useRef<HTMLInputElement>(null);
  const [updateFlag, setUpdateFlag] = useState<boolean>(false);

  useEffect(() => {
    if (category !== '') {
      setUpdateFlag(true);
    } else {
      setUpdateFlag(false);
    }
  }, [category]);

  const handleAdd = () => {
    handleSubmit(updateFlag, categoryNameRef.current?.value);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      dialogClassName="post-editor-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="editor-title">Edit Category</Modal.Title>
        <label className="editor-sub-title">Edit this Category</label>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label className="editor-title-label">Category Name</Form.Label>
            <Form.Control
              className="editor-control"
              placeholder="Category name"
              defaultValue={category}
              ref={categoryNameRef}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="save-button" onClick={handleAdd}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
