import { FC, Dispatch, SetStateAction, useState, useEffect, FormEvent } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import * as icons from 'react-bootstrap-icons';
import { Newspaper, Journals, Envelope, Book } from 'react-bootstrap-icons';

import { DashboardTypes } from '../../../modules/enums/types';
import { useAuth } from '../../../modules/hooks';
import { routes } from '../../../modules/mappers/urls';
import { SearchResult } from '../../Forms/SearchResult';
import './styles/styles.css';
import { LogoIcon } from '../../Logo';

export interface TopMenuProps {
  dashboardType: DashboardTypes;
  setDashboardType: Dispatch<SetStateAction<DashboardTypes>>;
}

interface IconProps extends icons.IconProps {
  iconName: keyof typeof icons;
}

export function Icon({ iconName, ...props }: IconProps) {
  const BootstrapIcon = icons[iconName];
  return <BootstrapIcon {...props} />;
}

export const TopMenu: FC<TopMenuProps> = ({ dashboardType, setDashboardType }) => {
  const ICON_SIZE = 17;
  const { credentialsInfo, signOut } = useAuth()!;
  const [showSearch, setShowSearch] = useState(false);
  const { name } = credentialsInfo || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchResult, setSearchResult] = useState<boolean>(false);
  useEffect(() => {
    const handleClickOutside = () => {
      setSearchResult(false);
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onSearchSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSearchResult(!searchResult);
  };

  return (
    <Container fluid className="top-menu">
      <Navbar expand="lg" variant="light" className="menu-toolbar">
        <Navbar.Brand
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(routes.HOME);
          }}
        >
          <LogoIcon width="30" height="30" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="top-nav" />
        <Navbar.Collapse id="top-nav">
          <Nav className="me-auto align-items-lg-center">
            <NavDropdown
              title="Reservations"
              className={pathname.includes(routes.BALANCES) ? 'nav-active' : 'nave-link'}
              id="top-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  navigate(routes.RESERVATIONS);
                  setDashboardType(DashboardTypes.RESERVATIONS);
                }}
              >
                <Journals size={ICON_SIZE} className="dropdown-menu-icon" />
                <div className="dropdown-sub-menu-item">
                  <div className="dropdown-sub-menu-title">Reservations</div>
                  <div className="dropdown-sub-menu-description">
                    List of reservations.
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  navigate(routes.INBOX);
                  setDashboardType(DashboardTypes.INBOX);
                }}
              >
                <Envelope size={ICON_SIZE} className="dropdown-menu-icon" />
                <div className="dropdown-sub-menu-item">
                  <div className="dropdown-sub-menu-title">Inbox</div>
                  <div className="dropdown-sub-menu-description">List of messages</div>
                </div>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="nave-link nav-space d-none" href="#">
              Inbox
            </Nav.Link>
            <NavDropdown
              title="Articles"
              className={
                pathname.includes(routes.ARTICLES)
                  ? 'nav-active nav-space'
                  : 'nave-link nav-space'
              }
              id="collapsible-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  navigate(routes.ARTICLES);
                }}
              >
                <Newspaper size={ICON_SIZE} className="dropdown-menu-icon" />
                <div className="dropdown-sub-menu-item">
                  <div className="dropdown-sub-menu-title">Articles</div>
                  <div className="dropdown-sub-menu-description">List of articles</div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  navigate(routes.ARTICLES_ADMIN);
                }}
              >
                <Book size={ICON_SIZE} className="dropdown-menu-icon" />
                <div className="dropdown-sub-menu-item">
                  <div className="dropdown-sub-menu-title">Article Management</div>
                  <div className="dropdown-sub-menu-description">Manage articles</div>
                </div>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              title="Users"
              className={
                pathname.includes(routes.USERS)
                  ? 'nav-active nav-space'
                  : 'nave-link nav-space'
              }
              onClick={() => {
                navigate(routes.USERS);
              }}
            >
              Users
            </Nav.Link>

            <Nav.Link
              title="To_Do"
              className={
                pathname === routes.PACKAGES
                  ? 'nav-active nav-space'
                  : 'nave-link nav-space'
              }
              onClick={() => {
                navigate(routes.PACKAGES);
              }}
            >
              To Do
            </Nav.Link>

            <Nav.Link
              title="Packages"
              className={
                pathname === routes.PACKAGES_MANAGEMENT
                  ? 'nav-active nav-space'
                  : 'nave-link nav-space'
              }
              onClick={() => {
                navigate(routes.PACKAGES_MANAGEMENT);
              }}
            >
              Packages
            </Nav.Link>

            <NavDropdown
              title="More"
              className={
                pathname.includes(routes.MORE)
                  ? 'nav-active nav-space d-none'
                  : 'nave-link nav-space d-none'
              }
              id="collapsible-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  navigate(routes.ARTICLES);
                }}
              >
                <Image src="/Icon-article.svg" className="dropdown-menu-icon" />
                <div className="dropdown-sub-menu-item">
                  <div className="dropdown-sub-menu-title">More Articles</div>
                  <div className="dropdown-sub-menu-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor.
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
                <Image src="/Icon-section.svg" className="dropdown-menu-icon" />
                <div className="dropdown-sub-menu-item">
                  <div className="dropdown-sub-menu-title">More Article Admin</div>
                  <div className="dropdown-sub-menu-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor.
                  </div>
                </div>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Form
            className={`search-form align-items-center d-flex position-relative ${
              showSearch ? 'show' : ''
            }`}
            onSubmit={onSearchSubmit}
          >
            <Image
              alt=""
              src="/glass.svg"
              className="glass-icon"
              onClick={() => setShowSearch(!showSearch)}
            />
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2 search-input"
              aria-label="Search"
            />
            <SearchResult result={[]} show={searchResult} />
          </Form>
          <Nav className="flex-row justify-content-end align-items-center">
            <Nav className="user-info">
              <Navbar.Text className="user-name">{name}</Navbar.Text>
              <Navbar.Text className="user-role">Administrator</Navbar.Text>
            </Nav>
            <NavDropdown
              title={
                <Image fluid src="/avatar.svg" roundedCircle width="35" height="15" />
              }
              align="end"
            >
              <NavDropdown.Item onClick={() => signOut()}>Log Out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};
