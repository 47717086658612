import { createContext } from 'react';

import { AuthStatus } from '../../enums/status';

export interface UserInfo {
  email: string;
  name: string;
  picture: string;
}

export interface CredentialsInfo {
  token: string;
  clientId: string;
  user: UserInfo;
}

export interface CognitoCredential {
  token: string;
  email: string;
  name: string;
}

export interface AuthContextProps {
  authStatus: AuthStatus;
  credentialsInfo?: CognitoCredential | null;
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  forgotPassword: (username: string) => void;
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => void;
  changePassword: (oldPassword: string, newPassword: string) => void;
}

export const AuthContext = createContext<AuthContextProps | null>(null);
