import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Container, Row } from 'react-bootstrap';

import { FileText } from 'react-bootstrap-icons';
import './styles/styles.css';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
}

export const SafetyDepositInformation: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
}) => {
  const onClickSubmit = () => {
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-100w"
      backdrop="static"
      id="guest-portal"
      centered
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} /> {'  '}
          Safety Deposit Payment Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Form.Text>
              If you have already gone through the process of paying the safety deposit
              please wait for 5 minutes and reload the portal to view latest information.
            </Form.Text>
          </Row>
        </Form>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button
          className="save-button no-border-radius"
          onClick={onClickSubmit}
          style={{ marginLeft: '10px' }}
        >
          Ok
        </Button>
      </Container>
    </Modal>
  );
};
