import { FC } from 'react';
import Container from 'react-bootstrap/Container';

import { PackageProvider } from '../../Providers/Package';
import { PackagesManagement } from './PackagesManagement';
import Header from '../../Header';

export const PackagesView: FC = () => {
  return (
    <PackageProvider>
      <Container fluid className="reservation-list-container">
        <Header title="Packages" />

        <PackagesManagement />
      </Container>
    </PackageProvider>
  );
};
