import { useEffect, useState } from 'react';
import Pusher, { Channel } from 'pusher-js';
import { config } from '../../config';

interface Event<T> {
  name: string;
  callback: (data: T) => void;
}

export const useMessaging = <T>(channelName: string, events: Event<T>[]) => {
  const [pusher, setPusher] = useState<Pusher | null>(null);
  const [channel, setChannel] = useState<Channel | null>(null);

  useEffect(() => {
    const pusherInstance = new Pusher(config.messaging.pusher.key, {
      cluster: config.messaging.pusher.cluster,
    });

    pusherInstance.connect();

    const subscribedChannel = pusherInstance.subscribe(channelName);

    events.forEach(event => {
      subscribedChannel.bind(event.name, (data: T) => {
        event.callback(data);
      });
    });

    setPusher(pusherInstance);
    setChannel(subscribedChannel);

    return () => {
      subscribedChannel.unbind_all();
      subscribedChannel.unsubscribe();
      pusherInstance.disconnect();
    };
  }, [channelName, events]);

  return { pusher, channel };
};
