import { FC, useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import PlusIcon from '../../../assets/plus-icon.svg';
import DateIcon from '../../../assets/date.svg';
import './styles/styles.css';
import Header from '../../Header';
import { CategoryTable } from '../../Tables/Category';
import { Categories } from '../../../modules/interfaces';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import AddCategory from '../../Modals/AddCategory/AddCategory';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';

export const Category: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [isDateShow, setisDateShow] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [editedCategory, setEditedCatgory] = useState('');
  const categoryTarget = useRef(null);
  const [categories, setCategories] = useState<Categories[]>([]);
  const { credentialsInfo } = useAuth()!;
  const { setLoading } = useLoading()!;

  const [{ data: categoriesData, loading: categoriesLoading, error: categoriesError }] =
    useRequest<Categories[]>(endpoints.CATEGORIES, 'get', {
      authToken: credentialsInfo?.token,
    });

  useEffect(() => {
    setLoading(categoriesLoading);
  }, [categoriesLoading, setLoading]);

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categoriesData) {
      const { result } = categoriesData;
      setCategories(result);
    }
  }, [categoriesLoading, categoriesData, categoriesError, setSimpleToasts]);

  const onAddCategory = () => {
    setEditedCatgory('');
    setAddCategory(true);
  };

  const onEditCategory = (categoriesItem?: Categories) => {
    setEditedCatgory(categoriesItem?.name || '');
    setAddCategory(true);
  };

  const onDeleteCategory = (categoriesItem?: Categories) => {
    setEditedCatgory(categoriesItem?.name || '');
    setDeleteCategory(true);
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title="Categories — 10" />
      <div className="catagory-container">
        <div
          ref={categoryTarget}
          onClick={() => setisDateShow(!isDateShow)}
          className="category-text-container"
        >
          <img src={DateIcon} alt="date" />
          <div className="category-text-style">Feb 10 — Apr 10, 2024 </div>
          <img src={isDateShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
        </div>
        <div onClick={onAddCategory} className="add-artical-container">
          <img src={PlusIcon} alt="plus-icon" />
          <div className="add-artical-text-style">Add category</div>
        </div>
      </div>
      <CategoryTable
        searchString=""
        refreshHandler={() => Promise.resolve()}
        tableData={categories}
        onEditCategory={onEditCategory}
        onDeleteCategory={onDeleteCategory}
      />
      <AddCategory
        show={addCategory}
        onSave={() => setAddCategory(false)}
        onHide={() => setAddCategory(false)}
        category={editedCategory}
      />
      <DeleteModal
        show={deleteCategory}
        onDelete={() => setDeleteCategory(false)}
        onHide={() => setDeleteCategory(false)}
        title="Delete Category"
        description="Are you sure you want to delete this category?"
      />
    </Container>
  );
};
