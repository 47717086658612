import { useContext, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import useAxios, { Options } from 'axios-hooks';

import { AuthContext } from '../context/authContext';
import { LoadingContext } from '../context/loadingContext';
import { AppContext } from '../context/appContext';
import { config } from '../config';
import { NotificationContext } from '../context/notificationContext';
import { useMessaging } from './useMessaging';
import { GuestAuthContext } from '../context/guestAuthContext';
import { PushNotificationContext } from '../context/pushNotificationContext';
import { ReservationsContext } from '../context/reservationContext';

interface ApiEnvelop {
  requestId: string;
  responseTimeMs: number;
}

interface SuccessEnvelop<T = unknown> extends ApiEnvelop {
  result: T;
}

export interface ErrorEnvelop extends ApiEnvelop {
  error: {
    code: string;
    message: string;
    type: string;
  };
}

export interface Params {
  [key: string]: string | number | boolean | Date;
}

export interface RequestOptions<T = unknown> {
  params?: Params;
  payload?: T;
  authToken?: string;
  authGuestToken?: string;
}

export interface MessageEvent<T> {
  name: string;
  callback: (data: T) => void;
}

export const useRequest = <R, T = unknown>(
  url: string,
  method: string,
  opts: RequestOptions<T>,
  hookOpts?: Options,
) => {
  const { params, payload, authToken, authGuestToken } = opts;
  const reqConfig: AxiosRequestConfig<T> = {
    url,
    data: payload,
    baseURL: config.bookingEngine.endpoint,
    method,
  };

  if (params) {
    reqConfig.params = params;
  }

  if (authToken) {
    reqConfig.headers = { ...reqConfig.headers, authorization: authToken };
  }

  if (authGuestToken) {
    reqConfig.headers = { ...reqConfig.headers, 'reservation-token': authGuestToken };
  }

  return useAxios<SuccessEnvelop<R>, T, ErrorEnvelop>(reqConfig, hookOpts);
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const usePushNotifications = () => {
  return useContext(PushNotificationContext);
};

export const useGuestAuth = () => {
  return useContext(GuestAuthContext);
};

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const useNotifications = () => {
  return useContext(NotificationContext);
};

export const useAppContext = () => {
  return useContext(AppContext);
};
export const useReservationsContext = () => {
  return useContext(ReservationsContext);
};

export const useLocalStorage = <T,>(
  key: string,
  defaultValue?: T | null,
): [T | undefined | null, (newValue?: T | null) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);

      if (value) {
        return JSON.parse(value) as T;
      }
      window.localStorage.removeItem(key);

      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue?: T | null): void => {
    if (newValue) {
      window.localStorage.setItem(key, JSON.stringify(newValue));
    } else {
      window.localStorage.removeItem(key);
    }

    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export const useMessagingHook = <T,>(channelName: string, events: MessageEvent<T>[]) => {
  return useMessaging<T>(channelName, events);
};
