import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles/createNewPassword.css';
import { LogoIcon } from '../../Logo';

export const CreateNewPassword: FC = () => {
  return (
    <div>
      <Container className="login forgot-password d-flex flex-column justify-content-center">
        <Container className="content" fluid>
          <Row className="justify-content-md-center loginLogo">
            <Col />
            <Col className="justify-content-md-center" xs="auto" sm="auto">
              <LogoIcon width="49.5px" height="45px" />
            </Col>
            <Col />
          </Row>
          <Row className="justify-content-md-center forgotPassHeader text-center">
            <Col />
            <Col className="justify-content-md-center" xs="auto" sm="auto">
              <h3 className="login-heading">Create new Password</h3>
              <p>Your new password must be different from previous used passwords.</p>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col>
              <form>
                <div className="form-group create-password">
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="New Password"
                  />
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword2"
                    placeholder="Confirm Password"
                  />
                </div>
                <button type="submit" className="btn submit-btn">
                  Reset Password
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
