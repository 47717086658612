import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css';
import App from './App';
import { AuthProvider } from './components/Providers/Auth';
import reportWebVitals from './reportWebVitals';
import { NotificationProvider } from './components/Providers/Notification';
import { PushNotificationProvider } from './components/Providers/PushNotifications';
import { config } from './modules/config';
import { GuestAuthProvider } from './components/Providers/GuestAuth';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NotificationProvider>
        <PushNotificationProvider>
          <AuthProvider>
            <GuestAuthProvider>
              <GoogleOAuthProvider clientId={config.google.clientId}>
                <App />
              </GoogleOAuthProvider>
            </GuestAuthProvider>
          </AuthProvider>
        </PushNotificationProvider>
      </NotificationProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
