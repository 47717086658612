// don't like this but this is due to the library we are using
// will refactor later.
/* eslint-disable react/prop-types */

import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select, { SelectRenderer } from 'react-dropdown-select';

import { OptionType } from '../../../modules/interfaces';

import './styles/styles.css';

export interface DropDownSelectProps {
  onConfirm: (value: OptionType[]) => void;
  data: OptionType[];
  placeholder: string;
}

export const DropDownSelect: FC<DropDownSelectProps> = ({
  onConfirm,
  data,
  placeholder,
}) => {
  const [selectedValues, setSelectedValues] = useState<OptionType[]>([]);

  const customDropdownRenderer = ({
    props,
    state,
    methods,
  }: SelectRenderer<OptionType>) => {
    const { options, keepSelectedInList } = props;
    return (
      <div>
        <div>
          {options.map(option => {
            if (!keepSelectedInList && methods.isSelected(option)) {
              return null;
            }

            return (
              <div
                className="select-dropdown-item"
                onClick={() => methods.addItem(option)}
                key={option.id}
              >
                <input
                  type="checkbox"
                  readOnly
                  className="checkbox"
                  checked={methods.isSelected(option)}
                />{' '}
                {option.name}
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-between dropdown-buttons">
          <Button className="dropdown-cancel" onClick={methods.clearAll}>
            Cancel
          </Button>
          <Button
            className="dropdown-apply"
            onClick={() => {
              document.getElementById('top-nav')?.click();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  const onChangeFilter = (val: OptionType[]) => {
    setSelectedValues(val);
  };

  const onCloseDropDown = () => {
    onConfirm(selectedValues);
  };

  return (
    <Select
      multi
      options={data}
      onDropdownClose={onCloseDropDown}
      values={[]}
      labelField="name"
      valueField="id"
      dropdownRenderer={customDropdownRenderer}
      placeholder={placeholder}
      onChange={values => onChangeFilter(values)}
      className="select-dropdown w-auto"
    />
  );
};
