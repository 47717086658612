import { FC, useState, useRef, useEffect, useCallback, useMemo, useReducer } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Image, Row } from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import { FileUploader } from 'react-drag-drop-files';
import { TagsInput } from 'react-tag-input-component';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

import { PreviewItem } from '../../Customize/PreviewItem';
import { useNotifications } from '../../../modules/hooks';
import { Article, Categories } from '../../../modules/interfaces';

import './styles/styles.css';
import { TagReducerType } from '../../../modules/enums/types';

interface PostEditorModalProps {
  data?: Article;
  show: boolean;
  categories: Categories[];
  handleClose: () => void;
  handleSubmit: (
    articleTitle: string,
    articleBody: string,
    tagState: string[],
    categoryId: string,
    fileMedia: File[],
    fileMediaDelete: string[],
  ) => void;
}

interface TagInputClassName {
  input: string;
  tag: string;
}

export interface FileStore {
  id?: string;
  url: string;
  file: File;
}

interface ModalData {
  title: string;
  subTitle: string;
}

interface TagReducerAction {
  type: TagReducerType;
  tags: string[];
}

export interface TagType {
  id: number;
  text: string;
}

const tagReducer = (state: string[], action: TagReducerAction) => {
  const { type, tags } = action;
  let newState = [...state];

  switch (type) {
    case TagReducerType.RESET:
      newState = [];
      break;
    case TagReducerType.UPDATE:
      newState = tags;
      break;
    default:
      break;
  }

  return newState;
};

export const PostEditorModal: FC<PostEditorModalProps> = ({
  data,
  show,
  categories,
  handleClose,
  handleSubmit,
}) => {
  const { setSimpleToasts } = useNotifications()!;
  const [modalData, setModalData] = useState<ModalData>({
    title: 'New Article',
    subTitle: 'Create a new Article',
  });
  const [tagState, dispatchTags] = useReducer(tagReducer, []);
  const [tagInputClassName] = useState<TagInputClassName>({
    tag: 'tag-input-tag',
    input: 'tag-input',
  });
  const [files, setFiles] = useState<FileStore[]>([]);
  const [fileLs, setFileList] = useState<FileList | null>(null);
  const [category, setCategory] = useState<string>();
  const [mediaDeleted, setMediaDeleted] = useState<string[]>([]);
  const editor = useRef<SunEditorCore>();
  const titleInput = useRef<HTMLInputElement>(null);

  const onChangeCategory = useCallback(
    (val: string) => {
      const currentCategory = categories.find(item => item.uuid === val);
      setCategory(currentCategory?.uuid);
    },
    [categories],
  );

  const handleFileChange = useCallback(
    (fileList: FileList) => {
      Array.from(fileList).forEach(file => {
        const fileAdded = files.some(item => item.file.name === file.name);

        if (!fileAdded) {
          const fileUrl = URL.createObjectURL(file);
          setFiles([...files, { id: undefined, url: fileUrl, file }]);
        }
      });
    },
    [files],
  );

  const handleDeleteFile = useCallback(
    (fileUrl: string, uuid: string | undefined) => {
      const filteredFiles = files.filter(item => item.url !== fileUrl);
      const list = new DataTransfer();

      URL.revokeObjectURL(fileUrl);

      filteredFiles.forEach(fileStore => list.items.add(fileStore.file));

      const { files: dtFiles } = list;

      if (uuid) {
        setMediaDeleted([...mediaDeleted, uuid]);
      }

      setFileList(dtFiles.length ? dtFiles : null);
      setFiles(filteredFiles);
    },
    [files, mediaDeleted],
  );

  useEffect(() => {
    let title = '';
    let subTitle = '';

    if (data) {
      const { categories: articleCategories, media, tags } = data;
      const [articleCategory] = articleCategories;

      dispatchTags({ type: TagReducerType.UPDATE, tags });
      setCategory(articleCategory.uuid);
      const mediaData = media.map((item): FileStore => {
        return { id: item.uuid, url: item.uri, file: new File([], '') };
      });
      setFiles(mediaData);
      title = 'Edit Article';
      subTitle = '';
    } else {
      dispatchTags({ type: TagReducerType.RESET, tags: [] });
      setCategory('');
      setFiles([]);
      setFileList(null);
      title = 'New Article';
      subTitle = 'Create a new Article';
    }
    setMediaDeleted([]);
    setModalData({ title, subTitle });
  }, [data, show]);

  const onClickSubmit = useCallback(() => {
    const articleBody = editor.current?.getContents(true) || '';
    const articleTitle = titleInput.current?.value || '';

    if (articleTitle === '') {
      setSimpleToasts({ type: 'danger', message: 'Please input the title', show: true });
      return;
    }
    if (tagState.length === 0) {
      setSimpleToasts({ type: 'danger', message: 'Please input the tags', show: true });
      return;
    }
    if (!category) {
      setSimpleToasts({
        type: 'danger',
        message: 'Please select the category',
        show: true,
      });
      return;
    }
    const media = files.filter(file => file.file.name !== '').map(file => file.file);

    handleSubmit(articleTitle, articleBody, tagState, category, media, mediaDeleted);
    handleClose();
  }, [
    category,
    files,
    tagState,
    mediaDeleted,
    handleClose,
    handleSubmit,
    setSimpleToasts,
  ]);

  const getSunEditorInstance = useCallback((sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  }, []);
  const fileTypes = useMemo(() => ['JPEG', 'JPG', 'PNG', 'GIF'], []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      dialogClassName="post-editor-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="editor-title">{modalData.title}</Modal.Title>
        <label className="editor-sub-title">{modalData.subTitle}</label>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label className="editor-title-label">Article Name</Form.Label>
            <Form.Control
              className="editor-control"
              placeholder="Article About Art"
              defaultValue={data?.name}
              ref={titleInput}
            />
          </Form.Group>
          <Form.Group className="mt-24">
            <Form.Label className="editor-title-label">Category</Form.Label>
            <Form.Select
              className="editor-control"
              value={category}
              onChange={val => onChangeCategory(val.target.value)}
            >
              <option value="" key="">
                Select Category
              </option>
              {categories.map(item => {
                return (
                  <option value={item.uuid} key={item.uuid}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-24">
            <Form.Label className="editor-title-label">Article Text</Form.Label>
            <SunEditor
              placeholder="Please type here..."
              setAllPlugins
              defaultValue={data?.body}
              getSunEditorInstance={getSunEditorInstance}
              setOptions={{
                buttonList: [
                  ['bold', 'italic', 'underline', 'strike', 'align', 'list', 'link'],
                ],
                rtl: false,
                alignItems: ['left', 'center', 'right'],
              }}
            />
          </Form.Group>
          <Form.Group className="mt-24">
            <Form.Label className="editor-title-label">Media Files</Form.Label>

            <Row className="m-0 upload-section">
              <Col sm={12} className="preview-section">
                <Row>
                  {files.map(item => {
                    return (
                      <PreviewItem
                        url={item.url}
                        key={item.url}
                        fileId={item.url}
                        uuid={item.id}
                        onDelete={handleDeleteFile}
                      />
                    );
                  })}
                </Row>
              </Col>
              <Col sm={12} className="file-upload text-center">
                <Image className="w-auto" alt="" src="/file-upload.svg" />
                <div className="text-center react-file-uploader">
                  <span className="d-block">
                    <span className="upload-letter">Upload a file or drag and drop </span>
                  </span>
                  <span className="d-block">
                    <span className="upload-formats">{fileTypes.join(',')} to 50MB </span>
                  </span>
                  <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    multiple
                    types={fileTypes}
                    className="file-uploader"
                    fileOrFiles={fileLs}
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mt-24">
            <Form.Label className="editor-title-label">Tags</Form.Label>
            <TagsInput
              value={tagState}
              onChange={(tags: string[]) => {
                const isDiff =
                  !tags.every(tag => tagState.includes(tag)) ||
                  tags.length !== tagState.length;

                if (isDiff) {
                  dispatchTags({ type: TagReducerType.UPDATE, tags });
                }
              }}
              name="tags"
              placeHolder="+Add Tag"
              separators={[' ', 'Enter', ',']}
              classNames={tagInputClassName}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="save-button" onClick={onClickSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
