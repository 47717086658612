import { FC, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { DataTable } from '../DataTable';

type ReportTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
};

export const ReportTable: FC<ReportTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
}: ReportTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [columns] = useState<ColDef<any>[]>([
    { field: 'months', headerName: 'MONTHS', flex: 2 },
    {
      field: 'night_available',
      headerName: 'NIGHTS AVAILABLE',
      flex: 1,
      sortable: true,
    },
    {
      field: 'night_sold',
      headerName: 'NIGHTS SOLD',
      flex: 1,
      sortable: true,
    },

    {
      field: 'occupacy_rate',
      headerName: 'OCCUPANCY RATE',
      sortable: true,
      flex: 2,
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
    />
  );
};
