import './styles/login.css';
import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gapi } from 'gapi-script';
import GoogleLogin from '@leecheuk/react-google-login';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Spinner } from 'react-bootstrap';

import EyeHideIcon from '../../../assets/eye-hide.svg';
import EyeShowIcon from '../../../assets/eye-show.svg';
import { config } from '../../../modules/config';
import { useAuth, useNotifications } from '../../../modules/hooks';
import { LogoIcon } from '../../Logo';

export const Login: FC = () => {
  const { signIn } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPassShow, setIsPassShow] = useState(false);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: config.google.clientId,
        scope: 'email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  const handleSignin = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await signIn(email, password);
    } catch (e) {
      const { message } = e as Error;
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    setIsLoading(false);
  };

  const onChangeEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onChangePasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const onPasswordToggle = useCallback(() => {
    setIsPassShow(!isPassShow);
  }, [isPassShow]);

  return (
    <Container fluid className="login d-flex flex-column justify-content-center">
      <Container className="content" fluid>
        <Row className="justify-content-md-center loginLogo">
          <Col />
          <Col className="justify-content-md-center" xs="auto" sm="auto">
            <LogoIcon width="49.5px" height="45px" />
          </Col>
          <Col />
        </Row>
        <Row className="justify-content-md-center loginHeader">
          <Col />
          <Col className="justify-content-md-center" xs="auto" sm="auto">
            <h3 className="login-heading">Sign in to your account</h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col className="login-form">
            <form>
              <div className="form-group email-password">
                <input
                  type="email"
                  className="form-control login-form-email"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email address"
                  onChange={onChangeEmailInput}
                />
                <input
                  type={isPassShow ? 'text' : 'password'}
                  className="form-control login-form-password"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={onChangePasswordInput}
                />
                <div onClick={onPasswordToggle} className="eye-icon-container">
                  <img
                    className="eye-icon"
                    alt="eye-img"
                    src={isPassShow ? EyeHideIcon : EyeShowIcon}
                  />
                </div>
              </div>
              <div className="checkbox-forgotpass">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Remember me
                  </label>
                </div>
                <Link to="/forgotPassword" className="forgot-password">
                  Forgot your password?
                </Link>
              </div>
              <button
                disabled={isLoading}
                type="submit"
                className="btn submit-btn position-relative"
                onClick={handleSignin}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className={isLoading ? '' : 'd-none'}
                />
                <span className={isLoading ? 'd-none' : ''}>Login</span>
              </button>
            </form>
          </Col>
        </Row>
        <Row className="justify-content-md-center loginSeparator d-none" sm={10} xs={10}>
          <Col xs={4} sm={4}>
            <div className="line-2" />
          </Col>
          <Col xs="auto" sm="auto">
            <p className="text-9">Or continue with</p>
          </Col>
          <Col xs={4} sm={4}>
            <div className="line-1" />
          </Col>
        </Row>
        <Row className="justify-content-md-center google-login-opt d-none">
          <Col sm={11} xs={12} className="w-100">
            <GoogleLogin
              clientId={config.google.clientId}
              render={renderProps => (
                <button
                  type="button"
                  className="google--login-btn d-flex justify-content-center align-items-center"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <Image src="/google.svg" width="17.16px" height="17.44px" />
                  Google Account
                </button>
              )}
              buttonText="Google"
              onSuccess={() => {
                // TODO: need to implement
              }}
              onFailure={() => {
                // TODO: need to implement
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
