import { FC, PropsWithChildren, useMemo, useState } from 'react';

import { PushNotificationContext } from '../../../modules/context/pushNotificationContext';

interface PushNotification {
  isRead: boolean;
  title: string;
}

export const PushNotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notificationList, setNotificationList] = useState<PushNotification[]>([]);

  const pushNotificationContext = useMemo(() => {
    return { notificationList, setNotificationList };
  }, [notificationList, setNotificationList]);

  return (
    <PushNotificationContext.Provider value={pushNotificationContext}>
      {children}
    </PushNotificationContext.Provider>
  );
};
