import React, { FC, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { PersonVcard } from 'react-bootstrap-icons';
import { useFormContext } from 'react-hook-form';
import { SocialNetworkCard } from '../../Cards/SocialNetworkCard';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const IdCheckModal: FC<UserDetailsModalProps> = ({ show, handleClose, isEnd }) => {
  const { setValue, getValues } = useFormContext();
  const [fontIdFile, setFontIdFile] = useState<File | undefined>(undefined);
  const [backIdFile, setBackIdFile] = useState<File | undefined>(undefined);

  const frontIdInputField = useRef<HTMLInputElement>(null);
  const backIdInputField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!getValues('files') || !(backIdFile && fontIdFile)) return;
    const files = getValues('files');

    setFontIdFile(files[0]);
    setBackIdFile(files[0]);
  }, [getValues]);

  const handleFrontIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files?.[0] || undefined;
    if (files) {
      setFontIdFile(files);
    }
  };

  const handleBackIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files?.[0] || undefined;
    if (files) {
      setBackIdFile(files);
    }
  };

  const onClickSubmit = () => {
    setValue('files', [fontIdFile, backIdFile]);
    // TODO: submit user ID
    if (isEnd) isEnd(true);
    handleClose();
  };
  const ICON_SIZE = 28;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="background post-editor-modal"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <PersonVcard size={25} /> {'  '}
          Upload your ID
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Container id="guests" fluid>
          <Form>
            <Row>
              <Form.Text>
                <h6>Accepted forms of ID:</h6>

                <ol>
                  <li>Drivers license</li>
                  <li>Passport</li>
                  <li>Other government Photo ID</li>
                </ol>
              </Form.Text>
            </Row>

            <Row>
              <Form.Group controlId="formFile">
                <input
                  onChange={handleFrontIdChange}
                  multiple={false}
                  ref={frontIdInputField}
                  type="file"
                  hidden
                />
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip">{fontIdFile?.name || ''}</Tooltip>}
                >
                  <Container
                    onClick={() => frontIdInputField.current?.click()}
                    style={{ padding: '0px', cursor: 'pointer' }}
                  >
                    <SocialNetworkCard
                      title="Front of ID"
                      icon={
                        <PersonVcard
                          size={ICON_SIZE}
                          className="card-icon"
                          color={fontIdFile ? 'green' : 'white'}
                        />
                      }
                    />
                  </Container>
                </OverlayTrigger>

                <input
                  onChange={handleBackIdChange}
                  multiple={false}
                  ref={backIdInputField}
                  type="file"
                  hidden
                />
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip">{backIdFile?.name || ''}</Tooltip>}
                >
                  <Container
                    onClick={() => backIdInputField.current?.click()}
                    style={{ padding: '0px', cursor: 'pointer' }}
                  >
                    <SocialNetworkCard
                      title="Back of ID"
                      icon={
                        <PersonVcard
                          size={ICON_SIZE}
                          className="card-icon"
                          color={backIdFile ? 'green' : 'white'}
                        />
                      }
                    />
                  </Container>
                </OverlayTrigger>
              </Form.Group>
            </Row>
          </Form>
        </Container>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button className="cancel-button no-border-radius" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          disabled={!(fontIdFile && backIdFile)}
          style={{ marginLeft: '10px' }}
          onClick={onClickSubmit}
        >
          Continue
        </Button>
      </Container>
    </Modal>
  );
};
