import { useGuestAuth, useRequest } from '../../../modules/hooks';
import {
  GuestsRequest,
  MewsReservationResponse,
  ReservationRatingResponse,
} from '../../../modules/interfaces';
import { endpoints } from '../../../modules/mappers/urls';

export const useGetReservation = (reservationId: string) => {
  const { token } = useGuestAuth()!;
  const getReservationUrl = `${endpoints.RESERVATION}/${reservationId}`;
  return useRequest<MewsReservationResponse>(
    getReservationUrl,
    'get',
    {
      authGuestToken: token,
    },
    { manual: true },
  );
};

export const useGuestReservation = (reservationId: string) => {
  const { token } = useGuestAuth()!;

  const getReservationUrl = `${endpoints.GUESTS}/${reservationId}`;
  return useRequest<GuestsRequest>(
    getReservationUrl,
    'get',
    {
      authGuestToken: token,
    },
    { manual: true },
  );
};

export const useAddPackagesToGuest = () => {
  const { token } = useGuestAuth()!;

  const getReservationUrl = `${endpoints.GUESTS}/request-packages`;
  return useRequest<GuestsRequest>(
    getReservationUrl,
    'put',
    {
      authGuestToken: token,
    },
    { manual: true },
  );
};

export const useGetAccessCode = (reservationId: string) => {
  const { token } = useGuestAuth()!;

  const urlAccessCode = `${endpoints.RESERVATION}/${reservationId}/access-code`;
  return useRequest<GuestsRequest>(
    urlAccessCode,
    'get',
    {
      authGuestToken: token,
    },
    { manual: true },
  );
};

export const useGetReservationRating = (reservationId: string) => {
  const { token } = useGuestAuth()!;

  const URL = `${endpoints.RATING}/${reservationId}`;
  return useRequest<ReservationRatingResponse>(URL, 'get', {
    authGuestToken: token,
  });
};
