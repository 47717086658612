import { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ShieldLock } from 'react-bootstrap-icons';
import { AxiosError } from 'axios';
import { ErrorEnvelop } from '../../modules/hooks';
import { getApiErrorMessage } from '../../modules/utils/transform';
import {
  GuestPortalErrors,
  guestPortalErrorsMapper,
} from '../Modals/GuestPagesModals/constants/GuestPortalConstants';

interface GuestPortalErrorPops {
  error?: AxiosError<unknown, unknown>;
  finishedRating?: boolean;
}

export const GuestPortalErrorPage: FC<GuestPortalErrorPops> = ({
  error,
  finishedRating,
}) => {
  const [guestPortalError, setGuestPortalError] = useState('NOT_FOUND');
  const errorMessage = error ? getApiErrorMessage(error as AxiosError<ErrorEnvelop>) : '';

  useEffect(() => {
    if (!errorMessage && finishedRating) setGuestPortalError('INVALID_STATE');

    if (errorMessage.includes(GuestPortalErrors.INVALID_RESERVATION_ID))
      setGuestPortalError('INVALID_RESERVATION_ID');
    else if (errorMessage === GuestPortalErrors.INVALID_STATE || finishedRating)
      setGuestPortalError('INVALID_STATE');
    else if (errorMessage === GuestPortalErrors.NOT_FOUND)
      setGuestPortalError('NOT_FOUND');
  }, []);

  return (
    <Container
      fluid
      className="login d-flex flex-column justify-content-center"
      id="guest-portal"
    >
      <Container className="content" fluid>
        <Row className="justify-content-md-center loginHeader">
          <ShieldLock color="white" size={70} />
          <Col className="justify-content-md-center mt-16px" xs="auto" sm="auto">
            <h3 className="login-heading">
              {guestPortalErrorsMapper[guestPortalError].title}
            </h3>
            <h5 className="error-subtitle">
              {guestPortalErrorsMapper[guestPortalError].subtitle}
            </h5>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
