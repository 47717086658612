import { AxiosError } from 'axios';

import { ErrorEnvelop } from '../hooks';

export const getApiErrorMessage = (error: AxiosError<ErrorEnvelop>) => {
  const { response, message: httpMessage } = error;

  let message = httpMessage;

  if (response) {
    message = response.data.error.message;
  }

  return message;
};

export const generateUUID = (): string => {
  return crypto.randomUUID();
};
