import { createContext } from 'react';
import { Users } from '../../interfaces';

export interface PropertiesContextProps {
  userPropertiesData?: Users;

  userPropertiesLoading: boolean;
}

export const PropertiesContext = createContext<PropertiesContextProps | undefined>(
  undefined,
);
