import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import { FileText } from 'react-bootstrap-icons';
import './styles/styles.css';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Carousel from 'react-bootstrap/Carousel';
import { PackagesResponse } from '../../../modules/interfaces';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  packageItem: PackagesResponse;
}
interface paramsOptions {
  value: string;
  price: number;
  quantity: number;
  type: string;
}

export const CustomizeStay: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  packageItem,
}) => {
  const [paramsSelectedOptions, setParamsSelectedOptions] = useState<paramsOptions[]>([]);
  const [maxQuantity, setMaxQuantity] = useState<number>(1);
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    trigger,
    reset,
  } = useFormContext();

  const { onBlur, name, ref } = register('size', {
    required: 'Choose one size or range',
  });

  const onClickSubmit = async () => {
    let validated = false;
    if (paramsSelectedOptions.length > 0) {
      setValue('amount', getValues('price') * getValues('quantity'));
      validated = await trigger(['price', 'quantity', 'size']);
    } else {
      setValue('price', packageItem.price || '');
      setValue('amount', packageItem.price || '');
      validated = await trigger(['price', 'quantity']);
    }
    setValue('status', 'PENDING');
    setValue('packageId', packageItem?.uuid || '');

    if (validated) {
      handleClose();
    }
  };

  useEffect(() => {
    if (!packageItem.params) return;

    const selectedOptions: paramsOptions[] = packageItem.params.reduce(
      (accumulator: paramsOptions[], item) => {
        if (item.type === 'RANGE' || item.type === 'SIZE') {
          accumulator.push({
            value: item.values,
            price: item.price || 0,
            quantity: item.quantity || 1,
            type: item.type,
          });
        }
        return accumulator;
      },
      [],
    );

    setParamsSelectedOptions(selectedOptions);
  }, [packageItem]);

  const changeValue = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = paramsSelectedOptions.find(item => item?.value === e.target.value);
    setMaxQuantity(value?.quantity || 1);

    setValue('price', value?.price);
    await trigger(['price']);
  };

  const handleCancel = () => {
    reset({
      price: 0,
      quantity: 1,
      reservationId: '',
      packageId: '',
      amount: 0,
      size: '',
    });
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} /> {'  '}
          Something to Celebrate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Row className="mt-16px">
          {packageItem?.media && packageItem?.media?.length > 0 ? (
            <Carousel>
              {packageItem.media?.map(item => (
                <Carousel.Item key={item.uri}>
                  <Image src={item?.uri} className="corousselImages" />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Image src="/no_image.jpg" className="corousselImages" />
          )}
        </Row>
        {paramsSelectedOptions.length > 0 && (
          <>
            <Row className="mt-16px">
              <Col xs="9">
                <Form.Label className="editor-title-label">
                  Select {paramsSelectedOptions[0].type.toLocaleLowerCase()}
                </Form.Label>

                <Form.Select
                  className="editor-control guest-inputs"
                  placeholder="Select days"
                  onChange={e => changeValue(e)}
                  onBlur={onBlur}
                  name={name}
                  ref={ref}
                >
                  <option value="">
                    Please select a {paramsSelectedOptions[0].type.toLocaleLowerCase()}
                  </option>
                  {paramsSelectedOptions.map(item => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col xs="3">
                <Form.Label className="editor-title-label">Quantity</Form.Label>
                <Form.Control
                  className="editor-control guest-inputs"
                  placeholder="Enter your First name..."
                  {...register('quantity', {
                    required: 'This is required.',
                    min: {
                      value: 1,
                      message: 'Please add at least 1',
                    },
                    max: {
                      value: maxQuantity,
                      message: `the max quantity is ${maxQuantity}`,
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="quantity"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>

            <ErrorMessage
              errors={errors}
              name="size"
              render={({ message }) => <span className="error-text">{message}</span>}
            />
          </>
        )}
        <Row>
          <h3 className="editor-title mt-16px ">
            {packageItem.price
              ? packageItem.price * getValues('quantity')
              : (getValues('price') || 0) * getValues('quantity')}{' '}
            USD
          </h3>

          <Form.Text>
            {packageItem.description ||
              'Did someone say champagne? Let&apos;s start your arrival to The Coach Houseon a good note - champagne on ice with two glasses on ice'}
          </Form.Text>
        </Row>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button className="cancel-button no-border-radius" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          style={{ marginLeft: '10px' }}
          onClick={onClickSubmit}
        >
          Continue
        </Button>
      </Container>
    </Modal>
  );
};
