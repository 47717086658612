import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import './styles/styles.css';
import { Clipboard } from 'react-bootstrap-icons';
import CheckIcon from '../../assets/check.svg';
import CustomFieldsIcon from '../../assets/custom-fields.svg';
import DownloadIcon from '../../assets/download.svg';
import DownBlackIcon from '../../assets/down-black.svg';
import FeeIcon from '../../assets/fee.svg';
import ForwordIcon from '../../assets/forword.svg';
import GuestPortal from '../../assets/guest-portal.svg';
import ReportIcon from '../../assets/report.svg';
import RoomIcon from '../../assets/room.svg';
import UnCheckIcon from '../../assets/uncheck.svg';
import UpSellIcon from '../../assets/upsells.svg';
import {
  GuestPackageResponse,
  MewsReservation,
  Reservation,
} from '../../modules/interfaces';
import { ReservationPackageContext } from '../../modules/context/guestPackageContext';
import { useAuth, useNotifications, useRequest } from '../../modules/hooks';
import { endpoints } from '../../modules/mappers/urls';
import { formatDateTime } from '../../modules/utils/dateFormat';

interface BookingDetailsProps {
  selectedReservation: MewsReservation;
  guestPackages: GuestPackageResponse[];
}

const BookingDetails: FunctionComponent<BookingDetailsProps> = ({
  selectedReservation,
  guestPackages,
}: BookingDetailsProps) => {
  const [isFeeVisible, setIsFeeVisible] = useState<boolean>(true);
  const [isCustomField, setIsCustomFiels] = useState<boolean>(true);
  const [packages, setPackages] = useState<GuestPackageResponse[]>([]);
  const [guestPackageId, setGuestPackageId] = useState('');
  const [guestReservation, setGuestReservation] = useState<Reservation | undefined>();
  const [status, setStatus] = useState('');
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;

  const reservationPackageList = useContext(ReservationPackageContext);
  const { getReservationPackage } = reservationPackageList!;

  const formatDate = (dateString: string) => {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_FULL);
  };

  const renderVerificationItem = (completed: boolean, title: string) => {
    return (
      <div className="verification-item">
        {completed ? (
          <img alt="check" src={CheckIcon} />
        ) : (
          <img alt="uncheck" src={UnCheckIcon} />
        )}
        <div className="verification-item-text">{title}</div>
      </div>
    );
  };

  const URL = `${endpoints.RESERVATION_PACKAGES}/${guestPackageId}`;
  const [{ data: guestPackageData }, updateReservationPackageState] =
    useRequest<GuestPackageResponse>(
      URL,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const guestReservationURL = `${endpoints.MEWS_RESERVATIONS}/${selectedReservation.Id}/details`;
  const [{ data: guestReservationData }] = useRequest<Reservation>(
    guestReservationURL,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  useEffect(() => {
    if (!guestReservationData) return;

    setGuestReservation(guestReservationData?.result);
  }, [guestReservationData]);

  const renderCustomField = (value: string, title: string) => {
    return (
      <div className="info-container">
        <div className="fee-text-title">{title}</div>
        <div className="row-view">
          <div className="info-text">{value}</div>
        </div>
      </div>
    );
  };

  const onFeeExpandClick = useCallback(() => {
    setIsFeeVisible(!isFeeVisible);
  }, [isFeeVisible]);

  const onCustomFieldExpandClick = useCallback(() => {
    setIsCustomFiels(!isCustomField);
  }, [isCustomField]);

  useEffect(() => {
    if (!guestPackages) return;

    setPackages(guestPackages);
  }, [guestPackages]);

  const onConfirm = (index: number, action: string) => {
    setPackages(prevPackages => {
      const newPackage = prevPackages.map((packageItem, j) => {
        const newPackageItem = { ...packageItem };
        if (index === j) {
          newPackageItem.status = action;
        }
        return newPackageItem;
      });
      return newPackage;
    });
  };

  useEffect(() => {
    if (!status) return;

    updateReservationPackageState({
      data: { status },
      headers: {
        authorization: credentialsInfo?.token,
      },
    });

    setStatus('');
  }, [status]);

  useEffect(() => {
    if (!guestPackageData) return;

    if (guestPackageData.result.status === 'APPROVED') {
      getReservationPackage();
    }
  }, [guestPackageData]);

  const onSubmit = (reservationStatus: string, reservationId: string, index: number) => {
    setGuestPackageId(reservationId);
    setStatus(reservationStatus);
    onConfirm(index, reservationStatus);
  };

  const copyToClipboardHandler = (reservationUrl?: string) => {
    if (!reservationUrl) {
      setSimpleToasts({
        type: 'warning',
        message: 'There is no URL for this reservation',
        show: true,
      });
      return;
    }

    navigator.clipboard.writeText(reservationUrl);
    setSimpleToasts({
      type: 'success',
      message: 'Reservation URL copied successfully',
      show: true,
    });
  };

  const renderUpsellView = (index: number, item: GuestPackageResponse) => {
    return (
      <div className="upsells-container">
        <div className="checkin-container">
          <div className="checkin-detail-container">
            <div className="room-details">
              {formatDateTime(item?.reservations?.startDate?.toString() || '')}
            </div>

            <div className="room-name"> {item.packages.name}</div>
          </div>
          <div className="status-detail-container">
            <div>
              <div className="room-details">Status</div>
              <div
                className="paid-text"
                style={{
                  color: item.status === 'APPROVED' ? '#21C413' : '#6B7280',
                }}
              >
                {item.status === 'APPROVED' ? 'PAID' : 'UNPAID'}
              </div>
            </div>
          </div>
        </div>
        <div className="devider" />

        {item.status === 'PENDING' ? (
          <div className="row-view">
            <div className="confirm-btn">
              <div
                className="revoke-text"
                onClick={() => onSubmit('APPROVED', item.uuid, index)}
              >
                Confirm
              </div>
            </div>
            <div className="revoke-btn">
              <div
                className="revoke-text"
                onClick={() => onSubmit('DENIED', item.uuid, index)}
              >
                Deny
              </div>
            </div>
          </div>
        ) : (
          <div
            className="upsell-status-text"
            style={{
              color: item.status === 'APPROVED' ? '#0CA925' : '#DB453C',
            }}
          >
            {item.status === 'APPROVED'
              ? 'This upsell is confirmed'
              : 'This upsell is denied'}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="reservation-detail-container">
      <div className="room-detail-container">
        <img className="room-image" alt="room" src={RoomIcon} />
        <div className="room-name-container">
          <div className="room-name">
            {selectedReservation.Resource
              ? selectedReservation.Resource.ResourceName
              : 'The Classic Queen'}
          </div>
          <div className="room-details">Second floor</div>
        </div>
      </div>
      <div className="checkin-container">
        <div className="checkin-detail-container">
          <div className="room-details">Check-in</div>
          <div className="room-name">
            {formatDate(selectedReservation.ScheduledStartUtc)}
          </div>
        </div>
        <div className="checkin-detail-container">
          <div className="room-details">Room number</div>
          <div className="room-name">
            {selectedReservation.Resource ? selectedReservation.Resource.Name : 3}
          </div>
        </div>
      </div>
      <div className="checkin-container">
        <div className="checkin-detail-container">
          <div className="room-details">Check-out</div>
          <div className="room-name">{formatDate(selectedReservation.EndUtc)}</div>
        </div>
        <div className="checkin-detail-container">
          <div className="room-details">Guests</div>
          <div className="room-name">
            {selectedReservation.PersonCounts.reduce(
              (accumulator, currentValue) => accumulator + currentValue.Count,
              0,
            )}
          </div>
        </div>
      </div>
      <div className="devider" />
      <div className="verification-step-title">Verification steps</div>
      <div className="verification-container">
        <div className="checkin-detail-container">
          {renderVerificationItem(!!guestReservation?.guestPhoneNumber, 'User details')}
          {renderVerificationItem(
            !!guestReservation?.guestReservation?.guest?.frontIdCard,
            'ID Upload',
          )}
          {renderVerificationItem(
            !!guestReservation?.guestReservation?.signature,
            'User Agreement',
          )}
        </div>
        <div className="checkin-detail-container">
          {renderVerificationItem(
            guestReservation?.safetyDepositPaymentStatus === 'PAID',
            'Security Deposit',
          )}
          {renderVerificationItem(false, 'Check In')}
        </div>
      </div>
      <div className="devider" />
      <div className="checkin-container">
        <div className="row-view">
          <img alt="report" src={ReportIcon} />
          <div className="verification-report">Verification Report</div>
        </div>
        <div className="download-btn">
          <img alt="report" src={DownloadIcon} />
          <div className="download-text">Download</div>
        </div>
      </div>
      <div className="devider" />
      <div className="checkin-container">
        <div className="row-view">
          <img alt="report" src={GuestPortal} />
          <div className="verification-report">Guest Portal Access</div>
        </div>
        <div className="checkin-container">
          <div
            className="copy-reservation-url-btn"
            onClick={() => copyToClipboardHandler(guestReservation?.reservationUrl)}
          >
            <div className="revoke-text">
              <Clipboard />
            </div>
          </div>
          <div className="revoke-btn">
            <div className="revoke-text">Revoke</div>
          </div>
        </div>
      </div>
      {packages.length > 0 && (
        <>
          <div className="devider" />

          <div className="row-view">
            <img alt="upsell" src={UpSellIcon} />
            <div className="verification-report">Upsells</div>
          </div>
        </>
      )}
      {packages.length > 0 &&
        packages.map((item, index) => {
          return renderUpsellView(index, item);
        })}
      {selectedReservation?.Prices?.taxes && (
        <>
          <div className="devider" />
          <div className="fee-container">
            <div className="row-view">
              <img alt="fee" src={FeeIcon} />
              <div className="verification-report">TAXES</div>
            </div>
            <div className="row-view">
              <div onClick={onFeeExpandClick} className="fee-expand-btn">
                <img alt="down" src={isFeeVisible ? DownBlackIcon : ForwordIcon} />
              </div>
            </div>
          </div>
        </>
      )}

      {isFeeVisible &&
        selectedReservation?.Prices?.taxes &&
        selectedReservation?.Prices?.taxes.map(item => (
          <div className="price-info-container">
            <div className="price-info-title">{item.name}</div>
            <div className="price-info-value">{`$${item.value.toFixed(2)}`}</div>
          </div>
        ))}
      <div className="devider" />
      <div className="fee-container">
        <div className="row-view">
          <img alt="fee" src={CustomFieldsIcon} />
          <div className="verification-report">Custom Fields</div>
        </div>
        <div className="row-view">
          <div className="fee-text" style={{ color: '#14BF59' }}>
            4 Fields
          </div>
          <div onClick={onCustomFieldExpandClick} className="fee-expand-btn">
            <img alt="down" src={isCustomField ? DownBlackIcon : ForwordIcon} />
          </div>
        </div>
      </div>
      {isCustomField && (
        <>
          {renderCustomField(
            `${selectedReservation.Customer?.FirstName} ${selectedReservation.Customer?.LastName}`,
            'Guest name (s)',
          )}
          {renderCustomField(
            guestReservation?.guestReservation?.guest?.licensePlate || '-',
            'License Plate',
          )}
          {renderCustomField('-', 'Estimate time of arrival')}
          {renderCustomField('-', 'How did you hear about us?')}
        </>
      )}
    </div>
  );
};

export default BookingDetails;
