import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { PackageContext } from '../../../modules/context/packageContext';
import { PackagesResponse } from '../../../modules/interfaces';
import { useGetAllPackages } from '../../Dashboards/Packages/packageEnpoints';
import { useLoading } from '../../../modules/hooks';

export const PackageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [packages, setPackages] = useState<PackagesResponse[]>([]);
  const [{ data, loading }, refetch] = useGetAllPackages();
  const { setLoading } = useLoading()!;

  useEffect(() => {
    if (!data) return;

    setPackages(data.result);
  }, [data]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const packageContext = useMemo(() => {
    return { packages, refetch, loading };
  }, [packages, refetch, loading]);

  return (
    <PackageContext.Provider value={packageContext}>{children}</PackageContext.Provider>
  );
};
