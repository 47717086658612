import { useAuth, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { GuestPackageResponse, PackagesResponse } from '../../../modules/interfaces';

export const useDeletePackage = (packageId: string) => {
  const { credentialsInfo } = useAuth()!;
  const deleteUrl = `${endpoints.PACKAGES}/${packageId}`;

  return useRequest<string>(
    deleteUrl,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );
};

export const useUpdatePackage = (packageId: string) => {
  const { credentialsInfo } = useAuth()!;

  const editUrl = `${endpoints.PACKAGES}/${packageId}`;

  return useRequest<string>(
    editUrl,
    'put',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );
};

export const useCreatePackage = () => {
  const { credentialsInfo } = useAuth()!;

  return useRequest<PackagesResponse>(
    endpoints.PACKAGES,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );
};

export const useGetAllPackages = () => {
  const { credentialsInfo } = useAuth()!;

  return useRequest<PackagesResponse[]>(endpoints.PACKAGES, 'get', {
    authToken: credentialsInfo?.token,
  });
};

export const useGetReservationPackage = () => {
  const { credentialsInfo } = useAuth()!;

  const URL = `${endpoints.RESERVATION_PACKAGES}`;
  return useRequest<GuestPackageResponse[]>(URL, 'get', {
    authToken: credentialsInfo?.token,
  });
};

export const useUpdateReservationPackage = (guestPackageId?: string) => {
  const { credentialsInfo } = useAuth()!;

  const URL = `${endpoints.RESERVATION_PACKAGES}/${guestPackageId}`;
  return useRequest<GuestPackageResponse[]>(
    URL,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );
};
