import { FC } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';

import { BedType } from '../../../modules/interfaces';

export interface BedItemProps {
  data: BedType;
  bedTypes: string[];
  onClickRemoveBed: (key: number) => void;
}

export const BedItem: FC<BedItemProps> = ({ data, bedTypes, onClickRemoveBed }) => {
  return (
    <Row className="mt-24px check-box-group">
      <Col md={6}>
        <Form.Group>
          <Form.Label className="editor-title-label">Bed Type</Form.Label>
          <Form.Select className="editor-control" value={data.value} disabled>
            <option value="" key="">
              Select bed type
            </option>
            {bedTypes?.map(typeItem => {
              return (
                <option value={typeItem} key={typeItem}>
                  {typeItem}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6} className="align-items-center col-md-6 d-flex justify-content-end">
        <Button
          variant="light"
          className="w-auto table-add-button bed-remove-button"
          onClick={() => {
            onClickRemoveBed(data.key);
          }}
        >
          <Image alt="" src="/Icon-minus.svg" width={16} height={16} />
          Remove Bed
        </Button>
      </Col>
    </Row>
  );
};
