import { FC, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import './styles/styles.css';
import { RefetchFunction } from 'axios-hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { useAuth, useRequest } from '../../../modules/hooks';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  roomId: string;
  refetchRooms: RefetchFunction<unknown, unknown>;
}

export const DeleteRoomModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  roomId,
  refetchRooms,
}) => {
  const { handleSubmit, reset, register, watch } = useForm({
    defaultValues: { option: 'no' },
  });

  const updateRoomUrl = `${endpoints.ROOMS}/${roomId}`;
  const { credentialsInfo } = useAuth()!;
  const [{ loading: deleteResult }, deleteRoom] = useRequest<string>(
    updateRoomUrl,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const onSubmit = () => {
    deleteRoom();
  };

  useEffect(() => {
    if (!deleteResult) return;

    reset();
    handleClose();
    refetchRooms();
  }, [deleteResult, reset, handleClose]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title className="editor-title">Deleting Room</Modal.Title>
        <span className="editor-sub-title">
          Are you sure you want to delete this room?
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="editor-title-label">Please confirm</Form.Label>
                <Form.Select className="editor-control" {...register('option')}>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="delete-button"
          disabled={watch('option') === 'no'}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
