import React, { ChangeEvent, FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

import DropDown from '../../../assets/down-black.svg';

type DamageReportmodalProp = {
  show: boolean;
  isEdit: boolean;
  onHide: () => void;
};

type CustomToggleProp = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CustomToggle = React.forwardRef(
  ({ children, onClick }: CustomToggleProp, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div
      ref={ref}
      className="damage-report-drop-down-style"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={DropDown} alt="drop-down" />
    </div>
  ),
);

const DamageReportmodal: FC<DamageReportmodalProp> = ({ show, onHide, isEdit }) => {
  const damageList = ['Cause Damage', 'Chargeback Dispute', 'No-Show'];
  const statusList = ['Pending', 'Active', 'Resolved'];
  const [causeDamange, setCauseDamage] = useState('Cause Damage');
  const [status, setStatus] = useState('Pending');
  const [description, setDescription] = useState('');

  const onSelectDamage = (eventKey: string | null) => {
    if (eventKey) {
      setCauseDamage(eventKey);
    }
  };

  const onSelectStatus = (eventKey: string | null) => {
    if (eventKey) {
      setStatus(eventKey);
    }
  };

  const getColorFromStatus = (statusColor: string) => {
    if (statusColor === 'Pending') {
      return '#F19204';
    }
    if (statusColor === 'Active') {
      return '#0CA925';
    }
    return '#2651C1';
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">
          {isEdit ? 'Edit damage report' : 'Add new damage report'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit-guest-input-title">Report name</div>
        <Dropdown onSelect={onSelectDamage}>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            {causeDamange}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: '100%' }}>
            {damageList.map(item => {
              return (
                <Dropdown.Item eventKey={item} active={item === causeDamange}>
                  {item}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="edit-guest-input-title">Description</div>
        <textarea
          className="description-text-input-style "
          aria-describedby="emailHelp"
          placeholder="Guest accidentally damaged a lamp during their stay"
          value={description}
          onChange={onDescriptionChange}
        />
        <div className="edit-guest-input-title">Report status</div>
        <Dropdown onSelect={onSelectStatus}>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <div className="row-status-drop-down">
              <div
                className="status-indicator"
                style={{ backgroundColor: getColorFromStatus(status) }}
              />
              {status}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: '100%' }}>
            {statusList.map(item => {
              return (
                <Dropdown.Item eventKey={item}>
                  <div
                    className="status-indicator"
                    style={{ backgroundColor: getColorFromStatus(item) }}
                  />
                  {item}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button className="edit-guest-modal-button-save" onClick={onHide}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DamageReportmodal;
