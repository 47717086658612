/* eslint-disable no-nested-ternary */
import React, { FC, ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

interface ReservationCardProps {
  title: string;
  icon: ReactNode;
}

export const SocialNetworkCard: FC<ReservationCardProps> = ({ title, icon }) => {
  return (
    <Card className="icon-card">
      <Card.Body>
        <Row>
          <Col xs="12" className="card-title">
            {icon}
            <span style={{ marginLeft: '13px' }}> {title} </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
