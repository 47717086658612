import { AxiosError } from 'axios';
import { RefetchFunction } from 'axios-hooks';
import { createContext } from 'react';

export interface GuestAuthContextProps {
  token: string;
  generateAuthToken: RefetchFunction<unknown, unknown>;
  loading: boolean;
  error: AxiosError<unknown, unknown> | null;
}

export const GuestAuthContext = createContext<GuestAuthContextProps | undefined>(
  undefined,
);
