import { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import LockIcon from '../../../assets/lock-icon.svg';
import UnLockIcon from '../../../assets/un-lock-icon.svg';

import { RoomLocks } from '../../../modules/interfaces';
import { useAuth, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';

interface UserDetailsModalProps {
  rowData?: RoomLocks;
  lockId: string;
}

interface FormLock {
  otp: string;
}
interface actionRemote {
  lockedState: string;
}

export const LockUnlockModal: FC<UserDetailsModalProps> = ({ rowData, lockId }) => {
  const { register, handleSubmit, reset } = useForm<FormLock>({
    defaultValues: { otp: '' },
  });

  const { credentialsInfo } = useAuth()!;
  const [lockStatus, setLockStatus] = useState('locked');
  const remoteActionUrl = `${endpoints.LOCKS}/${rowData?.lockId}/remote-action`;
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [{ data: remoteActionResult, loading }, updateLockStatus] =
    useRequest<actionRemote>(
      remoteActionUrl,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const onSubmit = (data: FormLock) => {
    updateLockStatus({
      data: { status: lockStatus, otp: data.otp },
      headers: {
        authorization: credentialsInfo?.token,
      },
    });

    reset();
    setOpenUpdateModal(false);
  };

  const handleStatusChange = (status: string) => {
    setLockStatus(status);
    setOpenUpdateModal(!openUpdateModal);
  };

  useEffect(() => {
    if (remoteActionResult?.result?.lockedState) return;

    setLockStatus(remoteActionResult?.result.lockedState || 'locked');
  }, [remoteActionResult]);

  return (
    <>
      {remoteActionResult && remoteActionResult.result.lockedState === 'unlocked' ? (
        <Button
          onClick={() => handleStatusChange('locked')}
          className="lock-unlock-button"
          style={{ backgroundColor: '#DB453C' }}
          disabled={loading}
        >
          <img src={LockIcon} alt="lock-icon" />

          <div style={{ marginLeft: '5px' }}>{loading ? 'Loading…' : 'Lock'}</div>
        </Button>
      ) : (
        <Button
          onClick={() => handleStatusChange('unlocked')}
          className="lock-unlock-button"
          style={{ backgroundColor: '#14BF59' }}
          disabled={loading}
        >
          <img src={UnLockIcon} alt="lock-icon" />
          <div style={{ marginLeft: '5px' }}>{loading ? 'Loading…' : 'Unlock'}</div>
        </Button>
      )}
      <Modal
        show={openUpdateModal}
        onHide={() => setOpenUpdateModal(false)}
        dialogClassName="post-editor-modal modal-200w"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title className="editor-title">{rowData?.lockName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Label className="editor-title-label">Enter OTP</Form.Label>
                <Form.Control
                  className="editor-control "
                  placeholder="Enter your OTP..."
                  {...register('otp', { required: true })}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '24px',
          }}
        >
          <Button className="cancel-button" onClick={() => setOpenUpdateModal(false)}>
            Cancel
          </Button>
          <Button className="save-button" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
