import { FC } from 'react';
import { Image } from 'react-bootstrap';

import './styles/styles.css';

export interface PreviewItemProps {
  url?: string;
  fileId: string;
  uuid?: string;
  onDelete: (fileId: string, uuid: string | undefined) => void;
}

export const PreviewItem: FC<PreviewItemProps> = ({ url, uuid, fileId, onDelete }) => {
  return (
    <div className="attachment-item">
      <Image className="attachment-img" alt="" src={url} />
      <span className="attachment-delete" onClick={() => onDelete(fileId, uuid)}>
        <Image className="" alt="" src="/minus.svg" />
      </span>
    </div>
  );
};
