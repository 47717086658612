import { FC, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';

import { EyeFill } from 'react-bootstrap-icons';
import { useNotifications } from '../../../modules/hooks';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  accessCode: string;
}

export const AccessCodeModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  accessCode,
}) => {
  const { setSimpleToasts } = useNotifications()!;
  const [showPassword, setShowPassword] = useState(false);

  const copyToClipboardHandler = () => {
    navigator.clipboard.writeText(accessCode);
    setSimpleToasts({
      type: 'success',
      message: 'Access code copied successfully',
      show: true,
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title className="editor-title">Access Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xs={12}>
              <Form.Label className="editor-title-label"> Your Access Code</Form.Label>
            </Col>
            <Col xs={10}>
              <Form.Group>
                <Form.Control
                  readOnly
                  type={showPassword ? 'text ' : 'password'}
                  value={accessCode}
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Button>
                <EyeFill onClick={() => setShowPassword(!showPassword)} size={18} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '24px',
        }}
      >
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="save-button" onClick={() => copyToClipboardHandler()}>
          Copy code
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
