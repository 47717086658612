import * as React from 'react';

type Props = {
  color: string;
};

function DropDownUpStatus({ color }: Props) {
  return (
    <svg width={8} height={5} viewBox="0 0 8 5" fill="none">
      <path
        d="M7 4.5a.5.5 0 00.391-.812l-.038-.042-3-3a.5.5 0 00-.66-.041l-.047.041-3 3-.041.047-.027.039-.027.048-.009.018-.013.034-.016.054-.005.026-.005.03-.002.028v.06l.002.029.005.03.005.025.016.054.013.034.035.066.033.045.036.04.047.042.039.027.048.027.018.008.033.014.054.016.027.005.03.005.028.002L7 4.5z"
        fill={color}
      />
    </svg>
  );
}

export default DropDownUpStatus;
