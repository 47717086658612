import { FC } from 'react';

export interface IconProps {
  height: string;
  width: string;
}

export const LogoIcon: FC<IconProps> = ({ height = '46', width = '50' }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4447 5.55533C22.5128 2.4872 27.4872 2.4872 30.5553 5.55533C33.6234 8.62345 33.6234 13.5979 30.5553 16.666L26.5383 20.683C25.6887 21.5326 24.3113 21.5326 23.4617 20.683L19.4447 16.666C16.3766 13.5979 16.3766 8.62345 19.4447 5.55533Z"
        fill="#15CF5F"
      />
      <path
        d="M5.556 19.442C8.62412 16.3739 13.5985 16.3739 16.6667 19.442L20.6836 23.459C21.5332 24.3086 21.5332 25.6861 20.6836 26.5357L16.6667 30.5527C13.5985 33.6208 8.62412 33.6208 5.556 30.5527C2.48788 27.4846 2.48788 22.5102 5.556 19.442Z"
        fill="#15CF5F"
      />
      <path
        d="M33.3314 19.442C36.3995 16.3739 41.3739 16.3739 44.442 19.442C47.5102 22.5102 47.5102 27.4846 44.442 30.5527C41.3739 33.6208 36.3995 33.6208 33.3314 30.5527L29.3144 26.5357C28.4648 25.6861 28.4648 24.3086 29.3144 23.459L33.3314 19.442Z"
        fill="#15CF5F"
      />
      <path
        d="M23.4617 29.3167C24.3113 28.4671 25.6887 28.4671 26.5383 29.3167L30.5553 33.3336C33.6234 36.4018 33.6234 41.3762 30.5553 44.4443C27.4872 47.5124 22.5128 47.5124 19.4447 44.4443C16.3766 41.3762 16.3766 36.4018 19.4447 33.3336L23.4617 29.3167Z"
        fill="#15CF5F"
      />
    </svg>
  );
};
