import { FC, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { DataTable } from '../DataTable';

type RevenueReportsTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
};

export const RevenueReportTable: FC<RevenueReportsTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
}: RevenueReportsTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [columns] = useState<ColDef<any>[]>([
    { field: 'months', headerName: 'MONTHS', flex: 1 },
    {
      field: 'room_rev',
      headerName: 'ROOM REVENUE',
      flex: 1,
      sortable: true,
    },
    {
      field: 'Package_rev',
      headerName: 'PACKAGE REVENUE',
      flex: 1,
      sortable: true,
    },

    {
      field: 'total_rev',
      headerName: 'TOTAL REVENUE',
      sortable: true,
      flex: 1,
    },
    {
      field: 'taxes',
      headerName: 'TAXES',
      sortable: true,
      flex: 1,
    },
    {
      field: 'fees',
      headerName: 'FEES',
      sortable: true,
      flex: 1,
    },
    {
      field: 'total',
      headerName: 'TOTAL',
      sortable: true,
      flex: 1,
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
    />
  );
};
