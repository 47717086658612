import { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';

import { useForm, Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { PackageItem } from '../../../modules/interfaces';

import { useAuth } from '../../../modules/hooks';
import { useUpdateReservationPackage } from '../../Dashboards/Packages/packageEnpoints';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  rowData: PackageItem;
  onUpdate: (status: string, paymentStatus?: string) => void;
}

export const UpdateRowDataModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  rowData,
  onUpdate,
}) => {
  const { credentialsInfo } = useAuth()!;

  const [packageStatus, setPackageStatus] = useState('');
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: { arrival: '', departure: '', amount: '', status: 'PENDING' },
  });

  const [{ data: reservationPackageData }, updateReservationPackageState] =
    useUpdateReservationPackage(rowData?.uuid);

  const onSubmit = (data: { status: string }) => {
    const { status } = data;

    setPackageStatus(status);

    updateReservationPackageState({
      data: { status },
      headers: {
        authorization: credentialsInfo?.token,
      },
    });
  };

  useEffect(() => {
    if (!rowData) return;

    if (Object.keys(rowData).length !== 0)
      reset({
        arrival: rowData.arrival,
        departure: rowData.arrival,
        amount: rowData?.amount?.toString(),
        status: rowData.status,
      });
  }, [reset, rowData]);

  useEffect(() => {
    if (!reservationPackageData || !packageStatus) return;

    if (packageStatus === 'APPROVED') {
      onUpdate(packageStatus, 'PAID');
    } else {
      onUpdate(packageStatus);
    }

    reset();
    handleClose();
  }, [reservationPackageData]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title className="editor-title">{rowData.packageName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Label className="editor-title-label">Arrival</Form.Label>
              <Form.Control
                className="editor-control "
                {...register('arrival')}
                readOnly
              />
            </Col>
            <Col>
              <Form.Label className="editor-title-label">Departure</Form.Label>
              <Form.Control
                className="editor-control "
                {...register('departure')}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Price</Form.Label>

              <Controller
                render={({ field: { value, onChange } }) => (
                  <NumericFormat
                    value={value}
                    prefix="$ "
                    thousandSeparator
                    className="form-control editor-control"
                    placeholder="Enter your Last name..."
                    onValueChange={({ floatValue }) => {
                      onChange(floatValue);
                    }}
                    readOnly
                  />
                )}
                name="amount"
                control={control}
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Group>
                <Form.Label className="editor-title-label">Permission</Form.Label>
                <Form.Select className="editor-control" {...register('status')}>
                  <option value="APPROVED">Approved</option>
                  <option value="PENDING">Pending</option>
                  <option value="DENIED">Denied</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="save-button" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
