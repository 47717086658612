import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { FileText } from 'react-bootstrap-icons';
import SignatureCanvas from 'react-signature-canvas';
import './styles/styles.css';
import { useFormContext } from 'react-hook-form';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AgreementModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  isEnd,
}) => {
  const signCanvas = React.useRef() as React.MutableRefObject<SignatureCanvas>;
  const { setValue, getValues } = useFormContext();
  const [errorMessage, setErrorMessage] = useState('');

  const handleClear = () => {
    signCanvas.current.clear();
  };

  const onClickSubmit = () => {
    setValue('signature', signCanvas.current.toDataURL());

    if (signCanvas.current.isEmpty()) {
      setErrorMessage('Please enter your signature');
    } else {
      setErrorMessage('');
    }

    if (isEnd && !signCanvas.current.isEmpty()) {
      isEnd(true);
      handleClose();
    }
  };

  useEffect(() => {
    if (!getValues('signature')) return;
    getValues('signature');

    signCanvas.current.fromDataURL(getValues('signature'));
  }, [getValues, show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} /> {'  '}
          Agreement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Form.Text>
              Deposit/Cancellation: For stays of any length, a deposit of 50% of the total
              charges is due at the time of booking. The second half of the deposit will
              be automatically deducted 7 days prior to your scheduled check-in. On the
              day of your arrival a $100 security deposit will be held throughout the
              duration of your stay. Payment can be made via Visa, MasterCard, American
              Express, or Discover. We will send confirmation of your reservation upon
              receipt of your deposit.
              <br />
              <br />
              Cancellation policy is strict and will be enforced.
              <br />
              <br />
              Guests who cancel more than 30 days in advance will receive a refund of
              their initial deposit. Since we charge the remaining balance 7 days prior to
              check-in, guests will not be able to receive a full refund within 7 days of
              arrival. Refunds will not be honored for those who cancel within those time
              frames from their scheduled arrival date. Winter Storm Cancellations: All
              reservations for the months of December through February that need to be
              cancelled due to flight cancellations or a state of emergency will be given
              a 90-day grace period to reschedule their stay to a further date. <br />{' '}
              <br /> <span> any cancellations please contact:</span>
              <br />
              reservations@coachhousesalem.com
            </Form.Text>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col sm={12}>
              <div className="signature-canvas-container">
                <SignatureCanvas
                  canvasProps={{ className: 'signature-canvas' }}
                  ref={signCanvas}
                />
              </div>
            </Col>
            <Col
              sm={7}
              style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '16px' }}
            >
              <span className="error-text">{errorMessage}</span>
            </Col>
            <Col
              sm={5}
              style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}
            >
              <Button className="save-button no-border-radius" onClick={handleClear}>
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button className="cancel-button no-border-radius" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          style={{ marginLeft: '10px' }}
          onClick={onClickSubmit}
        >
          Continue
        </Button>
      </Container>
    </Modal>
  );
};
