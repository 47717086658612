import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Col, Image, Row } from 'react-bootstrap';

import { DateTime } from 'luxon';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { DataTable, TextWithTooltipCellRenderer } from '../DataTable';
import {
  useAuth,
  useRequest,
  useLoading,
  useNotifications,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { endpoints } from '../../../modules/mappers/urls';
import { config } from '../../../modules/config';
import { DotDropDown } from '../../Customize/DropDown';
import { CategoryEditorModal } from '../../Modals/CategoryEditor';
import { ConfirmModal, ModalState } from '../../Modals/Confirm';
import { Categories } from '../../../modules/interfaces';

import './styles/styles.css';

export interface AdminTableProps {
  changeState: number;
}

interface CategoryDotCellRendererProps {
  data?: Categories;
  onClickEdit: (data?: Categories) => void;
  onClickDelete: (data?: Categories) => void;
}

const CategoryDotMenu: FC<CategoryDotCellRendererProps> = ({
  data,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <DotDropDown title="" drop="end">
      <Dropdown.Item
        onClick={() => {
          onClickEdit(data);
        }}
      >
        <PencilSquare className="dropdown-icon" />
        Edit Category
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickDelete(data);
        }}
      >
        <Trash className="dropdown-icon" />
        Delete Category
      </Dropdown.Item>
    </DotDropDown>
  );
};

const getDotMenu = ({ onClickEdit, onClickDelete }: CategoryDotCellRendererProps) => {
  const DotMenuCellRenderer: FC<ICellRendererParams<Categories>> = (
    params: ICellRendererParams<Categories>,
  ) => {
    const { data } = params;
    return (
      <CategoryDotMenu
        data={data}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
      />
    );
  };

  return DotMenuCellRenderer;
};

export const AdminCategoryTable: FC<AdminTableProps> = ({ changeState }) => {
  const gridRef = useRef<AgGridReact>(null);
  const { setSimpleToasts } = useNotifications()!;
  const [tableData, setTableData] = useState<Categories[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateUId, setUpdateUId] = useState<string>();
  const [confirmModal, setConfirmModal] = useState<ModalState>({
    show: false,
    title: '',
    body: '',
    handler: () => {
      // TODO: need to implement
    },
  });
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ data, loading, error }, refetch] = useRequest<Categories[]>(
    endpoints.CATEGORIES,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  const [
    { loading: insertLoading, data: insertResult, error: insertError },
    insertCategory,
  ] = useRequest<string>(
    endpoints.CATEGORIES,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const editUrl = `${endpoints.CATEGORIES}/${updateUId}`;
  const [
    { loading: updateLoading, data: updateResult, error: updateError },
    updateCategory,
  ] = useRequest<string>(
    editUrl,
    'put',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const deleteUrl = `${endpoints.CATEGORIES}/${updateUId}`;
  const [
    { loading: deleteLoading, data: deleteResult, error: deleteError },
    deleteCategory,
  ] = useRequest<string>(
    deleteUrl,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const refreshHandler = async () => {
    await refetch();
  };

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (data) {
      const { result } = data;
      setTableData(result);
    }
  }, [data, error, setSimpleToasts, setLoading]);

  useEffect(() => {
    setLoading(loading || insertLoading || deleteLoading || updateLoading);
  }, [loading, updateLoading, insertLoading, deleteLoading, setLoading]);

  useEffect(() => {
    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (deleteError) {
      const message = getApiErrorMessage(deleteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (insertResult || updateResult || deleteResult) {
      refetch();
    }
  }, [
    insertResult,
    insertError,
    updateResult,
    updateError,
    deleteResult,
    deleteError,
    setSimpleToasts,
    refetch,
  ]);

  const handleAddArticle = () => {
    setCurrentCategory('');
    setShowModal(true);
  };

  const submitAddArticle = (isUpdate: boolean, name?: string) => {
    if (name && name !== '') {
      setShowModal(false);
      if (isUpdate) {
        updateCategory({ data: { name } });
      } else {
        insertCategory({ data: { name } });
      }
    } else {
      const message = 'Please input category name';
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  };

  const closeConfirmModal = () => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
  };

  const handleDeleteCategory = useCallback(() => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
    deleteCategory();
  }, [confirmModal, setConfirmModal, deleteCategory]);

  const onClickEdit = useCallback(
    (category?: Categories) => {
      if (category) {
        setCurrentCategory(category.name);
        setUpdateUId(category.uuid);
        setShowModal(true);
      }
    },
    [setUpdateUId, setCurrentCategory, setShowModal],
  );

  const onClickDelete = useCallback(
    (category?: Categories) => {
      if (category) {
        setUpdateUId(category.uuid);
        setConfirmModal({
          show: true,
          title: 'Delete this category?',
          body: 'This action cannot be undone.',
          handler: () => {
            handleDeleteCategory();
          },
        });
      }
    },
    [setUpdateUId, setConfirmModal, handleDeleteCategory],
  );

  const [columns] = useState<ColDef<Categories>[]>([
    { field: 'name', headerName: 'CATEGORY NAME', width: 200 },
    {
      valueGetter: params => {
        const { data: category } = params;
        const length = category?.articles.length;
        return length;
      },
      headerName: 'ARTICLES IN CATEGORY',
      width: 200,
    },
    {
      field: 'createdAt',
      valueGetter: params => {
        const { data: category } = params;
        let date: string | undefined = category?.createdAt;
        if (category?.createdAt !== undefined) {
          date = DateTime.fromISO(category.createdAt).toFormat('MM-dd-yyyy');
        }

        return date;
      },
      headerName: 'CREATION DATE',
      cellRenderer: TextWithTooltipCellRenderer,
      width: 200,
    },
    {
      headerName: 'ACTION',
      colId: 'action',
      cellClass: 'actionCol justify-content-end',
      width: 200,
      cellRenderer: getDotMenu({ onClickEdit, onClickDelete }),
    },
  ]);

  return (
    <>
      <Row className="admin-article-table">
        <Col sm={12} className="admin-table-section">
          <Row className="d-flex align-items-center justify-content-between table-title-section">
            <Col sm={3}>
              <h5 className="table-title">All Categories</h5>
              <span className="table-sub-title">
                Total Categories: <span className="bold">{tableData.length}</span>
              </span>
            </Col>
            <Col sm={3} className="d-flex align-items-center justify-content-end">
              <Button
                id="dropdown-basic-button"
                className="w-auto table-add-button"
                onClick={handleAddArticle}
              >
                <Image alt="" src="/plus.svg" />
                Add Category
              </Button>
            </Col>
          </Row>
          <DataTable
            refreshHandler={refreshHandler}
            gridRef={gridRef}
            rowData={tableData}
            columnDefs={columns}
            pagination
            changeState={changeState}
            paginationPageSize={config.tables.balances.paginationSize}
            onRowClicked={(e: RowClickedEvent<Categories>) => {
              if (e.api.getFocusedCell()?.column.getColId() === 'action') {
                return;
              }
              const { data: category } = e;
              if (category) {
                // TODO: need to implement
              }
            }}
          />
        </Col>
      </Row>
      <CategoryEditorModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        handleSubmit={submitAddArticle}
        category={currentCategory}
      />
      <ConfirmModal
        show={confirmModal.show}
        title={confirmModal.title}
        body={confirmModal.body}
        handleCancel={closeConfirmModal}
        handleConfirm={handleDeleteCategory}
      />
    </>
  );
};
