import { FC, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { DataTable } from '../DataTable';

type RoomRevenueReportsTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
  tabletype: string;
};

export const RoomRevenueReportTable: FC<RoomRevenueReportsTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  tabletype,
}: RoomRevenueReportsTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const renderTotalText = (item: any) => {
    return <div className="total-text">{item.data.total} 123</div>;
  };

  const [columns] = useState<ColDef<any>[]>([
    { field: 'months', headerName: 'MONTHS', flex: 1 },
    {
      field: 'classic_queen',
      headerName: 'CLASSIC QUEEN',
      flex: 1,
      sortable: true,
    },
    {
      field: 'family_suite',
      headerName: 'FAMILY SUITE',
      flex: 1,
      sortable: true,
    },

    {
      field: 'queen_suite',
      headerName: 'QUEEN SUITE',
      sortable: true,
      flex: 1,
    },
    {
      field: 'king_suite',
      headerName: 'KING SUITE',
      sortable: true,
      flex: 1,
    },
    {
      field: 'luxury',
      headerName: 'LUXURY QS',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'TOTAL',
      cellRenderer: renderTotalText,
      sortable: true,
      flex: 1,
    },
  ]);

  const [roomTypeQuarter] = useState<ColDef<any>[]>([
    { field: 'months', headerName: 'MONTHS', flex: 1 },
    {
      field: 'classic_queen',
      headerName: 'CLASSIC QUEEN',
      flex: 1,
      sortable: true,
    },
    {
      field: 'family_suite',
      headerName: 'FAMILY SUITE',
      flex: 1,
      sortable: true,
    },

    {
      field: 'queen_suite',
      headerName: 'QUEEN SUITE',
      sortable: true,
      flex: 1,
    },
    {
      field: 'king_suite',
      headerName: 'KING SUITE',
      sortable: true,
      flex: 1,
    },
    {
      field: 'luxury',
      headerName: 'LUXURY QS',
      sortable: true,
      flex: 1,
    },
    {
      cellRenderer: renderTotalText,
      headerName: 'TOTAL',
      sortable: true,
      flex: 1,
    },
  ]);
  const [roomNumberMonth] = useState<ColDef<any>[]>([
    { field: 'room', headerName: 'ROOM', width: 90, pinned: 'left' },
    {
      field: 'room_type',
      headerName: 'ROOM TYPE',
      width: 150,
      pinned: 'left',
      sortable: true,
    },
    {
      field: 'january',
      headerName: 'JANUARY',
      width: 150,
      sortable: true,
    },

    {
      field: 'february',
      headerName: 'FEBRUARY',
      sortable: true,
      width: 150,
    },
    {
      field: 'march',
      headerName: 'MARCH',
      sortable: true,
      width: 150,
    },
    {
      field: 'april',
      headerName: 'APRIL',
      sortable: true,
      width: 150,
    },
    {
      field: 'may',
      headerName: 'MAY',
      sortable: true,
      width: 150,
    },
    {
      field: 'june',
      headerName: 'JUNE',
      sortable: true,
      width: 150,
    },
    {
      field: 'july',
      headerName: 'JULY',
      sortable: true,
      width: 150,
    },
    {
      field: 'august',
      headerName: 'AUGUST',
      sortable: true,
      width: 150,
    },
    {
      field: 'september',
      headerName: 'SEPTEMBER',
      sortable: true,
      width: 150,
    },
    {
      field: 'october',
      headerName: 'OCTOBER',
      sortable: true,
      width: 150,
    },
    {
      field: 'november',
      headerName: 'NOVEMBER',
      sortable: true,
      width: 150,
    },
    {
      field: 'december',
      headerName: 'DECEMBER',
      sortable: true,
      width: 150,
    },
    {
      cellRenderer: renderTotalText,
      headerName: 'TOTAL',
      sortable: true,
      width: 150,
    },
  ]);
  const [roomNumberQuarter] = useState<ColDef<any>[]>([
    { field: 'room', headerName: 'ROOM', flex: 0.5 },
    {
      field: 'room_type',
      headerName: 'ROOM TYPE',
      flex: 1,
      sortable: true,
    },
    {
      field: 'quarter_1',
      headerName: 'QUARTER 1',
      flex: 1,
      sortable: true,
    },

    {
      field: 'quarter_2',
      headerName: 'QUARTER 2',
      sortable: true,
      flex: 1,
    },
    {
      field: 'quarter_3',
      headerName: 'QUARTER 3',
      sortable: true,
      flex: 1,
    },
    {
      field: 'quarter_4',
      headerName: 'QUARTER 4',
      sortable: true,
      flex: 1,
    },
    {
      cellRenderer: renderTotalText,
      headerName: 'TOTAL',
      sortable: true,
      flex: 1,
    },
  ]);

  const columnData = () => {
    if (tabletype === 'roomTypeMonth') {
      return columns;
    }
    if (tabletype === 'roomTypeQuarter') {
      return roomTypeQuarter;
    }
    if (tabletype === 'roomNumberMonth') {
      return roomNumberMonth;
    }
    if (tabletype === 'roomNumberQuarter') {
      return roomNumberQuarter;
    }
    return [];
  };

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      key={tabletype}
      changeState={0}
      rowData={tableData}
      columnDefs={columnData()}
    />
  );
};
