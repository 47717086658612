import { ChangeEvent, FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

type AddReseravtionNotesProp = {
  show: boolean;
  onHide: () => void;
  onSave: (note: string) => void;
  note: string;
};

const AddReseravtionNotes: FC<AddReseravtionNotesProp> = ({
  show,
  onHide,
  onSave,
  note,
}) => {
  const [reservationNote, setReservationNote] = useState(note);

  const onReservationNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReservationNote(event.target.value);
  };

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">
          {note ? 'Edit Reservation Note' : 'Add Reservation Note'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-reaservation-modal-title">Reservation note</div>
        <textarea
          className="add-reaservation-input-style"
          aria-describedby="noteHelp"
          placeholder="Type here..."
          onChange={onReservationNoteChange}
          value={reservationNote}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button
          className="edit-guest-modal-button-save"
          onClick={() => onSave(reservationNote)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddReseravtionNotes;
