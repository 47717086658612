/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';

import { GuestsRequest, MewsReservation } from '../../../modules/interfaces';

import { ErrorEnvelop, useGuestAuth } from '../../../modules/hooks';
import {
  useAddPackagesToGuest,
  useGetReservation,
  useGetReservationRating,
  useGuestReservation,
} from '../../GuestPages/endpoints/GuestPageEnpoints';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { GuestPortalErrors } from '../../Modals/GuestPagesModals/constants/GuestPortalConstants';
import { GuestPortalContext } from '../../../modules/context/guestPortalContext';
import { GuestPortalErrorPage } from '../../GuestPages/GuestPortalErrorPage';

export const GuestPortalProvider = ({
  children,
  reservationId,
}: {
  children: React.ReactNode;
  reservationId: string;
}) => {
  const [reservation, setReservation] = useState<MewsReservation>();
  const [preregisteredGuest, setPreregisteredGuest] = useState<GuestsRequest>();
  const [guestName, setGuestName] = useState<string>('');
  const { token, generateAuthToken, loading, error } = useGuestAuth()!;
  const [{ data: reservationResult, loading: loadingReservation }, getMewsReservations] =
    useGetReservation(reservationId || '');

  const [finishedRating, setFinishedRating] = useState(false);

  const [{ data, loading: loadingGuest }] = useGetReservationRating(reservationId || '');

  const [
    { data: addPackageResult, loading: loadingAddPackage, error: addPackageError },
    addPackageToGuest,
  ] = useAddPackagesToGuest();

  useEffect(() => {
    if (!data) return;

    setFinishedRating(true);
  }, [data, loadingGuest]);

  const [
    { data: reservationGuest, loading: loadingPreregisterd },
    getGuestPreregistered,
  ] = useGuestReservation(reservationId || '');

  useEffect(() => {
    if (!error && !addPackageError) return;

    if (
      getApiErrorMessage(
        (error as AxiosError<ErrorEnvelop>) ||
          (addPackageError as AxiosError<ErrorEnvelop>),
      ) === GuestPortalErrors.JWT_EXPIRED
    ) {
      generateAuthToken({ data: { reservationId } });
    }
  }, [error, generateAuthToken, loading, reservationId, addPackageError]);

  useEffect(() => {
    if (!reservationResult) return;

    setReservation(reservationResult?.result.Reservations[0]);
  }, [reservationResult]);

  useEffect(() => {
    if (!reservationGuest) return;

    setPreregisteredGuest(reservationGuest?.result);
  }, [reservationGuest]);

  useEffect(() => {
    if (!token) return;

    getMewsReservations();
  }, [token]);

  const guestPortalContext = useMemo(() => {
    return {
      reservation,
      getMewsReservations,
      loading,
      error,
      preregisteredGuest,
      getGuestPreregistered,
      guestName,
      setGuestName,
      addPackageResult,
      addPackageToGuest,
    };
  }, [
    reservation,
    getMewsReservations,
    loading,
    error,
    preregisteredGuest,
    getGuestPreregistered,
    guestName,
    setGuestName,
    addPackageResult,
    addPackageToGuest,
  ]);

  return (
    <LoadingOverlay
      active={loading || loadingReservation || loadingPreregisterd || loadingAddPackage}
      text="Wait while we get things ready."
      className="loading-page-v"
    >
      <GuestPortalContext.Provider value={guestPortalContext}>
        {error &&
        getApiErrorMessage(error as AxiosError<ErrorEnvelop>) !==
          GuestPortalErrors.JWT_EXPIRED ? (
          <GuestPortalErrorPage error={error} />
        ) : finishedRating ? (
          <GuestPortalErrorPage finishedRating={finishedRating} />
        ) : (
          children
        )}
      </GuestPortalContext.Provider>
    </LoadingOverlay>
  );
};
