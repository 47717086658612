interface GuestPortalErrorMessages {
  title: string;
  subtitle: string;
}

export const GuestPortalErrors = {
  JWT_EXPIRED: 'jwt expired',
  NOT_FOUND: 'Mews API Error',
  INVALID_STATE: 'Reservation is not in valid state',
  INVALID_RESERVATION_ID: 'Invalid JSON',
};

export const guestPortalErrorsMapper: Record<string, GuestPortalErrorMessages> = {
  NOT_FOUND: {
    title: 'This reservation not exist',
    subtitle: 'Sorry, this reservation could not be found',
  },
  INVALID_STATE: {
    title: 'This reservation is not available',
    subtitle: 'Sorry, reservation is no longer valid for pre check-in',
  },
  INVALID_RESERVATION_ID: {
    title: 'There is something wrong with this reservation',
    subtitle: 'Sorry, this reservation has an incorrect ID',
  },
};
