import { FC } from 'react';
import Container from 'react-bootstrap/Container';

import { PropertiesTable } from '../../Tables/Properties';
import './styles/styles.css';
import Header from '../../Header';

export const Properties: FC = () => {
  return (
    <Container fluid className="reservation-list-container">
      <Header title="Packages" />
      <PropertiesTable />
    </Container>
  );
};
