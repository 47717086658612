import { FC, ReactNode } from 'react';
import { Button, Modal, ModalFooter } from 'react-bootstrap';

import './styles/UserModals.css';

export interface ModalComponentData {
  show: boolean;
  size?: 'lg' | 'sm' | 'xl' | undefined;
  handleSave: () => void;
  handleClose: () => void;
  className?: string;
  children?: ReactNode[];
  title?: string;
  cancelButton?: string;
  saveButton?: string;
}

export const ModalComponent: FC<ModalComponentData> = ({
  show,
  size,
  handleClose,
  handleSave,
  className,
  children,
  title,
  cancelButton,
  saveButton,
}) => {
  return (
    <Modal
      size={size}
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={`user--role-modal ${className}`}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title>{children?.length ? children[1] : title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children?.length ? children[0] : children}</Modal.Body>
      <ModalFooter
        className={`border-0 edit--modal-footer ${className} justify-content-end`}
      >
        <Button className="cancel-button" onClick={handleClose}>
          {cancelButton}
        </Button>
        <Button className="save-button" onClick={handleSave}>
          {saveButton}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
