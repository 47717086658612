import { FC, useState, useCallback, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Overlay, Popover } from 'react-bootstrap';
import './styles/styles.css';
import Header from '../../Header';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import DateIcon from '../../../assets/date.svg';
import UnCheckRadioIcon from '../../../assets/uncheck-radio-icon.svg';
import CheckRadioIcon from '../../../assets/check-radio-icon.svg';
import ExpertIcon from '../../../assets/expert.svg';
import { monthList, yearList } from '../../../constants/generalData';
import { RoomRevenueReportTable } from '../../Tables/RoomRevenueReports ';

export const RoomRevenueReports: FC = () => {
  const [recentData, setRecentData] = useState([
    {
      months: 'December, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'November, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'October, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'September, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'August, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'July, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'June, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'May, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'April, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'March, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'February, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
    {
      months: 'January, 2023',
      classic_queen: '$7,564.00',
      family_suite: '$4,567.00',
      queen_suite: '$8,942.00',
      king_suite: '$3,467.00',
      luxury: '$5,113.00',
      total: '$29,653.00',
    },
  ]);
  const roomTypeQuarter = [
    {
      months: 'Quarter 4, 2023',
      classic_queen: '$17,564.00',
      family_suite: '$12,567.00',
      queen_suite: '$15,942.00',
      king_suite: '$23,467.00',
      luxury: '$25,113.00',
      total: '$79,653.00',
    },
    {
      months: 'Quarter 3, 2023',
      classic_queen: '$17,564.00',
      family_suite: '$12,567.00',
      queen_suite: '$15,942.00',
      king_suite: '$23,467.00',
      luxury: '$25,113.00',
      total: '$79,653.00',
    },
    {
      months: 'Quarter 2, 2023',
      classic_queen: '$17,564.00',
      family_suite: '$12,567.00',
      queen_suite: '$15,942.00',
      king_suite: '$23,467.00',
      luxury: '$25,113.00',
      total: '$79,653.00',
    },
    {
      months: 'Quarter 1, 2023',
      classic_queen: '$17,564.00',
      family_suite: '$12,567.00',
      queen_suite: '$15,942.00',
      king_suite: '$23,467.00',
      luxury: '$25,113.00',
      total: '$79,653.00',
    },
  ];
  const roomNumberMonth = [
    {
      room: '1',
      room_type: 'Luxury Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '2',
      room_type: 'Classic Queen',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '3',
      room_type: 'Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '4',
      room_type: 'Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '5',
      room_type: 'Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '6',
      room_type: 'Classic Queen',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '7',
      room_type: 'Classic Queen',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '8',
      room_type: 'Luxury Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '9',
      room_type: 'Luxury Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '10',
      room_type: 'Luxury Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '11',
      room_type: 'Luxury Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '12',
      room_type: 'Family Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '13',
      room_type: 'Family Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
    {
      room: '14',
      room_type: 'Luxury Queen Suite',
      january: '$14,567.00',
      february: '$13,000.00',
      march: '$15,650.00',
      april: '$14,444.00',
      may: '$21,560.00',
      june: '$12,267.00',
      july: '$24,500.00',
      august: '$4,670.00',
      september: '$5,565.00',
      october: '$8,990.00',
      november: '$10,200.00',
      december: '$13,500.00',
      total: '$329,650.00',
    },
  ];
  const roomNumberQuarter = [
    {
      room: '1',
      room_type: 'Luxury Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '2',
      room_type: 'Classic Queen',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '3',
      room_type: 'Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '4',
      room_type: 'Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '5',
      room_type: 'Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '6',
      room_type: 'Classic Queen',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '7',
      room_type: 'Classic Queen',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '8',
      room_type: 'Luxury Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '9',
      room_type: 'King Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '10',
      room_type: 'Luxury Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '11',
      room_type: 'Luxury Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '12',
      room_type: 'Family Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '13',
      room_type: 'Family Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
    {
      room: '14',
      room_type: 'Luxury Queen Suite',
      quarter_1: '$14,567.00',
      quarter_2: '$13,000.00',
      quarter_3: '$15,650.00',
      quarter_4: '$14,444.00',
      total: '$329,650.00',
    },
  ];
  const [view, setView] = useState<string>('by Month');
  const [viewFinal, setViewFinal] = useState<string>('by Month');
  const [roomView, setRoomView] = useState<string>('Total by room type');
  const [roomViewFinal, setRoomViewFinal] = useState<string>('Total by room type');
  const viewArray = ['by Quarter', 'by Month'];
  const roomArray = ['Total by room type', 'Total by room number'];
  const [dateShow, setDateShow] = useState(false);
  const dateTarget = useRef(null);
  const roomTarget = useRef(null);
  const [viewShow, setViewShow] = useState(false);

  const [roomShow, setRoomShow] = useState(false);
  const viewTarget = useRef(null);
  const fromMonth = useRef(null);
  const toMonth = useRef(null);
  const fromYear = useRef(null);
  const toYear = useRef(null);
  const [fromMonthShow, setFromMonthShow] = useState(false);
  const [toMonthShow, setToMonthShow] = useState(false);
  const [fromYearShow, setFromYearShow] = useState(false);
  const [toYearShow, setToYearShow] = useState(false);
  const [fromMonthValue, setFromMonthValue] = useState('September');
  const [toMonthValue, setToMonthValue] = useState('June');
  const [fromYearValue, setFromYearValue] = useState('2021');
  const [toYearValue, setToYearValue] = useState('2024');
  const [fromMonthValueFinal, setFromMonthValueFinal] = useState<string>('September');
  const [toMonthValueFinal, setToMonthValueFinal] = useState<string>('June');
  const [fromYearValueFinal, setFromYearValueFinal] = useState<string>('2021');
  const [toYearValueFinal, setToYearValueFinal] = useState<string>('2024');
  const [tableType, setTableType] = useState('roomTypeMonth');

  const refreshHandler = useCallback(async () => {
    console.log('test');
  }, []);

  const onViewItemClick = (reportItem: string) => {
    if (roomShow) {
      setRoomView(reportItem);
    } else {
      setView(reportItem);
    }
  };

  const renderViewItem = (reportItem: string) => {
    const value = roomShow ? roomView : view;
    const isSelected = value === reportItem;
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onViewItemClick(reportItem)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckRadioIcon : UnCheckRadioIcon}
          alt="check-icon"
        />
        <div style={{ textTransform: 'none' }}>{reportItem}</div>
      </div>
    );
  };

  const onCancleView = () => {
    setView(viewFinal);
    setViewShow(false);
    setRoomShow(false);
    setRoomView(roomViewFinal);
  };

  const setTableTypeState = (viewA: string, roomType: string) => {
    if (viewA === 'by Quarter' && roomType === 'Total by room type') {
      setTableType('roomTypeQuarter');
    } else if (viewA === 'by Month' && roomType === 'Total by room type') {
      setTableType('roomTypeMonth');
    } else if (viewA === 'by Quarter' && roomType === 'Total by room number') {
      setTableType('roomNumberQuarter');
    } else if (viewA === 'by Month' && roomType === 'Total by room number') {
      setTableType('roomNumberMonth');
    }
  };

  const onApplyView = () => {
    if (view) {
      setViewFinal(view);
      setViewShow(false);
      setTableTypeState(view, roomViewFinal);
    }
  };
  const onApplyRoom = () => {
    if (roomView) {
      setRoomViewFinal(roomView);
      setRoomShow(false);
      setTableTypeState(viewFinal, roomView);
    }
  };

  const onCancledate = () => {
    setDateShow(false);
    setFromMonthValue(fromMonthValueFinal);
    setToMonthValue(toMonthValueFinal);
    setFromYearValue(fromYearValueFinal);
    setToYearValue(toYearValueFinal);
  };

  const onApplyDate = () => {
    setDateShow(false);
    if (fromMonthValue) {
      setFromMonthValueFinal(fromMonthValue);
    }
    if (toMonthValue) {
      setToMonthValueFinal(toMonthValue);
    }
    if (fromYearValue) {
      setFromYearValueFinal(fromYearValue);
    }
    if (toYearValue) {
      setToYearValueFinal(toYearValue);
    }
  };

  const onFromMonthClick = () => {
    setFromMonthShow(true);
  };

  const onFromYearClick = () => {
    setFromYearShow(true);
  };

  const onToMonthClick = () => {
    setToMonthShow(true);
  };

  const onToYearClick = () => {
    setToYearShow(true);
  };

  const onMonthClick = (isFrom: boolean, month: string) => {
    setFromMonthShow(false);
    setToMonthShow(false);
    if (isFrom) {
      setFromMonthValue(month);
    } else {
      setToMonthValue(month);
    }
  };

  const onYearClick = (isFrom: boolean, year: string) => {
    setFromYearShow(false);
    setToYearShow(false);
    if (isFrom) {
      setFromYearValue(year);
    } else {
      setToYearValue(year);
    }
  };

  const renderDateByYear = () => {
    return (
      <div className="drop-down-container-status">
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">From</div>
              <div
                onClick={onFromYearClick}
                ref={fromYear}
                className="year-calender-container "
              >
                <div className="year-text-style">{fromYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">To</div>
              <div
                onClick={onToYearClick}
                ref={toYear}
                className="year-calender-container "
              >
                <div className="year-text-style">{toYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="apply-and-cancle-container">
          <div onClick={onCancledate} className="cancle-text-container">
            Cancel
          </div>
          <div onClick={onApplyDate} className="apply-text-container">
            Apply
          </div>
        </div>
      </div>
    );
  };

  const renderMonthList = (isFrom: boolean) => {
    return (
      <div style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
        {monthList.map(item => (
          <div
            onClick={() => onMonthClick(isFrom, item)}
            className="dropdown-item-calendar-picker"
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderYearList = (isFrom: boolean) => {
    return (
      <div style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
        {yearList.map(item => (
          <div
            onClick={() => onYearClick(isFrom, item.toString())}
            className="dropdown-item-calendar-picker"
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderDateByMonth = () => {
    return (
      <div className="drop-down-container-status">
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">From</div>
              <div
                ref={fromMonth}
                className="year-calender-container by-month-container"
                onClick={() => onFromMonthClick()}
              >
                <div className="year-text-style">{fromMonthValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromMonthShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div
                onClick={onFromYearClick}
                ref={fromYear}
                className="year-calender-container by-month-text"
              >
                <div className="year-text-style">{fromYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">To</div>
              <div
                ref={toMonth}
                onClick={() => onToMonthClick()}
                className="year-calender-container by-month-container "
              >
                <div className="year-text-style">{toMonthValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toMonthShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div
                onClick={onToYearClick}
                ref={toYear}
                className="year-calender-container by-month-text"
              >
                <div className="year-text-style">{toYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="apply-and-cancle-container">
          <div onClick={onCancledate} className="cancle-text-container">
            Cancel
          </div>
          <div onClick={onApplyDate} className="apply-text-container">
            Apply
          </div>
        </div>
      </div>
    );
  };

  const getTableData = () => {
    if (tableType === 'roomTypeMonth') {
      return recentData;
    }
    if (tableType === 'roomTypeQuarter') {
      return roomTypeQuarter;
    }
    if (tableType === 'roomNumberMonth') {
      return roomNumberMonth;
    }
    if (tableType === 'roomNumberQuarter') {
      return roomNumberQuarter;
    }
    return [];
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title="Room Revenue Reports — 75" />
      <div className="artical-text-main-container">
        <div className="artical-dropdown-container ">
          <div
            ref={roomTarget}
            onClick={() => setRoomShow(!roomShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              <div>{roomViewFinal}</div>
            </div>
            <img src={roomShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div
            ref={dateTarget}
            onClick={() => setViewShow(!viewShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              <div className="view-text-style">View: </div>
              {viewFinal}
            </div>
            <img src={viewShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div className="catagory-container">
            <div
              ref={viewTarget}
              onClick={() => setDateShow(!dateShow)}
              style={{ marginLeft: 0 }}
              className="date-rage-picker-text-container"
            >
              <img src={DateIcon} alt="date" />
              <div className="category-text-style">Last year</div>
              <img
                className="drop-down-icon-style"
                src={dateShow ? DropDownOpenIcon : DropDownIcon}
                alt="drop-down"
              />
            </div>
          </div>
        </div>
        <div className="add-artical-container">
          <img src={ExpertIcon} alt="plus-icon" />
          <div className="add-artical-text-style">Export</div>
        </div>
      </div>

      <Overlay
        onHide={() => setDateShow(false)}
        target={viewTarget.current}
        show={dateShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {viewFinal === 'by Month' ? renderDateByMonth() : renderDateByYear()}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setViewShow(false)}
        target={dateTarget.current}
        rootClose
        show={viewShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {viewArray.map(renderViewItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleView} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyView} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setRoomShow(false)}
        target={roomTarget.current}
        rootClose
        show={roomShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {roomArray.map(renderViewItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleView} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyRoom} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setFromMonthShow(false)}
        target={fromMonth.current}
        rootClose
        show={fromMonthShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderMonthList(true)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setToMonthShow(false)}
        target={toMonth.current}
        rootClose
        show={toMonthShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderMonthList(false)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setFromYearShow(false)}
        target={fromYear.current}
        rootClose
        show={fromYearShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">{renderYearList(true)}</Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setToYearShow(false)}
        target={toYear.current}
        rootClose
        show={toYearShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderYearList(false)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <RoomRevenueReportTable
        searchString=""
        tableData={getTableData()}
        refreshHandler={refreshHandler}
        tabletype={tableType}
      />
    </Container>
  );
};
