import { Col, Container, Row } from 'react-bootstrap';
import '../styles/styles.css';
import { FC, useContext, useEffect } from 'react';
import { GuestPortalContext } from '../../../modules/context/guestPortalContext';

interface GuestPageHeaderProps {
  type: 'firstRegistration' | 'verify';
}

const GuestPageHeader: FC<GuestPageHeaderProps> = ({ type }) => {
  const { reservation, loading, preregisteredGuest, guestName, setGuestName } =
    useContext(GuestPortalContext)!;

  useEffect(() => {
    if (!preregisteredGuest?.guest.firstName || !preregisteredGuest?.guest.lastName)
      return;
    setGuestName(
      `${preregisteredGuest?.guest.firstName} ${preregisteredGuest?.guest.lastName}`,
    );
  }, [
    preregisteredGuest?.guest.firstName,
    preregisteredGuest?.guest.lastName,
    setGuestName,
  ]);

  return (
    <Container className="image-container">
      <Row className="pb-16" style={{ height: '50%', marginTop: '30px' }}>
        <Col>
          <div className="table-title">
            <h6 className="mb-0">{`Welcome ${
              guestName ||
              `${reservation?.Customer?.FirstName} ${reservation?.Customer?.LastName}` ||
              'Guest'
            }`}</h6>
          </div>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <div className="table-title">{/* TO-DO show current temperature */}</div>
        </Col>
      </Row>
      <Row className="pb-16" style={{ height: '50%' }}>
        {type === 'firstRegistration' ? (
          <>
            <Col xs="9" style={{ display: 'flex', alignContent: 'end' }}>
              <div className="table-title">
                <h6 className="mb-0">
                  {reservation?.Resource?.Data?.Value?.FloorOrdinalNumber}
                </h6>
                <h3 className="mb-0 mt-12px">
                  {loading ? 'Loading room name...' : reservation?.Resource?.ResourceName}
                </h3>
              </div>
            </Col>
            <Col
              xs="3"
              style={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <div className="table-title">
                <h6
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 0,
                  }}
                >
                  Room
                </h6>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div className="number-circle">{reservation?.Resource?.Name}</div>
                </div>
              </div>
            </Col>
          </>
        ) : (
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div className="table-title">
              <h3 className="mb-0">Let&apos;s get your Booking Verified</h3>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default GuestPageHeader;
