import React, { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';
import DropDownIcon from '../../../assets/down-black.svg';
import UploadFileIcon from '../../../assets/upload-file.svg';
import AddTagIcon from '../../../assets/add-tag-icon.svg';
import DeleteArticalImgIcon from '../../../assets/delete-article-image.svg';
import DeleteTagIcon from '../../../assets/delete-tag-icon.svg';
import { Article, Categories } from '../../../modules/interfaces';

type AddArticlesProp = {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  categories: Categories[];
  article?: Article;
};

type CustomToggleProp = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CustomToggle = React.forwardRef(
  ({ children, onClick }: CustomToggleProp, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div
      ref={ref}
      className="damage-report-drop-down-style"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={DropDownIcon} alt="drop-down" />
    </div>
  ),
);

const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'MP4', 'MOV'];

type TagItem = {
  name: string;
  isAdd?: boolean;
  isEdit?: boolean;
};

const AddArticles: FC<AddArticlesProp> = ({
  show,
  onHide,
  onSave,
  categories,
  article,
}) => {
  const getTagList = (articleA: Article) => {
    const newArticle = articleA.tags.map(item => {
      return { name: item, isAdd: false };
    });
    newArticle.push({ name: '', isAdd: true });
    return newArticle;
  };

  const getCategory = () => {
    if (article && article.categories[0]) {
      return article.categories[0].name;
    }
    if (categories.length > 0) {
      return categories[0].name;
    }
    return '';
  };

  const [articaleName, setArticalName] = useState<string>(article ? article.name : '');
  const [articleBody, setArticalBody] = useState<string>(article ? article.body : '');
  const [category, setCategory] = useState<string>(getCategory());
  const [files, setFiles] = useState<File[]>([]);
  const [tags, setTags] = useState<TagItem[]>(
    article && article.tags.length > 0
      ? getTagList(article)
      : [{ name: '', isAdd: true }],
  );

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['link'],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'align',
    'link',
  ];

  const onArticleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setArticalName(event.target.value);
  };

  const onChangeBody = (value: string) => {
    setArticalName(value);
  };

  const handleChange = (fileA: FileList) => {
    const keyList = Object.keys(fileA);
    const fileList: File[] = [];
    keyList.forEach((key, index) => {
      fileList.push(fileA.item(index) as File);
    });
    setFiles([...files, ...fileList]);
  };

  const onSelectStatus = (eventKey: string | null) => {
    if (eventKey) {
      setCategory(eventKey);
    }
  };

  const onAddNewTag = () => {
    const newTag = {
      name: '',
      isEdit: true,
    };
    const list = [...tags];
    list.unshift(newTag);
    setTags(list);
  };

  const onDeleteTag = (index: number) => {
    const list = [...tags];
    list.splice(index, 1);
    setTags(list);
  };

  const onSubmitTag = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && tags[index].name) {
      const newList = tags.map((item, j) => {
        const tagItem = { ...item };
        if (index === j) {
          tagItem.isEdit = false;
        }
        return tagItem;
      });
      setTags(newList);
    }
  };

  const onEditTagChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newList = tags.map((item, j) => {
      const tagItem = { ...item };
      if (index === j) {
        tagItem.name = event.target.value;
      }
      return tagItem;
    });
    setTags(newList);
  };

  const handleTagClick = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    if (e.detail === 2) {
      const newList = tags.map((item, j) => {
        const tagItem = { ...item };
        if (index === j) {
          tagItem.isEdit = true;
        }
        return tagItem;
      });
      setTags(newList);
    }
  };

  const renderTagsItem = (item: TagItem, index: number) => {
    if (item.isAdd) {
      return (
        <div onClick={onAddNewTag} className="add-tags-text">
          <img src={AddTagIcon} alt="add-tag-icon" className="add-tag-icon" />
          Add tags
        </div>
      );
    }
    if (item.isEdit) {
      return (
        <div className="edit-tags-text">
          <input
            onChange={e => onEditTagChange(e, index)}
            value={item.name}
            onKeyDown={event => onSubmitTag(index, event)}
            placeholder="Tag name"
            className="p-0 edit-tag-input"
          />
          <div className="delete-tag-icon" onClick={() => onDeleteTag(index)}>
            <img src={DeleteTagIcon} alt="delete-tag-icon" />
          </div>
        </div>
      );
    }
    return (
      <div onClick={e => handleTagClick(e, index)} className="view-tags-text">
        {item.name}
        <div className="delete-tag-icon" onClick={() => onDeleteTag(index)}>
          <img src={DeleteTagIcon} alt="delete-tag-icon" />
        </div>
      </div>
    );
  };

  const onDeleteFile = (index: number) => {
    const list = [...files];
    list.splice(index, 1);
    setFiles(list);
  };

  const renderFileItem = (item: any, index: number) => {
    return (
      <div className="add-article-img-container">
        <img
          className="add-article-img"
          src={URL.createObjectURL(item)}
          alt="selected-file"
        />
        <div onClick={() => onDeleteFile(index)}>
          <img
            className="delete-artical-img"
            src={DeleteArticalImgIcon}
            alt="delete-article"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="modal-view-style"
      show={show}
      contentClassName="modal-content-style"
      dialogClassName="article-modal-classname"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">
          {article ? 'Edit article' : 'Create new article'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-reaservation-modal-title">Article name</div>
        <input
          className="add-article-input-style"
          aria-describedby="noteHelp"
          placeholder="Enter Article name"
          onChange={onArticleNameChange}
          value={articaleName}
        />
        <div className="edit-guest-input-title">Category</div>
        <Dropdown onSelect={onSelectStatus}>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <div className="row-status-drop-down">{category}</div>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: '100%' }}>
            {categories.map(item => {
              return <Dropdown.Item eventKey={item.name}>{item.name}</Dropdown.Item>;
            })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="edit-guest-input-title">Article Text</div>
        <ReactQuill
          theme="snow"
          modules={modules}
          value={articleBody}
          onChange={onChangeBody}
          formats={formats}
          placeholder="Enter Article text"
        />
        <div className="edit-guest-input-title">Media files</div>
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          multiple
          maxSize={50}
        >
          <div className="media-file-upload-container">
            <img src={UploadFileIcon} alt="upload-file" />
            <div className="file-upload-title">Upload file or drag & drop</div>
            <div className="file-upload-desc">PNG, JPG, GIF, MP4, MOV up to 50 MB</div>
          </div>
        </FileUploader>
        <div className="add-tag-arry-style" style={{ marginBottom: '20px' }}>
          {files.map(renderFileItem)}
        </div>
        <div className="edit-guest-input-title">Tags</div>
        <div className="tag-list-style">{tags.map(renderTagsItem)}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button className="edit-guest-modal-button-save" onClick={() => onSave()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddArticles;
