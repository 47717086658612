import { ChangeEvent, FC, FormEvent, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

import './styles/forgotstyle.css';
import { useAuth, useNotifications } from '../../../modules/hooks';
import { routes } from '../../../modules/mappers/urls';
import { LogoIcon } from '../../Logo';

export const ForgotPassword: FC = () => {
  const { forgotPassword } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangeEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const handleSend = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await forgotPassword(email);
      navigate(routes.CREATENEWPASSWORD);
    } catch (e) {
      const { message } = e as Error;
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    setIsLoading(false);
  };

  return (
    <Container
      fluid
      className="login forgot-password d-flex flex-column justify-content-center"
    >
      <Container className="content" fluid>
        <Row className="justify-content-md-center loginLogo">
          <Col />
          <Col className="justify-content-md-center" xs="auto" sm="auto">
            <LogoIcon width="49.5px" height="45px" />
          </Col>
          <Col />
        </Row>
        <Row className="justify-content-md-center forgotPassHeader text-center">
          <Col />
          <Col className="justify-content-md-center" xs="auto" sm="auto">
            <h3 className="login-heading">Forgot your Password?</h3>
            <p>
              Enter your email and we will share a link to <br /> create a new password
            </p>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col>
            <form>
              <div className="form-group email-field">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email address"
                  onChange={onChangeEmailInput}
                />
              </div>
              <button
                disabled={isLoading}
                type="submit"
                className="btn submit-btn"
                onClick={handleSend}
              >
                Send Link
              </button>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
