/* eslint-disable no-nested-ternary */
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import React, { useEffect, useState } from 'react';
import './styles/styles.css';
import { useLocation, useParams } from 'react-router-dom';

import { GuestPortalProvider } from '../Providers/GuestPortal';
import GuestPageHeader from './Header';
import { GuestPortal } from './GuestPortal';
import { GuestRegistration } from './GuestRegistration';
import { Feedback } from './Feedback';
import { useColorScheme } from '../../modules/utils/colorScheme';

const GuestPageLayout = () => {
  const { pathname } = useLocation();
  const { reservationId } = useParams();
  const isDarkMode = useColorScheme();

  const changeFavicon = (link: string) => {
    let favicon = document.querySelector('link[rel="icon"]');

    if (!favicon) {
      favicon = document.createElement('link');
      favicon.setAttribute('rel', 'icon');
      document.head.appendChild(favicon);
    }

    favicon.setAttribute('href', link);
  };

  useEffect(() => {
    document.title = 'Guest Portal';
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      changeFavicon('/ch_logo.ico');
    } else {
      changeFavicon('/ch_logo_black.ico');
    }
  }, [isDarkMode]);

  return (
    <GuestPortalProvider reservationId={reservationId || ''}>
      <Container
        id="guest-portal"
        fluid
        className="page-container"
        style={{ paddingLeft: '0px', paddingRight: '0px' }}
      >
        <Card className="card-item" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {pathname.includes('/guest-registration/') ? (
            <>
              <GuestPageHeader type="verify" />
              <GuestRegistration reservationId={reservationId || ''} />
            </>
          ) : pathname.includes('feedback') ? (
            <>
              <GuestPageHeader type="firstRegistration" />
              <Feedback />
            </>
          ) : (
            <>
              <GuestPageHeader type="firstRegistration" />
              <GuestPortal />
            </>
          )}
        </Card>
      </Container>
    </GuestPortalProvider>
  );
};

export default GuestPageLayout;
