import { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Eye } from 'react-bootstrap-icons';
import { MewsReservation, RoomLocks } from '../../modules/interfaces';
import DropDownIcon from '../../assets/drop-down.svg';
import DropDownUpIcon from '../../assets/drop-down-up.svg';
import RoomIcon from '../../assets/room.svg';
import { endpoints } from '../../modules/mappers/urls';
import { useAuth, useLoading, useRequest } from '../../modules/hooks';
import { AccessCodeModal } from '../Modals/ReservationModals/AccessCodeModal';
import { LockUnlockModal } from '../Modals/ReservationModals/LockUnlockModal';

interface RoomItemProps {
  roomItem: MewsReservation;
  reservationId: string;
  isPrevious?: boolean;
}

export const RoomItem: FunctionComponent<RoomItemProps> = ({
  roomItem: item,
  reservationId,
  isPrevious,
}: RoomItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { credentialsInfo } = useAuth()!;
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [locksList, setLocksList] = useState<RoomLocks[]>([]);

  const { setLoading } = useLoading()!;
  const [showAccessCode, setShowAccessCode] = useState(false);

  const getReservationUrl = `${endpoints.RESERVATION}/${reservationId}/locks`;
  const [{ data: reservationResult, loading: loadingReservation }, getReservationLocks] =
    useRequest<RoomLocks[]>(
      getReservationUrl,
      'get',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const generateAccessCode = `${endpoints.RESERVATION}/${reservationId}/access-code`;
  const [
    { data: accessCodeResult, loading: loadingAccessCode },
    generateReservationAccessCode,
  ] = useRequest<string>(
    generateAccessCode,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const accessCodeUrl = `${endpoints.RESERVATION}/${reservationId}/access-code`;
  const [{ data: getAccessCodeResult, loading: loadingGetAccessCode }, getAccessCode] =
    useRequest<string>(
      accessCodeUrl,
      'get',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const generateAccessCodes = () => {
    generateReservationAccessCode({
      data: { reusePod: false },
      headers: {
        authorization: credentialsInfo?.token,
      },
    });
  };

  const showAccessCodeHandler = () => {
    if (!accessCode) {
      getAccessCode();
    } else {
      setShowAccessCode(!showAccessCode);
    }
  };

  useEffect(() => {
    setLoading(loadingReservation || loadingAccessCode || loadingGetAccessCode);
  }, [loadingAccessCode, loadingGetAccessCode, loadingReservation, setLoading]);

  useEffect(() => {
    if (isPrevious) return;

    getReservationLocks();
  }, []);

  useEffect(() => {
    if (!accessCodeResult?.result) return;

    setAccessCode(accessCodeResult.result);
    setShowAccessCodeModal(true);
  }, [accessCodeResult, setLoading]);

  useEffect(() => {
    if (!reservationResult?.result[0]?.lockId || isPrevious) return;

    setLocksList(reservationResult.result);
  }, [reservationResult]);

  useEffect(() => {
    if (!getAccessCodeResult?.result) return;

    setAccessCode(getAccessCodeResult.result);
    setShowAccessCode(!showAccessCode);
  }, [getAccessCodeResult]);

  const onDropDownClick = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const renderLockItem = (lockItem: RoomLocks, lockIndex: number) => {
    const isLastIndex = locksList.length - 1 === lockIndex;
    return (
      <>
        <div key={lockIndex} className="lock-list-item-container">
          <div className="lock-list-row-space">
            <div>
              <div className="lock-name-title-text">Lock name</div>
              <div className="lock-name-title-value">{lockItem.lockName}</div>
            </div>

            <LockUnlockModal rowData={lockItem} lockId={reservationId} />
          </div>
        </div>
        {!isLastIndex && <div key={lockIndex} className="lock-item-divider" />}
      </>
    );
  };

  return (
    <div className="room-list-item-main-container">
      <div
        className="room-list-item-container"
        style={{
          borderRadius: isOpen ? undefined : '10px',
          cursor: isPrevious ? 'default' : 'pointer',
        }}
        onClick={() => !isPrevious && onDropDownClick()}
      >
        <div className="hotel-detail-container">
          <img
            style={{ borderRadius: '8px' }}
            className="hotel-image"
            alt="room"
            src={RoomIcon}
          />
          <div className="hotel-name-container">
            <div className="hotel-name">{`Room ${item.Resource?.Name}`}</div>
            <div className="hotel-details">{item.Resource?.ResourceName}</div>
          </div>
        </div>
        {!isPrevious && (
          <img alt="drop-down" src={isOpen ? DropDownUpIcon : DropDownIcon} />
        )}
      </div>
      {isOpen && (
        <>
          <div className="room-lock-info-container">
            {locksList.map((lockItem, index) => renderLockItem(lockItem, index))}
          </div>
          <div className="full-line-divider" />
          <div className="lock-list-item-container">
            <div className="lock-list-row-space">
              <div className="door-access-code-text">Door access code</div>
              <div className="access-code-container">
                <div className="access-code-container">
                  <div> {showAccessCode ? accessCode : '********'}</div>

                  <Button
                    style={{
                      padding: '0px',
                      marginTop: '0px',
                      marginLeft: 10,
                      backgroundColor: '#fff',
                      color: 'black',
                    }}
                  >
                    <Eye onClick={() => showAccessCodeHandler()} size={18} />
                  </Button>
                </div>
                <div
                  className="lock-unlock-button"
                  style={{ backgroundColor: '#222222', marginLeft: '40px' }}
                  onClick={generateAccessCodes}
                >
                  <div>Generate code</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <AccessCodeModal
        show={showAccessCodeModal}
        handleClose={() => {
          setShowAccessCodeModal(false);
        }}
        accessCode={accessCode}
      />
    </div>
  );
};
