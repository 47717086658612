import { FC, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Pagination from 'react-bootstrap/Pagination';

import { DashboardSearchInput } from '../../../Inputs/Search';
import { DropDownSelect } from '../../../Inputs/DropDownSelect';
import { PostEditorModal } from '../../../Modals/PostEditor';
import { Article } from '../../../../modules/interfaces';

import './styles/styles.css';

export const ArticleSearch: FC = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [editData] = useState<Article>();
  const handleEditorModalClose = () => setModal(false);
  return (
    <Container fluid className="main-container mb-5">
      <Row className="justify-content-center article-search">
        <DashboardSearchInput />
      </Row>
      <Row className="articles-section">
        <h4 className="header-title">Search Results (3)</h4>
        <Row className="justify-content-end justify-content-sm-between align-items-center">
          <Row className="filter-dropdown w-auto">
            <span className="w-auto dropdown-title">Categories:</span>
            <DropDownSelect
              data={[]}
              placeholder="Select Category"
              onConfirm={() => {
                // TODO: need to implement
              }}
            />
          </Row>
        </Row>
      </Row>
      <Row className="mt-2 justify-content-end align-items-center">
        <span className="w-auto article-page-title">Pages:</span>
        <Pagination className="justify-content-end mt-0 w-auto">
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item active>1</Pagination.Item>
          <Pagination.Item>2</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Item>10</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
        </Pagination>
      </Row>
      <PostEditorModal
        show={modal}
        handleClose={handleEditorModalClose}
        data={editData}
        categories={[]}
        handleSubmit={() => {
          // TODO: need to implement
        }}
      />
    </Container>
  );
};
