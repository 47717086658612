export enum DashboardTypes {
  BALANCES = 'Balances',
  RESERVATIONS = 'Reservations',
  INBOX = 'Inbox',
  ARTICLES = 'Articles',
  FORGOTPASSWORD = 'Forgotpassword',
  CREATENEWPASSWORD = 'Createnewpassword',
}

export enum ModalType {
  EDIT,
  DELETE,
  SUSPEND_USER,
  ACTIVATE_USER,
  RESET_PASSWORD,
}

export enum TagReducerType {
  UPDATE = 'UPDATE',
  RESET = 'RESET',
}

export enum PaymentType {
  RESERVATION_PACKAGE = 'reservation-package',
  SAFETY_DEPOSIT = 'safety-deposit',
}
