import { FC, PropsWithChildren, useMemo, useState } from 'react';

import {
  NotificationContext,
  ToastSimpleNotifications,
} from '../../../modules/context/notificationContext';

import { SimpleToasts } from '../../Toasts/Simple';

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [simpleToasts, setSimpleToasts] = useState<ToastSimpleNotifications>();

  const notificationContext = useMemo(() => {
    return { simpleToasts, setSimpleToasts };
  }, [simpleToasts, setSimpleToasts]);

  return (
    <NotificationContext.Provider value={notificationContext}>
      {children}
      <SimpleToasts />
    </NotificationContext.Provider>
  );
};
