import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../modules/hooks';
import { routes } from '../../modules/mappers/urls';
import { AuthStatus } from '../../modules/enums/status';

export const ProtectedRoute = ({ children }: PropsWithChildren): JSX.Element => {
  const { authStatus } = useAuth() || {};
  const location = useLocation();
  let element = children;
  if (authStatus === AuthStatus.signedOut) {
    element = <Navigate to={routes.LOGIN} replace state={{ from: location }} />;
  }

  return element as JSX.Element;
};
