import { FC, useState, useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { DataTable } from '../DataTable';
import { config } from '../../../modules/config';
import { Article, Categories } from '../../../modules/interfaces';
import DeleteIcon from '../../../assets/delete-icon.svg';
import EditblackIcon from '../../../assets/edit-icon.svg';
import VerticalDotMenuIcon from '../../../assets/vertical-dot-menu.svg';

type CategoryTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
  onEditCategory: (category?: Categories) => void;
  onDeleteCategory: (category?: Categories) => void;
};

export const CategoryTable: FC<CategoryTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  onEditCategory,
  onDeleteCategory,
}: CategoryTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const renderMenuIcon = (item: RowClickedEvent<Categories>) => {
    return (
      <div className="menu-icon-container-style">
        <OverlayTrigger
          trigger="click"
          placement="left"
          rootClose
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <div
                  onClick={e => {
                    document.body.click();
                    onEditCategory(item.data);
                  }}
                  style={{ paddingBottom: '5px' }}
                  className="edit-damage-report-container"
                >
                  <img
                    className="edit-icon-image-style"
                    src={EditblackIcon}
                    alt="edit-black-icon"
                  />
                  <div className="edit-text-style">Edit</div>
                </div>

                <div
                  style={{ marginTop: '10px' }}
                  className="edit-damage-report-container"
                  onClick={e => {
                    document.body.click();
                    onDeleteCategory(item.data);
                  }}
                >
                  <img
                    className="edit-icon-image-style"
                    src={DeleteIcon}
                    alt="edit-black-icon"
                  />
                  <div className="delete-text-style">Delete</div>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div>
            <img src={VerticalDotMenuIcon} alt="vertical-icon" />
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const [columns] = useState<ColDef<Article>[]>([
    { field: 'name', headerName: 'CATEGORY NAME', width: 150 },

    {
      valueGetter: params => {
        const { data: article } = params;
        const customer = article?.createdBy;
        const name = customer ? `${customer.firstName} ${customer.lastName}` : '-';
        return name;
      },
      headerName: ' AETICLES IN CATEGORY',
      width: 100,
      sortable: true,
    },

    {
      field: 'createdAt',
      headerName: 'CREATION DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<Article, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value).toFormat('MM-dd-yyyy');
        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      width: 100,
    },

    {
      field: '',
      headerName: '',
      cellRenderer: renderMenuIcon,
      width: 1,
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
      pagination
      paginationPageSize={config.tables.reservations.paginationSize}
    />
  );
};
