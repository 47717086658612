import { FC, useState, useEffect } from 'react';
import { Card, Col, Dropdown, Image, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  Archive,
  BoxArrowInLeft,
  ChevronRight,
  PencilSquare,
  Trash,
} from 'react-bootstrap-icons';

import DeleteIcon from '../../../../assets/delete-icon.svg';
import EditblackIcon from '../../../../assets/edit-icon.svg';
import ArchiveIcon from '../../../../assets/archive.svg';
import DraftIcon from '../../../../assets/draft.svg';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../../modules/hooks';
import { Article, Categories, Media } from '../../../../modules/interfaces';
import { endpoints, routes } from '../../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../../modules/utils/transform';
import { DotDropDown } from '../../../Customize/DropDown';
import './styles/styles.css';
import Header from '../../../Header';
import AddArticles from '../../../Modals/AddArticles/AddArticles';
import DeleteModal from '../../../Modals/DeleteModal/DeleteModal';

interface RowMediaData {
  rowData: Media[];
  key: string;
}

interface ArticlesDetailsProps {
  handleClose?: () => void;
  articleData?: Article;
  subpage: boolean;
}

export const ArticlesDetails: FC<ArticlesDetailsProps> = ({
  handleClose,
  articleData,
  subpage,
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState<boolean>(false);
  const [actionArticle, setActionArticle] = useState<Article | undefined>();
  const [categories, setCategories] = useState<Categories[]>([]);
  const [addArticle, setAddArticle] = useState(false);
  const [editData, setEditData] = useState<Article>();
  const [articleId, setArticleId] = useState<string>();
  const [mediaMatrix, setMediaMatrix] = useState<RowMediaData[]>([]);
  const location = useLocation();
  const { setSimpleToasts } = useNotifications()!;
  const articleImages = [
    'https://s3-alpha-sig.figma.com/img/47dc/ab17/a9b4e1b6c063baecfeb0100e0edd31e0?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Cw7tBaikeVrUhN9eiipHeXB8Xitd1TtKWnIPFW-vVk7tJGR25BworwgNwOTX1vPi88AuEBQEQLq~GiZXAOZf6l3LSZ8ZwLxMfVFDS2uQBqQOtz6v3J~UCEoLuXDn6jKc2lSTPo~JtwdeI1AEXogzxvCTXKSwtDDBPt5bM7t4YrvyDdXmJmkBXf0gu53xMIYcKBxQG-93ER4EWsnApGu5Asq~sR71BIt-k~TMlDUt7CrPALC6cclZpJOwSwYIhMHnS25TH3iBvLzR1F1XdxcEXLzYLOrhMC7Th4~V50caNGBU6DnTC51Bp8VqbCEbSvtEitH~lL4C-Lv~kWQkpkvJxA__',
    'https://s3-alpha-sig.figma.com/img/71c2/37f2/b7e138e7c7cb74d5893251c52f10fa70?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eFTPROMh9iHNaPBGNn-jUC5pFX06FTuHo~SGo4Q-TgQrEPDVNEJnGx9AzWbvAyat09adjLA8rrm0Qu5Emuie5qJ7YeSHlRFRRfpvZWswHgV~UQ93U~VLPaT1P13XENbA3pR-lVvkdrIPXUvdtmoUrZluZjJHP-jXor-D-G1q8NbvF~N2RCXBI1r1PR67oJJH67ww8yQ7eJ7ahH7ATBxLjJZioL1khLbcgDD36M8JVumJCb8qwCaihdb73YQynT9MN1jbM~f9QZRql~8qNRUM771PdnYgqrh0oT12ZRWd33kZjHb4KN5qd~a5CTxdd7vurxrRbbxAAuSYlQ2Cbn9f1Q__',
    'https://s3-alpha-sig.figma.com/img/4fc8/96ae/e57c0b217158da041f5ce0c747432d4f?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=W7qMsULG3Cy7AafnebLv2xoo6ZusSMLhMU3HfdkJB6N-EBRmjp9YfQVa5jctbzKQgg9C1nPxx934RhQj9Bz356ysuv0s1alifv~vecfQl~Ae5cWd~6sGVj-3gcGW10kIL8fYGezbcOjCKSpZUEZDU5uP2AtCZRk9wcAb7NOSg2qftZsGbKQ4Uaq2ZGB50OjHNPvslwyKkhoLDaYps7gN5nCgta-cd9hPNNj-BFKISedzuU2Bb32mWJOnPy6ZnNKc6g5vqCyG9~IdCRaJdnYUFAbCA8KO7fedo6HcngJbWH83R53~32yeOPcxESJRUU7PsUsms8ASd-OF11aKKjWFdg__',
    'https://s3-alpha-sig.figma.com/img/54bd/8d71/cf7e8a5de34cf2033db1d2b27edd88d5?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=NsNaVeWwBrJ7XMlbj6E38fGWxxKUzs2X41A~9n4hmj9l912Su3YjzXSagS9bQvdRUHAqL~ly0ivdyebBwjxPqJrmjP0R9o8OZlxniYIfGYnI2r3YsjtnRHgbLl8XF7fflixsVvC8QBQ06gDrCFOIH2GcREjAeENxeIWqLy3lQL2fHtQzvXarGFQWyFo4GtUSMNMO~u27cCgxIEhTBQQpiRoGDrp2-qHlp9RcQV20MUR4-ySMiFYjCuegzJ7-ofb6VfGTvp5ltyE-ihcYZnnuRi8wmMdwZli8i9wRs6LttVujvnj9XXj9vgIlNr5pzE~U3G4ZGjo86wT0x~TkHSPGUw__',
    'https://s3-alpha-sig.figma.com/img/be2b/f2e7/3237f7b6ee706638109a22e1ca2a0ab1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SVXgYGoC~vi7HH5xv-GWMXBiYozNDeFLQlwHBKwm6a9PAPqh9QFXgyr0ZIOwZ4G2URSrC5fVJTKdDfZxQCPOFVMBS1vkP25b6LUTWX65nUT5r04kPI~zVKU4GWqZH9Qbb0sZvEZdt~6dxUl5j3ig4aBN30Y~ou~fgQJvYwx6LKJbj1dvFnAcW6zbFpVHqCcwD3FqcGlaoIotP6ysPkVReeJBUh72i6RPoyyoljQS1YHdk6k1pQPg5X--Kv1V5Gv2F9CfVpI9gLmcRa9qiK6q3NDGqxZ4m~SDLAF6RsN~bjX~nf~oGmc6Ip8rGZxunOdLZG21PKv2mSMPbYtrtKJI8w__',
  ];

  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ data: categoriesData, loading: categoriesLoading, error: categoriesError }] =
    useRequest<Categories[]>(endpoints.CATEGORIES, 'get', {
      authToken: credentialsInfo?.token,
    });

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categoriesData) {
      const { result } = categoriesData;
      setCategories(result);
    }
  }, [categoriesLoading, categoriesData, categoriesError, setSimpleToasts, setLoading]);

  const url = `${endpoints.ARTICLES}/${articleId}`;
  const [{ data, loading, error }, getDetail] = useRequest<Article>(
    url,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );
  const tagsArry = [
    {
      name: 'Art',
    },
    {
      name: 'Culture',
    },
    {
      name: 'MonaLisa',
    },
    {
      name: 'Portrait',
    },
    {
      name: 'DaVinci',
    },
    {
      name: 'Pictures',
    },
  ];
  const renderTagsItem = (item: { name: string }) => {
    return <div className="tag-text-view">{item.name}</div>;
  };
  const [{ loading: lastViewedLoading, error: lastViewedError }, updateLastViewed] =
    useRequest(
      url,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );
  useEffect(() => {
    setLoading(loading || lastViewedLoading);
  }, [loading, lastViewedLoading, setLoading]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (lastViewedError) {
      const message = getApiErrorMessage(lastViewedError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (data) {
      const { result } = data;
      setEditData(result);
    }
  }, [loading, data, error, lastViewedError, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (articleData) {
      setEditData(articleData);
    }
    if (!subpage) {
      const searchString = location.search;
      const params = new URLSearchParams(searchString);
      setArticleId(params.get('id') || '');
      getDetail();
      const formData = new FormData();
      formData.append('lastViewedDate', 'true');
      updateLastViewed({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    }
  }, [
    credentialsInfo?.token,
    articleData,
    subpage,
    getDetail,
    updateLastViewed,
    location,
  ]);

  useEffect(() => {
    if (editData) {
      const { media = [] } = editData!;
      const matrix = media.reduce(
        (acc: RowMediaData[], mediaFile: Media): RowMediaData[] => {
          const rows = [...acc];
          const key = `row-${rows.length + 1}`;
          const rowMedia = rows.pop() || { rowData: [], key };

          if (rowMedia.rowData.length < 2) {
            rowMedia.rowData.push(mediaFile);

            rows.push(rowMedia);
          } else {
            rows.push(rowMedia, { rowData: [mediaFile], key });
          }

          return rows;
        },
        [],
      );
      setMediaMatrix(matrix);
    }
  }, [editData]);
  return (
    <Container fluid className="article-detail-container p-0">
      <Header title="Article details" isBack />
      <Card className="article-body">
        <Row className="article-detail-header justify-content-between align-items-center">
          <div className="detaile-main-container-info">
            <div className="detaile-main-container">
              <div className="three-dot-and-culture-text-container">
                <div className="art-and-culture-text-style">Art & Culture</div>
                {true ? (
                  <DotDropDown title="">
                    <Dropdown.Item
                      onClick={() => {
                        setAddArticle(true);
                      }}
                    >
                      <img
                        className="edit-icon-image-style"
                        src={EditblackIcon}
                        alt="edit-black-icon"
                      />
                      <div className="edit-text-style">Edit</div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <img
                        className="edit-icon-image-style"
                        src={ArchiveIcon}
                        alt="edit-black-icon"
                      />
                      <div className="edit-text-style">Move to Archive</div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <img
                        className="edit-icon-image-style"
                        src={DraftIcon}
                        alt="edit-black-icon"
                      />
                      <div className="edit-text-style">Move to Draft</div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setModal(true)}>
                      <img
                        className="edit-icon-image-style"
                        src={DeleteIcon}
                        alt="edit-black-icon"
                      />
                      <div className="delete-text-style">Delete</div>
                    </Dropdown.Item>
                  </DotDropDown>
                ) : null}
              </div>

              <div className="art-and-culture-main-text">
                5 things you need to know about Google for Jobs new algorithm
              </div>

              <div className="image-text-container">
                <img
                  className="art-and-clture-name-view"
                  src="https://s3-alpha-sig.figma.com/img/1ef1/e96d/3d09cd9a0b4ee63cf8a2154261199c00?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=mpun6PI105nzbu0zw5okIPbR~e1XJmDraXuiqS6Dkj7la2lEf0DLIyUnfc8YI2mfYmWNp~JBGoMEhIJWLDfEoOrcyZB90~vHhwP050uptg5ijvfO-MYomYxNja56X9Zl3SZ3FnPTzt69qy0qTzt9i35OL-aUMScZtTzNrDHmO1O0Nl289m5K8XjKNDJjSrcDp80GMTY1EzoPAT~xEHnVv-FoSYW0x1SHsWH91EzBS1P8M7gn6vptD5EXdOKKEdGDe5Fl1YZntNdeaw9XPfsgCrLIxMAJsj5PKbi1Gk8Ila-VZUnFjnDm3QOZy4sGpzNcKCYFDY2ER3laMxoHgZ-43A__"
                  alt="art-and-clture"
                />
                <div>
                  <div className="name-text-style">Cameron Williamson</div>
                  <div className="date-and-readimg-time">
                    <div className="date-and-time-text-style">
                      Last updated 12 February, 2024 10:45 PM
                    </div>
                    <div className="dot-and-time-container">
                      <div className="dot-style" />
                      <div className="time-reading-view">15 min read</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="art-and-clture-info">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </div>
              <div className="article-image-container">
                <img
                  src={articleImages[0]}
                  alt="article-img"
                  className="article-main-image"
                />
                <div className="artical-more-image-container">
                  <div className="article-more-row-image-container">
                    <img
                      src={articleImages[1]}
                      alt="article-img"
                      className="article-more-image"
                    />
                    <img
                      src={articleImages[2]}
                      alt="article-img"
                      className="article-more-image"
                    />
                  </div>
                  <div className="article-more-row-image-container">
                    <img
                      src={articleImages[3]}
                      alt="article-img"
                      className="article-more-image"
                    />
                    <img
                      src={articleImages[4]}
                      alt="article-img"
                      className="article-more-image"
                    />
                  </div>
                </div>
              </div>
              <div className="tags-text-style">Tags</div>
              <div className="tag-arry-style">{tagsArry.map(renderTagsItem)}</div>
            </div>
          </div>
        </Row>
        <Row className="article-detail-content">
          <span className="pr-0 pl-0">{editData ? parse(editData.body) : ''}</span>
        </Row>
        <Row className="article-detail-images">
          {mediaMatrix.map(item => (
            <Row key={item.key}>
              {item.rowData.map(file => (
                <Col xs={12} md={6} className="pr-0 pt-2 pt-md-0">
                  <Row className="h-50 pr-0">
                    <Col xs={6} md={6} className="p-2 pt-0">
                      <Image
                        alt=""
                        src={file.uri}
                        className="d-inline-block align-top w-100 h-100"
                      />
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          ))}
        </Row>
        <Row className="justify-content-between align-items-center article-detail-footer d-none">
          <span className="w-auto">12/10/2022 2:35PM</span>
          <span className="w-auto">15 min read</span>
        </Row>
      </Card>
      <AddArticles
        article={
          {
            uuid: '2e1e6526-a393-40af-8f72-c54b816a3da2',
            name: 'Restaurant Recommendations',
            body: '<p><br></p>',
            createdAt: '2023-05-11T15:52:08.000Z',
            status: 'PUBLISHED',
            createdBy: {
              createdAt: '2023-02-07T01:47:46.000Z',
              updatedAt: '2023-02-07T01:47:46.000Z',
              firstName: 'Admin',
              lastName: 'User',
              email: 'sysadmin@sullivan.capital',
              phoneNumber: null,
              externalId: null,
              status: 'ACTIVE',
              deletedAt: null,
              roles: [
                {
                  uuid: 'c4e68b67-1b27-4291-9c9e-9c5e665f4a48',
                  createdAt: '2023-02-07T01:47:46.000Z',
                  updatedAt: '2023-02-07T01:47:46.000Z',
                  name: 'Administrator',
                  role: 'admin',
                  description: null,
                  permissions: ['view', 'edit', 'create'],
                },
              ],
            },
            tags: [
              'Salem',
              'Breakfast',
              'Lunch',
              'Dinner',
              'Bars',
              'Kid-Friendly',
              'Coffee',
            ],
            categories: [
              {
                uuid: '36b6f564-6a5e-437c-a005-a15953980836',
                createdAt: '2023-02-07T16:23:52.000Z',
                name: 'Guest Management',
                description: '',
                articles: [],
              },
            ],
            media: [],
          } as Article
        }
        show={addArticle}
        key={JSON.stringify(actionArticle)}
        categories={categories}
        onSave={() => setAddArticle(false)}
        onHide={() => setAddArticle(false)}
      />
      <DeleteModal
        show={modal}
        onDelete={() => setModal(false)}
        onHide={() => setModal(false)}
        title="Delete Article"
        description="Are you sure you want to delete this article?"
      />
    </Container>
  );
};
