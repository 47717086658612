import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import { LoadingContext } from '../../../modules/context/loadingContext';

export const LoadingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingClass, setLoadingClass] = useState('loading-page-v');

  useEffect(() => {
    if (
      window.innerHeight <
      document.getElementsByClassName('mainContainer')[0].clientHeight
    ) {
      setLoadingClass('loading-page-h');
    } else {
      setLoadingClass('loading-page-v');
    }
  }, [loading]);

  const loadingContext = useMemo(() => {
    return { loading, setLoading };
  }, [loading, setLoading]);

  return (
    <LoadingContext.Provider value={loadingContext}>
      <LoadingOverlay
        active={loading}
        text="Wait while we get things ready."
        className={loadingClass}
      >
        {children}
      </LoadingOverlay>
    </LoadingContext.Provider>
  );
};
