import { FC } from 'react';
import { Container } from 'react-bootstrap';

import './styles/styles.css';

interface SearchResultItem {
  data: string;
  section: string;
  url: string;
}

interface SearchResultProps {
  result: SearchResultItem[];
  show: boolean;
}

export const SearchResult: FC<SearchResultProps> = ({ result, show }) => {
  return (
    <Container className={show === true ? 'search-result-card' : 'd-none'}>
      <label className="search-result-title">Search Results</label>
      <div className="search-content">
        <div className="d-flex justify-content-between search-result-item">
          <label className="search-result-item-title">12313f0d</label>
          <label className="search-result-item-section">Reservation ID</label>
        </div>
        <div className="d-flex justify-content-between search-result-item">
          <label className="search-result-item-title">12313f0ddfdf</label>
          <label className="search-result-item-section">Reservation TT ID</label>
        </div>
        <div className="d-flex justify-content-between search-result-item">
          <label className="search-result-item-title">12313f0d</label>
          <label className="search-result-item-section">Reservation ID</label>
        </div>
      </div>
    </Container>
  );
};
