import { FC, useState, useCallback, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Overlay, Popover } from 'react-bootstrap';
import './styles/styles.css';
import Header from '../../Header';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import DateIcon from '../../../assets/date.svg';
import UnCheckRadioIcon from '../../../assets/uncheck-radio-icon.svg';
import CheckRadioIcon from '../../../assets/check-radio-icon.svg';
import ExpertIcon from '../../../assets/expert.svg';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { monthList, yearList } from '../../../constants/generalData';
import { RevenueReportTable } from '../../Tables/RevenueReports';

export const RevenueReports: FC = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [recentData, setRecentData] = useState([
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
    {
      months: 'June, 2024',
      room_rev: '$17.607.73',
      Package_rev: '$2.300.00',
      total_rev: '$19.907.73',
      taxes: '$1.500.00',
      fees: '$1.500.00',
      total: '$5.113.00',
    },
  ]);
  const [view, setView] = useState<string>('by Months');
  const [viewFinal, setViewFinal] = useState<string>('by Months');
  const viewArray = ['by Quarter', 'by Months'];
  const [dateShow, setDateShow] = useState(false);
  const dateTarget = useRef(null);
  const [viewShow, setViewShow] = useState(false);
  const viewTarget = useRef(null);
  const fromMonth = useRef(null);
  const toMonth = useRef(null);
  const fromYear = useRef(null);
  const toYear = useRef(null);
  const [fromMonthShow, setFromMonthShow] = useState(false);
  const [toMonthShow, setToMonthShow] = useState(false);
  const [fromYearShow, setFromYearShow] = useState(false);
  const [toYearShow, setToYearShow] = useState(false);
  const [fromMonthValue, setFromMonthValue] = useState('September');
  const [toMonthValue, setToMonthValue] = useState('June');
  const [fromYearValue, setFromYearValue] = useState('2021');
  const [toYearValue, setToYearValue] = useState('2024');
  const [fromMonthValueFinal, setFromMonthValueFinal] = useState<string>('September');
  const [toMonthValueFinal, setToMonthValueFinal] = useState<string>('June');
  const [fromYearValueFinal, setFromYearValueFinal] = useState<string>('2021');
  const [toYearValueFinal, setToYearValueFinal] = useState<string>('2024');

  const refreshHandler = useCallback(async () => {
    console.log('test');
  }, []);

  const onViewItemClick = (reportItem: string) => {
    setView(reportItem);
  };

  const renderViewItem = (reportItem: string) => {
    const isSelected = view === reportItem;
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onViewItemClick(reportItem)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckRadioIcon : UnCheckRadioIcon}
          alt="check-icon"
        />
        <div style={{ textTransform: 'none' }}>{reportItem}</div>
      </div>
    );
  };

  const onCancleView = () => {
    setView(viewFinal);
    setViewShow(false);
  };

  const onApplyView = () => {
    if (view) {
      setViewFinal(view);
      setViewShow(false);
    }
  };

  const onCancledate = () => {
    setDateShow(false);
    setFromMonthValue(fromMonthValueFinal);
    setToMonthValue(toMonthValueFinal);
    setFromYearValue(fromYearValueFinal);
    setToYearValue(toYearValueFinal);
  };

  const onApplyDate = () => {
    setDateShow(false);
    if (fromMonthValue) {
      setFromMonthValueFinal(fromMonthValue);
    }
    if (toMonthValue) {
      setToMonthValueFinal(toMonthValue);
    }
    if (fromYearValue) {
      setFromYearValueFinal(fromYearValue);
    }
    if (toYearValue) {
      setToYearValueFinal(toYearValue);
    }
  };

  const onFromMonthClick = () => {
    setFromMonthShow(true);
  };

  const onFromYearClick = () => {
    setFromYearShow(true);
  };

  const onToMonthClick = () => {
    setToMonthShow(true);
  };

  const onToYearClick = () => {
    setToYearShow(true);
  };

  const onMonthClick = (isFrom: boolean, month: string) => {
    setFromMonthShow(false);
    setToMonthShow(false);
    if (isFrom) {
      setFromMonthValue(month);
    } else {
      setToMonthValue(month);
    }
  };

  const onYearClick = (isFrom: boolean, year: string) => {
    setFromYearShow(false);
    setToYearShow(false);
    if (isFrom) {
      setFromYearValue(year);
    } else {
      setToYearValue(year);
    }
  };

  const renderDateByYear = () => {
    return (
      <div className="drop-down-container-status">
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">From</div>
              <div
                onClick={onFromYearClick}
                ref={fromYear}
                className="year-calender-container "
              >
                <div className="year-text-style">{fromYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">To</div>
              <div
                onClick={onToYearClick}
                ref={toYear}
                className="year-calender-container "
              >
                <div className="year-text-style">{toYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="apply-and-cancle-container">
          <div onClick={onCancledate} className="cancle-text-container">
            Cancel
          </div>
          <div onClick={onApplyDate} className="apply-text-container">
            Apply
          </div>
        </div>
      </div>
    );
  };

  const renderMonthList = (isFrom: boolean) => {
    return (
      <div style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
        {monthList.map(item => (
          <div
            onClick={() => onMonthClick(isFrom, item)}
            className="dropdown-item-calendar-picker"
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderYearList = (isFrom: boolean) => {
    return (
      <div style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
        {yearList.map(item => (
          <div
            onClick={() => onYearClick(isFrom, item.toString())}
            className="dropdown-item-calendar-picker"
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderDateByMonth = () => {
    return (
      <div className="drop-down-container-status">
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">From</div>
              <div
                ref={fromMonth}
                className="year-calender-container by-month-container"
                onClick={() => onFromMonthClick()}
              >
                <div className="year-text-style">{fromMonthValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromMonthShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div
                onClick={onFromYearClick}
                ref={fromYear}
                className="year-calender-container by-month-text"
              >
                <div className="year-text-style">{fromYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">To</div>
              <div
                ref={toMonth}
                onClick={() => onToMonthClick()}
                className="year-calender-container by-month-container "
              >
                <div className="year-text-style">{toMonthValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toMonthShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div
                onClick={onToYearClick}
                ref={toYear}
                className="year-calender-container by-month-text"
              >
                <div className="year-text-style">{toYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="apply-and-cancle-container">
          <div onClick={onCancledate} className="cancle-text-container">
            Cancel
          </div>
          <div onClick={onApplyDate} className="apply-text-container">
            Apply
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title="Revenue Reports — 75" />
      <div className="artical-text-main-container">
        <div className="artical-dropdown-container ">
          <div
            ref={dateTarget}
            onClick={() => setViewShow(!viewShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              <div className="view-text-style">View: </div>
              {viewFinal}
            </div>
            <img src={viewShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div className="catagory-container">
            <div
              ref={viewTarget}
              onClick={() => setDateShow(!dateShow)}
              className="date-rage-picker-text-container"
            >
              <img src={DateIcon} alt="date" />
              <div className="category-text-style">
                {viewFinal === 'by Months'
                  ? `${fromMonthValueFinal}, ${fromYearValueFinal} — ${toMonthValueFinal}, ${toYearValueFinal}`
                  : `${fromYearValueFinal} — ${toYearValueFinal}`}
              </div>
              <img
                className="drop-down-icon-style"
                src={dateShow ? DropDownOpenIcon : DropDownIcon}
                alt="drop-down"
              />
            </div>
          </div>
        </div>
        <div className="add-artical-container">
          <img src={ExpertIcon} alt="plus-icon" />
          <div className="add-artical-text-style">Export</div>
        </div>
      </div>

      <Overlay
        onHide={() => setDateShow(false)}
        target={viewTarget.current}
        show={dateShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {viewFinal === 'by Months' ? renderDateByMonth() : renderDateByYear()}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setViewShow(false)}
        target={dateTarget.current}
        rootClose
        show={viewShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {viewArray.map(renderViewItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleView} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyView} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setFromMonthShow(false)}
        target={fromMonth.current}
        rootClose
        show={fromMonthShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderMonthList(true)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setToMonthShow(false)}
        target={toMonth.current}
        rootClose
        show={toMonthShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderMonthList(false)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setFromYearShow(false)}
        target={fromYear.current}
        rootClose
        show={fromYearShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">{renderYearList(true)}</Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setToYearShow(false)}
        target={toYear.current}
        rootClose
        show={toYearShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderYearList(false)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <RevenueReportTable
        searchString=""
        tableData={recentData}
        refreshHandler={refreshHandler}
      />
      <DeleteModal
        show={modal}
        onDelete={() => setModal(false)}
        onHide={() => setModal(false)}
        title="Delete Article"
        description="Are you sure you want to delete this article?"
      />
      <div className="bottom-total-view">
        <div className="total-text-view">TOTAL</div>
        <div className="ammount-text-style">$259.826.80</div>
        <div className="ammount-text-style">$287.426.80</div>
        <div className="ammount-text-style">$18.000.00</div>
        <div className="ammount-text-style">$1.500.00</div>
        <div className="ammount-text-style">$1.500.00</div>
        <div className="ammount-text-style">$61.356.00</div>
      </div>
    </Container>
  );
};
