import { useState, useEffect, ChangeEvent, FC } from 'react';
import { Form } from 'react-bootstrap';

import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { ModalType } from '../../../modules/enums/types';
import { Roles, Users } from '../../../modules/interfaces';
import { UserStatus } from '../../../modules/enums/status';
import { UserAction } from '../../../modules/enums/actions';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { ModalComponent } from '../UserModals';
import './styles/index.css';

export interface UserUpdateRequest {
  firstName: string;
  lastName: string;
  role: string;
}
export interface UserStatusRequest {
  status: string;
}
export interface UserManagmentModalData {
  handleClose: () => void;
  handleRefresh: () => void;
  modalType: ModalType;
  modalName: string;
  cancelButton: string;
  saveButton: string;
  roles?: Roles[];
  data?: Users;
}

interface UserModalProps {
  type: ModalType;
}

interface UserModalBodyProps extends UserModalProps {
  firstName: string;
  lastName: string;
  email: string;
  roleId?: string;
  roles?: Roles[];
  onChangeFirstName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeLastName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeRole: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const ModalBody: FC<UserModalBodyProps> = ({
  type,
  firstName,
  lastName,
  email,
  roleId,
  roles = [],
  onChangeFirstName,
  onChangeLastName,
  onChangeRole,
}) => {
  let component: JSX.Element | null = null;

  if (type === ModalType.EDIT) {
    component = (
      <Form>
        <Form.Group>
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            defaultValue=""
            value={firstName}
            onChange={onChangeFirstName}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            defaultValue=""
            value={lastName}
            onChange={onChangeLastName}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Form.Select value={roleId} onChange={onChangeRole}>
            {roles.map(role => {
              return <option value={role.uuid}>{role.name}</option>;
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="formEditUser">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            defaultValue={email}
            disabled
          />
        </Form.Group>
      </Form>
    );
  } else if (type === ModalType.RESET_PASSWORD) {
    component = (
      <Form>
        <Form.Group controlId="formEditUser">
          <Form.Label>User Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            defaultValue="AndreySpencer1234@gmail.com"
          />
        </Form.Group>
      </Form>
    );
  }

  return component;
};
const ModalTitle: FC<UserModalProps> = ({ type }) => {
  let component: JSX.Element | null = null;

  if (type === ModalType.EDIT) {
    component = (
      <>
        Edit User <br /> <span>Edit this User</span>
      </>
    );
  } else if (type === ModalType.SUSPEND_USER) {
    component = (
      <>
        Suspend User <br />
        <span>Are you sure you want to suspend this user?</span>
      </>
    );
  } else if (type === ModalType.ACTIVATE_USER) {
    component = (
      <>
        Activate User <br />
        <span>Are you sure you want to activate this user?</span>
      </>
    );
  } else if (type === ModalType.RESET_PASSWORD) {
    return <>Reset Password</>;
  } else if (type === ModalType.DELETE) {
    component = (
      <>
        Delete User <br />
        <span className="delete-message">Are you sure you want to delete this user?</span>
      </>
    );
  }

  return component;
};

export const UserManagmentOption: FC<UserManagmentModalData> = ({
  modalName,
  cancelButton,
  saveButton,
  data,
  roles,
  modalType,
  handleClose,
  handleRefresh,
}) => {
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;
  const [showModal, setShowModal] = useState('');
  const [footerBtn1, setFooterBtn1] = useState('');
  const [footerBtn2, setFooterBtn2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState('');
  const { setSimpleToasts } = useNotifications()!;

  const url = `${endpoints.USERS}/${email}`;
  const [{ loading: updateLoading, data: updateResult, error: updateError }, updateUser] =
    useRequest<UserUpdateRequest>(
      url,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  useEffect(() => {
    setShowModal(modalName);
    setFooterBtn1(cancelButton);
    setFooterBtn2(saveButton);

    if (data) {
      const [selectedRole] = data.roles;
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);

      if (selectedRole) {
        setRoleId(selectedRole.uuid);
      }
    }
  }, [setShowModal, cancelButton, saveButton, data, modalName]);

  useEffect(() => {
    setLoading(updateLoading);
  }, [updateLoading, setLoading]);

  useEffect(() => {
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateResult) {
      setSimpleToasts({ type: 'info', message: 'User Updated successly!', show: true });
      handleClose();
      handleRefresh();
    }
  }, [updateResult, updateError, handleClose, handleRefresh, setSimpleToasts]);

  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.currentTarget.value);
  };

  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.currentTarget.value);
  };

  const onChangeRole = (e: ChangeEvent<HTMLSelectElement>) => {
    setRoleId(e.currentTarget.value);
  };

  let modalClass = '';

  if (
    showModal === UserAction.DELETE_USER ||
    showModal === UserAction.SUSPEND_USER ||
    showModal === UserAction.ACTIVATE_USER
  ) {
    modalClass = 'suspend--user-modal';
  } else if (showModal === UserAction.RESET_PASSWORD) {
    modalClass = 'resetpass--user-modal';
  } else if (showModal === UserAction.EDIT_USER) {
    modalClass = 'user--role-modal new-role-modal edit--user-modal';
  }

  return (
    <div className="user--management-opt">
      <ModalComponent
        show={!!showModal}
        className={modalClass}
        handleClose={() => {
          setShowModal('');
          handleClose();
        }}
        handleSave={() => {
          setShowModal('');
          if (modalType === ModalType.EDIT) {
            const updateData: UserUpdateRequest = {
              firstName,
              lastName,
              role: roleId,
            };
            updateUser({ data: updateData });
          } else if (modalType === ModalType.SUSPEND_USER || ModalType.ACTIVATE_USER) {
            const newStatus =
              data?.status === UserStatus.SUSPENDED
                ? UserStatus.ACTIVE
                : UserStatus.SUSPENDED;
            const suspendData: UserStatusRequest = {
              status: newStatus,
            };
            updateUser({ data: suspendData });
          }
        }}
        aria-labelledby="contained-modal-title-vcenter"
        title={showModal}
        cancelButton={footerBtn1}
        saveButton={footerBtn2}
      >
        <ModalBody
          type={modalType}
          firstName={firstName}
          lastName={lastName}
          onChangeFirstName={onChangeFirstName}
          onChangeLastName={onChangeLastName}
          onChangeRole={onChangeRole}
          email={email}
          roleId={roleId}
          roles={roles}
        />
        <ModalTitle type={modalType} />
      </ModalComponent>
    </div>
  );
};
