import { ChangeEvent, FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

type AddCategoryProp = {
  show: boolean;
  onHide: () => void;
  onSave: (category?: string) => void;
  category: string;
};

const AddCategory: FC<AddCategoryProp> = ({ show, onHide, onSave, category }) => {
  const [categoryText, setCategoryText] = useState(category);

  useEffect(() => {
    setCategoryText(category);
  }, [category]);

  const onCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryText(event.target.value);
  };

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">
          {category ? 'Edit Category' : 'Add Category'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="category-name-text-view">Category name</div>
        <input
          className="add-article-input-style"
          aria-describedby="noteHelp"
          placeholder="Category name here"
          onChange={onCategoryChange}
          value={categoryText}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button
          className="edit-guest-modal-button-save"
          onClick={() => onSave(categoryText)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCategory;
