import * as React from 'react';

type Props = {
  color: string;
};

function DropDownStatus({ color }: Props) {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_4542_13268)">
        <path
          d="M9 4.5a.5.5 0 01.391.811l-.038.043-3 3a.5.5 0 01-.66.041l-.047-.041-3-3-.041-.048-.027-.038-.027-.048-.009-.018-.013-.034-.016-.054-.005-.026-.005-.03-.002-.029v-.058l.002-.03.005-.03.005-.025.016-.054.013-.034.035-.066.033-.045.036-.04.047-.042.039-.027.048-.027.018-.008.033-.014.054-.016.027-.005.03-.005.028-.002L9 4.5z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4542_13268">
          <path fill="#fff" d="M0 0H12V12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DropDownStatus;
