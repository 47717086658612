import { FC, useState, useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { BadgeCellRenderer, DataTable } from '../DataTable';
import { config } from '../../../modules/config';
import { Article } from '../../../modules/interfaces';
import DeleteIcon from '../../../assets/delete-icon.svg';
import EditblackIcon from '../../../assets/edit-icon.svg';
import VerticalDotMenuIcon from '../../../assets/vertical-dot-menu.svg';
import ArchiveIcon from '../../../assets/archive.svg';
import DraftIcon from '../../../assets/draft.svg';
import { routes } from '../../../modules/mappers/urls';

type ArticleTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
  onEditArticle: (article?: Article) => void;
  onDeleteArticle: (article?: Article) => void;
};

export const ArticleTable: FC<ArticleTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  onEditArticle,
  onDeleteArticle,
}: ArticleTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const renderMenuIcon = (params: RowClickedEvent<Article>) => {
    return (
      <div className="menu-icon-container-style">
        <OverlayTrigger
          trigger="click"
          placement="left"
          rootClose
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <div
                  onClick={e => {
                    document.body.click();
                    onEditArticle(params.data);
                  }}
                  style={{ paddingBottom: '8px' }}
                  className="edit-damage-report-container"
                >
                  <img
                    className="edit-icon-image-style"
                    src={EditblackIcon}
                    alt="edit-black-icon"
                  />
                  <div className="edit-text-style">Edit</div>
                </div>
                <div
                  onClick={e => {
                    document.body.click();
                  }}
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                  className="edit-damage-report-container"
                >
                  <img
                    className="edit-icon-image-style"
                    src={ArchiveIcon}
                    alt="edit-black-icon"
                  />
                  <div className="edit-text-style">Move to Archive</div>
                </div>
                <div
                  onClick={e => {
                    document.body.click();
                  }}
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                  className="edit-damage-report-container"
                >
                  <img
                    className="edit-icon-image-style"
                    src={DraftIcon}
                    alt="edit-black-icon"
                  />
                  <div className="edit-text-style">Move to Draft</div>
                </div>
                <div
                  style={{ paddingTop: '8px' }}
                  className="edit-damage-report-container"
                  onClick={e => {
                    document.body.click();
                    onDeleteArticle(params.data);
                  }}
                >
                  <img
                    className="edit-icon-image-style"
                    src={DeleteIcon}
                    alt="edit-black-icon"
                  />
                  <div className="delete-text-style">Delete</div>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div>
            <img src={VerticalDotMenuIcon} alt="vertical-icon" />
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const [columns] = useState<ColDef<Article>[]>([
    { field: 'name', headerName: 'ARTICLE TITLE', width: 150 },
    {
      valueGetter: params => {
        const { data: article } = params;
        const resource = article?.categories;
        if (resource) {
          return resource[0].name;
        }
        return '';
      },

      headerName: 'CATEGORY',
      width: 100,
      sortable: true,
    },
    {
      valueGetter: params => {
        const { data: article } = params;
        const customer = article?.createdBy;
        const name = customer ? `${customer.firstName} ${customer.lastName}` : '-';
        return name;
      },
      headerName: 'AUTHOR',
      width: 100,
      sortable: true,
    },

    {
      field: 'createdAt',
      headerName: 'CREATION DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<Article, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value).toFormat('MM-dd-yyyy');
        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      width: 100,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      sortable: true,
      cellRenderer: BadgeCellRenderer,
      width: 50,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: renderMenuIcon,
      width: 1,
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
      pagination
      paginationPageSize={config.tables.reservations.paginationSize}
      onRowClicked={(e: RowClickedEvent<Article>) => {
        const focusCell = e.api.getFocusedCell();
        if (focusCell?.column.getUserProvidedColDef()?.field === '') {
          return;
        }
        navigate({ pathname: routes.ARTICLES_DETAILS, search: `?id=${e.data?.uuid}` });
      }}
    />
  );
};
