import { FC, useMemo, useEffect, useState } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import { capitalCase } from 'case-anything';
import SideArrow from '../../../assets/side-arrow.svg';
import DownArrow from '../../../assets/down-arrow-bottom.svg';
import VarticalThreeDot from '../../../assets/vartical-three-dot.svg';
import RightSideArrow from '../../../assets/right-side-arrow.svg';
import SideArrowDisable from '../../../assets/side-arrow-disable.svg';
import RightSideArrowDisable from '../../../assets/right-side-arrow-disable.svg';

import { CurrencyInput } from '../../Inputs/Currency';
import {
  statusColorMapper,
  statusTextColorMapper,
} from '../../../modules/mappers/statusColorMapper';
import './styles/styles.css';

export interface BaseGridProps<T = any> extends AgGridReactProps<T> {
  refreshHandler?: () => Promise<void>;
  searchString?: string;
  changeState: number;
  gridRef: React.RefObject<AgGridReact<T>>;
}

export const CurrencyCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;

  return <CurrencyInput displayType="text" value={value} />;
};

export const ImageLabelCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;
  return (
    <>
      <Image alt="" src={value.img} width="40" height="40" className="ag-cell-img" />
      <span>{value.name}</span>
    </>
  );
};

export const BadgeCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;

  return (
    <div
      className="status-badge"
      style={{
        backgroundColor: statusColorMapper[value.toUpperCase() as string],
        color: statusTextColorMapper[value.toUpperCase() as string],
      }}
    >
      {capitalCase(value)}
    </div>
  );
};

export const TextWithTooltipCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value = '-' } = params;
  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <strong>{value}</strong>
        </Tooltip>
      }
    >
      <div>{value}</div>
    </OverlayTrigger>
  );
};

export const DataTable: FC<BaseGridProps> = <T,>(props: BaseGridProps<T>) => {
  const {
    rowData,
    refreshHandler,
    paginationPageSize = 1,
    searchString,
    gridRef,
    changeState,
    pagination,
    ...rest
  } = props;
  const memoizedData = useMemo(() => rowData, [rowData]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);
  const rowHeight = 54;
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [changeState, gridRef]);

  useEffect(() => {
    const resize = () => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit();
        return true;
      }
      return false;
    };
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [gridRef]);

  useEffect(() => {
    if (gridRef.current && searchString) {
      gridRef.current!.api.setQuickFilter(searchString || '');
    }
  }, [searchString, gridRef]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.sizeColumnsToFit();
      setTotalRecord(gridRef.current.api.getDisplayedRowCount());
      setTotalPage(gridRef.current.api.paginationGetTotalPages());
      setCurrentPage(0);
    }
  }, [gridRef, rowData]);

  const onClickNextPage = () => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToNextPage();
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage());
    }
  };

  const onClickPreviousPage = () => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToPreviousPage();
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage());
    }
  };

  const onClickFirstPage = () => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToFirstPage();
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage());
    }
  };

  const onClickLastPage = () => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToLastPage();
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage());
    }
  };
  const showCount = paginationPageSize * (currentPage + 1);
  const currentShowingStart = paginationPageSize * currentPage + 1;
  const currentShowingEnd = showCount > totalRecord ? totalRecord : showCount;
  return (
    <Container className="ag-theme-alpine p-0" fluid>
      <AgGridReact
        className="w-100 border-0 data-grid data-table-list-container"
        ref={gridRef}
        rowHeight={rowHeight}
        rowData={memoizedData}
        paginationPageSize={paginationPageSize}
        domLayout="autoHeight"
        {...rest}
      />
      {pagination && (
        <div className="pagination-section">
          <span className="pagination-title">
            Results:
            <div className="pagination-title-bold">
              {currentShowingStart} - {currentShowingEnd} of {totalRecord}
            </div>
          </span>
          {totalPage >= 2 ? (
            <div className="bottom-row-continer">
              <div className="number-and-text-continer">
                <div className="text-bottom-view">25</div>
                <img className="arrow-style " src={DownArrow} alt="down-arrow" />
              </div>
              <div onClick={onClickPreviousPage} className="bottom-row-continer">
                <img
                  className="side-arrow-style"
                  src={currentPage === 0 ? SideArrowDisable : SideArrow}
                  alt="side-arrow"
                />
                <div
                  className={
                    currentPage === 0 ? 'previous-number-style' : 'two-text-style'
                  }
                >
                  Prev
                </div>
              </div>
              {currentPage + 1 !== totalPage ? (
                <div className="bottom-row-continer">
                  {currentPage === 0 ? (
                    <div className="bottom-row-continer">
                      <div className="number-continer">
                        <div className="one-text-style">{currentPage + 1}</div>
                      </div>
                      <div onClick={onClickNextPage} className="two-text-style">
                        {currentPage + 2}
                      </div>
                    </div>
                  ) : (
                    <div className="bottom-row-continer">
                      <div onClick={onClickPreviousPage} className="two-text-style">
                        {currentPage}
                      </div>
                      <div className="number-continer">
                        <div className="one-text-style">{currentPage + 1}</div>
                      </div>
                    </div>
                  )}
                  <img
                    className="side-arrow-style"
                    src={VarticalThreeDot}
                    alt="vartical-three-dot"
                  />
                  <div onClick={onClickLastPage} className="two-text-style">
                    {totalPage}
                  </div>
                </div>
              ) : (
                <div className="bottom-row-continer">
                  <div onClick={onClickPreviousPage} className="two-text-style">
                    1
                  </div>
                  <img
                    className="side-arrow-style"
                    src={VarticalThreeDot}
                    alt="vartical-three-dot"
                  />
                  <div onClick={onClickPreviousPage} className="two-text-style">
                    {currentPage}
                  </div>
                  <div className="number-continer" onClick={onClickNextPage}>
                    <div className="one-text-style">{totalPage}</div>
                  </div>
                </div>
              )}
              <div className="bottom-row-continer" onClick={onClickNextPage}>
                <div
                  className={
                    currentPage + 1 !== totalPage
                      ? 'two-text-style'
                      : 'previous-number-style'
                  }
                >
                  Next
                </div>
                <img
                  className="side-arrow-style"
                  src={
                    currentPage + 1 !== totalPage ? RightSideArrow : RightSideArrowDisable
                  }
                  alt="vartical-three-dot"
                />
              </div>
            </div>
          ) : (
            <div className="bottom-row-continer">
              <div className="number-and-text-continer">
                <div className="text-bottom-view">25</div>
                <img className="arrow-style " src={DownArrow} alt="down-arrow" />
              </div>
              <img className="side-arrow-style" src={SideArrowDisable} alt="side-arrow" />
              <div className="previous-number-style">Prev</div>
              <div className="number-continer">
                <div className="one-text-style">{currentPage + 1}</div>
              </div>
              <div className="previous-number-style">Next</div>
              <img
                className="side-arrow-style"
                src={RightSideArrowDisable}
                alt="vartical-three-dot"
              />
            </div>
          )}
        </div>
      )}
    </Container>
  );
};
