import { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import './styles/styles.css';
import { RoomsTable } from '../../Tables/Rooms';
import Header from '../../Header';

export const Rooms: FC = () => {
  return (
    <Container fluid className="reservation-list-container">
      <Header title="Rooms" />
      <RoomsTable />
    </Container>
  );
};
