import { FC } from 'react';
import DropdownButton, { DropdownButtonProps } from 'react-bootstrap/DropdownButton';
import './styles/styles.css';

export const DotDropDown: FC<DropdownButtonProps> = ({ className, ...rest }) => {
  return (
    <DropdownButton
      id="dropdown-basic-button"
      className="page-dropdown w-auto article-card-dropdown p-0"
      drop="down"
      {...rest}
    />
  );
};
