import { FunctionComponent, useState } from 'react';

import './styles/styles.css';

type Pref = {
  title: string;
  isSelect: boolean;
};

type ReservationPreferanceProps = {
  isEditable?: boolean;
  dietaryPref: Array<Pref>;
  roomPref: Array<Pref>;
  onSelectPref?: (title: string) => void;
  onSelectRoomPref?: (title: string) => void;
};

const ReservationPreferance: FunctionComponent<ReservationPreferanceProps> = ({
  isEditable,
  dietaryPref,
  roomPref,
  onSelectPref,
  onSelectRoomPref,
}) => {
  const renderRoomPrefeItem = (item: Pref) => {
    return (
      <div
        className={
          item.isSelect ? 'preferance-item preferance-item-select' : 'preferance-item'
        }
        onClick={
          isEditable ? () => onSelectRoomPref && onSelectRoomPref(item.title) : undefined
        }
        style={isEditable ? { cursor: 'pointer' } : undefined}
      >
        {item.title}
      </div>
    );
  };

  const renderPrefeItem = (item: Pref) => {
    return (
      <div
        className={
          item.isSelect ? 'preferance-item preferance-item-select' : 'preferance-item'
        }
        onClick={isEditable ? () => onSelectPref && onSelectPref(item.title) : undefined}
        style={isEditable ? { cursor: 'pointer' } : undefined}
      >
        {item.title}
      </div>
    );
  };

  return (
    <div className="preferences-container" style={isEditable ? { border: 0 } : undefined}>
      <div className="preferance-title">Dietary preferences</div>
      <div className="preferance-list">{dietaryPref.map(renderPrefeItem)}</div>
      <div className="preferance-title" style={{ marginTop: '40px' }}>
        Room preferences
      </div>
      <div className="preferance-list">{roomPref.map(renderRoomPrefeItem)}</div>
    </div>
  );
};

export default ReservationPreferance;
