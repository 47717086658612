export const messagingEvents = {
  MESSAGE: {
    INBOUND: 'message-inbound',
    OUTBOUND: 'message-outbound',
  },
  CHANNEL_NAME: 'guest-messaging',
};

export interface MessageInboundData {
  messageId: string;
  text: string;
  sender: string;
  receiver: string;
  messageType: 'text' | 'image';
  status: string;
  guestId: string;
  isActive: boolean;
  timestamp: string;
  imageUrl?: string;
  channel: string;
  guestPhoneNumber: string;
}
