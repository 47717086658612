import { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DeleteInfoIcon from '../../../assets/delete-info.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

type DeleteModalProp = {
  show: boolean;
  onHide: () => void;
  title: string;
  description: string;
  onDelete: () => void;
};

const DeleteModal: FC<DeleteModalProp> = ({
  show,
  onHide,
  onDelete,
  title,
  description,
}) => {
  return (
    <Modal
      className="modal-delete-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <img className="delete-info-image-style" src={DeleteInfoIcon} alt="delete-info" />
        <div className="delete-info-text-style">{title}</div>
        <div className="delete-info-remove-text-style">{description}</div>
        <div className="delete-btn-container">
          <div className="delete-btn-cancal-style" onClick={onHide}>
            Cancel
          </div>
          <div className="delete-info-btn-detele-style" onClick={onDelete}>
            Delete
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
