import { ChangeEvent, FC, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { useNotifications } from '../../../modules/hooks';
import { OptionItem } from '../../../modules/interfaces';

import './styles/index.css';

export interface NewRoleModalData {
  showAddRole: boolean;
  handleClose: () => void;
  handleSave: (
    name: string,
    permissions: OptionItem[],
    assignedUser: OptionItem[],
  ) => void;
}

export const RoleOptions = [
  { label: 'view', value: 'view' },
  { label: 'edit', value: 'edit' },
  { label: 'create', value: 'create' },
];

export const NewRoleModal: FC<NewRoleModalData> = ({
  showAddRole,
  handleClose,
  handleSave,
}) => {
  const assignedUser: OptionItem[] = [];

  const assignedproperties: OptionItem[] = [];

  const [permissionsSelected, setPermissionsSelected] = useState([]);
  const [assignedUserSelected, setAssignedUserSelected] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [assignedPropertiesSelected, setAssignedPropertiesSelected] = useState([]);

  const { setSimpleToasts } = useNotifications()!;

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.currentTarget.value);
  };

  const hanleSave = () => {
    if (roleName === '') {
      setSimpleToasts({ type: 'danger', message: 'Empty role name!', show: true });
      return;
    }
    if (permissionsSelected.length === 0) {
      setSimpleToasts({
        type: 'danger',
        message: 'Please select permission!',
        show: true,
      });
      return;
    }
    handleSave(roleName, permissionsSelected, assignedUserSelected);
    handleClose();
  };

  return (
    <div>
      <Modal
        className="user--role-modal new-role-modal edit--user-modal"
        show={showAddRole}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            New Role <br /> <span>Create a new Role</span>{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Name of Role"
                onChange={onChangeName}
              />
            </Form.Group>
            <Form.Group className="permission-multiselector">
              <Form.Label>Permissions</Form.Label>
              <MultiSelect
                options={RoleOptions}
                value={permissionsSelected}
                onChange={setPermissionsSelected}
                labelledBy="Select"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Assigned Users</Form.Label>
              <MultiSelect
                options={assignedUser}
                value={assignedUserSelected}
                onChange={setAssignedUserSelected}
                labelledBy="Select"
              />
            </Form.Group>
            <Form.Group className="d-none">
              <Form.Label>Assigned Properties</Form.Label>
              <Form.Label>Assigned Users</Form.Label>
              <MultiSelect
                options={assignedproperties}
                value={assignedPropertiesSelected}
                onChange={setAssignedPropertiesSelected}
                labelledBy="Select"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-between">
          <Button className="cancel-button" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="save-button" onClick={hanleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
