import { FC, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { AdminArticleTable } from '../../Tables/AdminArticles';
import { AdminCategoryTable } from '../../Tables/AdminCategory';

import './styles/styles.css';

export const ArticleAdmin: FC = () => {
  const [changeState, setChangeState] = useState<number>(0);
  return (
    <Container fluid className="main-container mb-5">
      <Tabs
        defaultActiveKey="Articles"
        className="admin-tabs"
        onClick={() => {
          setChangeState(changeState + 1);
        }}
      >
        <Tab eventKey="Articles" title="Articles" className="admin-tab">
          <AdminArticleTable changeState={changeState} />
        </Tab>
        <Tab eventKey="Categories" title="Categories" className="admin-tab">
          <AdminCategoryTable changeState={changeState} />
        </Tab>
      </Tabs>
    </Container>
  );
};
