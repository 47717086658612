import { useEffect, useState } from 'react';

export const useColorScheme = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
  );

  useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

    const handleColorSchemeChange = (e: any) => {
      setIsDarkMode(e.matches);
    };

    matchMedia.addEventListener('change', handleColorSchemeChange);

    return () => {
      matchMedia.removeEventListener('change', handleColorSchemeChange);
    };
  }, []);

  return isDarkMode;
};
