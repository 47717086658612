import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Container, Row } from 'react-bootstrap';

import { FileText } from 'react-bootstrap-icons';
import './styles/styles.css';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
}

export const TermsAndPrivacy: FC<UserDetailsModalProps> = ({ show, handleClose }) => {
  const onClickSubmit = () => {
    // TODO: submit user signature
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} /> {'  '}
          Terms & Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Form.Text>
              <h6>Terms & Conditions:</h6> <br />
              1. Booking Confirmation: By making a reservation, you agree to the terms and
              conditions of our hotel. <br />
              2. Check-in & Check-out: Check-in time is at [insert time] and check-out
              time is at [insert time]. <br /> 3. Payment: Payment is required at the time
              of booking or upon check-in. We accept [list accepted payment methods].
              <br /> 4. Cancellation: Cancellation policies vary based on the room type
              and rate plan selected. Please refer to your booking confirmation for
              details. <br /> 5. Guest Conduct: We expect all guests to respect fellow
              guests and hotel staff. Any disruptive or inappropriate behavior may result
              in eviction without a refund. <br /> 6. Damages: Guests are responsible for
              an damages to the hotel property and will be charged accordingly. <br />
              <br />
              <h6>Privacy Policy:</h6> <br /> 1. Information Collection: We collect
              personal information for reservations, including name, contact details, and
              payment information. <br />
              2. Data Security: Your information is stored securely. and we take measures
              to protect it from unauthorized <br /> 3. Use of Information: We use your
              data for booking purposes. communication. and to improve our services. We do
              not share your information with third parties without your consent. <br />
              4. Cookies: We use cookies to enhance your online experience on our website.
              You can manage your cookie preferences in your browser settings. <br /> 5.
              Marketing Communications: With your consent, we may send vou promotional
              offers and newsletters.
            </Form.Text>
          </Row>
        </Form>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button className="cancel-button no-border-radius" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          onClick={onClickSubmit}
          style={{ marginLeft: '10px' }}
        >
          Agree & Continue
        </Button>
      </Container>
    </Modal>
  );
};
